// ------ headers ------

.title {
  font-size: 36px;
  line-height: 150%;
  font-weight: 600;
  margin-bottom: 24px;
}
.title-small {
  font-size: 18px;
  font-weight: 600;
  line-height: 140%;
}
.block__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text {
  font-size: 18px;
  max-width: 750px;
}

.h1,
h1 {
}
.h2,
h2 {
}
.h3,
h3 {
}
.h4,
h4 {
}
.h5,
h5 {
}
.h6,
h6 {
}

//------ text ------

.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
.p,
p {
}
.p-xs {
}
.p-md {
}
.p-lg {
}
.p-xl {
}

//------ links ------

a {
}

//------ list ------

ul {
}
ol {
}

//------ table ------

//------ form ------
