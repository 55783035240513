.checkbox-switch {
  display: inline-block;
  height: 29px;
  line-height: 29px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;

  &__switcher {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 29px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 25%/50%;
    background: #eee;
    transition: 0.2s;

    &--dark {
      background: #434343;
    }

    &::before {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: white;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
      transition: 0.2s;
    }
  }

  &__switcher-blocked {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 29px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 25%/50%;
    background: rgb(70, 70, 70);
    transition: 0.2s;
    cursor: not-allowed;

    &--dark {
      background: #434343;
    }

    &::before {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: white;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
      transition: 0.2s;
    }
  }

  .switch-input {
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked + .checkbox-switch__switcher {
      background: $orange;
    }
    &:checked + .checkbox-switch__switcher-blocked {
      background: rgb(70, 70, 70);
    }
    &:checked + .checkbox-switch__switcher:before {
      transform: translateX(28px);
    }
  }
}
