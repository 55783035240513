.js-tabs {
  &__header {
    display: flex;
    gap: 36px;
    margin-bottom: 30px;
    padding-bottom: 6px;
  }

  &__title {
    font-size: 22px;
    font-weight: 600;
    text-decoration: none;
    padding-bottom: 6px;
    color: #000;
  }
  &__title-active {
    color: $orange;
    cursor: default;
    border-bottom: 1px solid $orange;

    &:hover {
      color: $orange;
    }
  }
}

.tabs-nav {
  display: flex;
  gap: 8px;

  &__item {
    font-size: 16px;
    padding: 12px 16px;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
  }
  &__item.is-active {
    cursor: default;
    color: #ee702a;
    background: rgba(238, 112, 42, 0.0823529412);
  }
}
.tabs__content {
  padding-top: 48px;
}
.tab {
  display: none;
}
.tab.is-active {
  display: block;
}

.tabs-nav-2 {
  display: flex;
  gap: 24px;

  .tabs-nav__item {
    font-size: 20px;
    color: #000;
    font-weight: 600;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    text-decoration: none;
    cursor: pointer;
    padding-bottom: 6px;
  }
  .tabs-nav__item.is-active {
    cursor: default;
    color: $orange;
    border-bottom: 1px solid $orange;
  }
}

.attach-Interface {
  .tabs-nav__item {
    font-size: 24px;
    font-weight: 600;
    padding: 0;
    margin-top: 36px;
    margin-right: 24px;
    padding-bottom: 8px;
  }
  .tabs-nav__item.is-active {
    border-radius: 0;
    background: none;
    // margin-bottom: 8px;
    border-bottom: 1px solid #ee702a;
  }

  .tabs {
    border-bottom: 1px solid #c0c7cd;
  }
}
