.manage-actions {
  display: flex;
  justify-content: flex-end;
  gap: 24px;

  @include media-breakpoint-down(xl) {
    flex-wrap: wrap;

    .progressbar-block {
      width: 100%;
    }
    .input-wrapper {
      flex-grow: 1;
    }
  }
}

.delete {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.action {
  border: 1px solid $line-color;
  border-radius: 4px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  // cursor: pointer;

  &__text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.metadata-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}
.metadata-subtitle {
  font-size: 20px;
  margin-bottom: 16px;
}
.metadata-text {
  font-size: 16px;
  color: #858a8f;
  font-weight: 500;
}

.more-icon {
  svg {
    width: 10px;
    height: 17px;
  }
  &:hover {
    svg {
      fill: $orange;
    }
  }
}
.action-icon {
  width: 20px;
  height: 20px;
}

.info-block {
  padding-bottom: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 24px;
  }

  &__subtitle {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  &__row {
    margin-bottom: 8px;
  }
  &__key {
    font-size: 18px;
    font-weight: 500;
  }
  &__value {
    font-size: 18px;
    color: $text-color;
    font-weight: 300;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.input-file {
  background-color: #f4f4f4;
  padding: 26px 16px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  .file-label {
    color: $orange;
    text-decoration: underline;

    &:hover {
      opacity: 0.9;
    }
  }
}

.graphic-block {
  padding-bottom: 32px;
  margin-bottom: 32px;
  &__title {
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 32px;
  }
  &__subtitle {
    font-size: 18px;
    font-weight: 500;
    color: #adb4ba;
    margin-bottom: 16px;
  }
}
.chart {
  max-width: 280px;
  max-height: 280px;
}

.volume-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  @include media-breakpoint-down(xl) {
    flex-wrap: wrap;
    gap: 24px;

    button {
      margin: 0;
    }
  }
}
