.checkbox-wrapper {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  color: $text-color;
  font-size: 16px;
  font-weight: 300;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 20px;
  min-height: 20px;
}

.checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid $line-color;
}

.checkbox-wrapper:hover input ~ .checkmark {
  border-color: $orange;
}

.checkbox-wrapper input:checked ~ .checkmark {
  background-color: $orange;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-wrapper .checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
