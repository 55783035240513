.icons-block {
  display: flex;
  gap: 24px;

  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.resources-block {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__text {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
  }
}

.list-links {
  display: flex;
  flex-direction: column;
}
.list-link {
  cursor: pointer;
  color: $orange;
  font-weight: 500;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    opacity: 0.6;
    color: $orange;
  }
}
