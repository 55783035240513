.tooltip {
  position: relative;

  svg {
    vertical-align: text-top;
  }

  &:hover .tooltiptext {
    visibility: visible;
  }

  .tooltiptext {
    visibility: hidden;
    background-color: rgb(74, 74, 74);
    color: #fff;
    text-align: center;
    padding: 8px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 120%;
    width: max-content;
    max-width: 200px;

    position: absolute;
    left: 50%;
    bottom: 140%;
    transform: translateX(-50%);
    z-index: 1000;

    &--right {
      position: absolute;
      left: auto;
      left: 140%;
      bottom: 50%;
      transform: translateY(50%);
      z-index: 1000;
    }
  }
}
