.box {
  border: 1px solid $line-color;
  border-radius: 10px;
}
.box-header {
  padding: 16px 24px;
  border-bottom: 1px solid $line-color;
}

.box-main {
  padding: 24px;

  &__content {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
  }
}
.badge {
  width: 100%;
  height: 60px;
  padding: 10px 16px 8px;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 16px;
  border-radius: 4px;
  background-color: #ee702a26;
  color: $orange;
  cursor: pointer;

  &__content {
    display: flex;
    align-items: baseline;
    gap: 4px;
  }

  input {
    height: 42px;
  }
}

.error-badge {
  width: 100%;
  height: 60px;
  padding: 10px 16px 8px;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 16px;
  border-radius: 4px;
  background-color: #f4bfba;
  color: #676362;
  cursor: pointer;

  &__content {
    display: flex;
    align-items: baseline;
    gap: 4px;
  }

  input {
    height: 42px;
  }
}

.accordion__btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  font-size: 24px;
  font-weight: 600;
  padding: 12px 0;
  font-size: 18px;
  color: $text-color;
  cursor: pointer;
  transition: background-color 0.2s linear;
}
.accordion__icon {
  width: 20px;
  height: 20px;
}
.accordion__btn.is-open .accordion__icon {
  transform: rotate(180deg);
}
.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.small-text {
  font-size: 18px;
  font-style: italic;
}

.file {
  display: flex;
  align-items: baseline;
  gap: 24px;
}
.file-label {
  cursor: pointer;
}
#file-upload,
#file-upload2 {
  display: none;
}
