* {
  outline: none;
  box-sizing: border-box;
  &:after,
  &:before {
    box-sizing: border-box;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  color: inherit;
}

img {
  max-width: 100%;
}

button {
  background: none;
  padding: 0;
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;
  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
  }
}
table {
  border-collapse: collapse;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
