.to-pay-info {
  display: flex;
  justify-content: flex-end;
  &__wrap {
    padding: 8px 16px;
    background: rgba(238, 112, 42, 0.15);
    border: 1px solid #ee702a;
    border-radius: 34px;
    box-sizing: border-box;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    color: #222222;
    margin-right: 12px;
  }

  &__price {
    font-size: 16px;
    color: #434343;
  }
}
