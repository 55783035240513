.sub-block {
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
  flex-grow: 1;

  @include media-breakpoint-down(xl) {
    padding: 12px;
  }

  &__title {
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 12px;
  }

  &__link {
    font-weight: 600;
    text-transform: uppercase;

    &:hover {
      color: $orange;

      svg {
        fill: $orange;
      }
    }
  }
}
