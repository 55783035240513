::ng-deep
  .mat-form-field-appearance-outline.mat-form-field-has-label
  .mat-form-field-infix {
  padding: 0.7em 0 0.7em 0;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.4em 0 0.8em 0;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.5em;
}

::ng-deep
  .mat-form-field-appearance-outline
  .mat-form-field-label.mat-form-field-empty {
  top: 23px;
}

::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
  margin-top: 2px;
}

::ng-deep
  .mat-form-field.mat-form-field-type-mat-select.mat-form-field-has-label
  .mat-form-field-infix {
  padding: 0.6em 0 0.8em 0;
  .mat-form-field-label-wrapper {
    top: -14px;
    padding: 0;
  }
}

::ng-deep
  .mat-form-field.mat-form-field-type-mat-select.mat-form-field-has-label.mat-focused
  .mat-form-field-label {
  transform: translateY(-1.8em) scale(0.8);
}

::ng-deep
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  transform: translateY(-1.4em) scale(0.8);
}

::ng-deep
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-form-field.mat-focused
  .mat-form-field-outline-gap {
  border-top-color: currentColor;
}

::ng-deep
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-form-field
  .mat-form-field-outline-gap {
  border-top-color: currentColor;
}

::ng-deep
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-form-field.mat-focused.mat-form-field-type-mat-select
  .mat-form-field-outline-gap {
  border-top-color: transparent;
}

::ng-deep
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-form-field.mat-form-field-type-mat-select
  .mat-form-field-outline-gap {
  border-top-color: transparent;
}

::ng-deep
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-form-field.mat-focused.mat-form-field-has-label
  .mat-form-field-outline-gap {
  border-top-color: transparent;
}

::ng-deep
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-form-field.mat-form-field-has-label
  .mat-form-field-outline-gap {
  border-top-color: transparent;
}

::ng-deep .mat-form-field {
  &.mat-form-field-appearance-outline {
    > .mat-form-field-wrapper {
      > .mat-form-field-flex {
        > .mat-form-field-outline {
          > .mat-form-field-outline-start,
          .mat-form-field-outline-end,
          .mat-form-field-outline-gap {
            border-width: 1px;
          }
        }

        > .mat-form-field-outline:not(.mat-form-field-disabled) {
          > .mat-form-field-outline-start,
          .mat-form-field-outline-end,
          .mat-form-field-outline-gap {
            border-width: 1px;
          }
        }
      }

      > .mat-form-field-flex:hover {
        > .mat-form-field-outline:not(.mat-form-field-disabled) {
          > .mat-form-field-outline-start,
          .mat-form-field-outline-end,
          .mat-form-field-outline-gap {
            border-width: 1px;
          }
        }
      }
    }
  }
}

:host ::ng-deep .mat-select-arrow {
  border-left: 15px solid transparent !important;
  border-right: none !important;
  border-top: 15px solid transparent !important;
  border-image-source: "" !important;
  border-image-repeat: stretch !important;
}
