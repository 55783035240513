.review-block {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 150%;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  &__key {
    font-size: 18px;
    font-weight: 600;
  }

  &__value {
    font-size: 18px;
    color: $dark-gray;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__total {
    margin-top: auto;
  }

  &__wrap {
    max-width: 504px;
    height: 77px;
    border-radius: 10px;
    padding: 0 24px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
  }

  &__price {
    font-weight: 500;
    font-size: 20px;
    text-align: end;
  }
}
