@import "~codemirror/lib/codemirror.css";
@import "~codemirror/theme/3024-day.css";
@import "~codemirror/theme/3024-night.css";
@import "~codemirror/theme/abbott.css";
@import "~codemirror/theme/abcdef.css";
@import "~codemirror/theme/ambiance.css";
@import "~codemirror/theme/ayu-dark.css";
@import "~codemirror/theme/ayu-mirage.css";
@import "~codemirror/theme/base16-dark.css";
@import "~codemirror/theme/base16-light.css";
@import "~codemirror/theme/bespin.css";
@import "~codemirror/theme/blackboard.css";
@import "~codemirror/theme/cobalt.css";
@import "~codemirror/theme/colorforth.css";
@import "~codemirror/theme/darcula.css";
@import "~codemirror/theme/dracula.css";
@import "~codemirror/theme/duotone-dark.css";
@import "~codemirror/theme/duotone-light.css";
@import "~codemirror/theme/eclipse.css";
@import "~codemirror/theme/elegant.css";
@import "~codemirror/theme/erlang-dark.css";
@import "~codemirror/theme/gruvbox-dark.css";
@import "~codemirror/theme/hopscotch.css";
@import "~codemirror/theme/icecoder.css";
@import "~codemirror/theme/idea.css";
@import "~codemirror/theme/isotope.css";
@import "~codemirror/theme/juejin.css";
@import "~codemirror/theme/lesser-dark.css";
@import "~codemirror/theme/liquibyte.css";
@import "~codemirror/theme/lucario.css";
@import "~codemirror/theme/material.css";
@import "~codemirror/theme/material-darker.css";
@import "~codemirror/theme/material-palenight.css";
@import "~codemirror/theme/material-ocean.css";
@import "~codemirror/theme/mbo.css";
@import "~codemirror/theme/mdn-like.css";
@import "~codemirror/theme/midnight.css";
@import "~codemirror/theme/monokai.css";
@import "~codemirror/theme/moxer.css";
@import "~codemirror/theme/neat.css";
@import "~codemirror/theme/neo.css";
@import "~codemirror/theme/night.css";
@import "~codemirror/theme/nord.css";
@import "~codemirror/theme/oceanic-next.css";
@import "~codemirror/theme/panda-syntax.css";
@import "~codemirror/theme/paraiso-dark.css";
@import "~codemirror/theme/paraiso-light.css";
@import "~codemirror/theme/pastel-on-dark.css";
@import "~codemirror/theme/railscasts.css";
@import "~codemirror/theme/rubyblue.css";
@import "~codemirror/theme/seti.css";
@import "~codemirror/theme/shadowfox.css";
@import "~codemirror/theme/the-matrix.css";
@import "~codemirror/theme/tomorrow-night-bright.css";
@import "~codemirror/theme/tomorrow-night-eighties.css";
@import "~codemirror/theme/ttcn.css";
@import "~codemirror/theme/twilight.css";
@import "~codemirror/theme/vibrant-ink.css";
@import "~codemirror/theme/xq-dark.css";
@import "~codemirror/theme/xq-light.css";
@import "~codemirror/theme/yeti.css";
@import "~codemirror/theme/yonce.css";
@import "~codemirror/theme/zenburn.css";
@import "~codemirror/addon/fold/foldgutter.css";
@import "~codemirror/addon/lint/lint.css";
:root {
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Exo 2, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dde4ed;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #212529;
  --bs-link-hover-color: black;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 10px 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: none;
}
a:hover {
  color: var(--bs-link-hover-color);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 40px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 100%;
  }
}
.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 4px;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 4px;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 8px;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 8px;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 12px;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 12px;
}

.g-20,
.gx-20 {
  --bs-gutter-x: 20px;
}

.g-20,
.gy-20 {
  --bs-gutter-y: 20px;
}

.g-24,
.gx-24 {
  --bs-gutter-x: 24px;
}

.g-24,
.gy-24 {
  --bs-gutter-y: 24px;
}

.g-32,
.gx-32 {
  --bs-gutter-x: 32px;
}

.g-32,
.gy-32 {
  --bs-gutter-y: 32px;
}

.g-40,
.gx-40 {
  --bs-gutter-x: 40px;
}

.g-40,
.gy-40 {
  --bs-gutter-y: 40px;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 4px;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 4px;
  }

  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 8px;
  }

  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 8px;
  }

  .g-sm-12,
.gx-sm-12 {
    --bs-gutter-x: 12px;
  }

  .g-sm-12,
.gy-sm-12 {
    --bs-gutter-y: 12px;
  }

  .g-sm-20,
.gx-sm-20 {
    --bs-gutter-x: 20px;
  }

  .g-sm-20,
.gy-sm-20 {
    --bs-gutter-y: 20px;
  }

  .g-sm-24,
.gx-sm-24 {
    --bs-gutter-x: 24px;
  }

  .g-sm-24,
.gy-sm-24 {
    --bs-gutter-y: 24px;
  }

  .g-sm-32,
.gx-sm-32 {
    --bs-gutter-x: 32px;
  }

  .g-sm-32,
.gy-sm-32 {
    --bs-gutter-y: 32px;
  }

  .g-sm-40,
.gx-sm-40 {
    --bs-gutter-x: 40px;
  }

  .g-sm-40,
.gy-sm-40 {
    --bs-gutter-y: 40px;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 4px;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 4px;
  }

  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 8px;
  }

  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 8px;
  }

  .g-md-12,
.gx-md-12 {
    --bs-gutter-x: 12px;
  }

  .g-md-12,
.gy-md-12 {
    --bs-gutter-y: 12px;
  }

  .g-md-20,
.gx-md-20 {
    --bs-gutter-x: 20px;
  }

  .g-md-20,
.gy-md-20 {
    --bs-gutter-y: 20px;
  }

  .g-md-24,
.gx-md-24 {
    --bs-gutter-x: 24px;
  }

  .g-md-24,
.gy-md-24 {
    --bs-gutter-y: 24px;
  }

  .g-md-32,
.gx-md-32 {
    --bs-gutter-x: 32px;
  }

  .g-md-32,
.gy-md-32 {
    --bs-gutter-y: 32px;
  }

  .g-md-40,
.gx-md-40 {
    --bs-gutter-x: 40px;
  }

  .g-md-40,
.gy-md-40 {
    --bs-gutter-y: 40px;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 4px;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 4px;
  }

  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 8px;
  }

  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 8px;
  }

  .g-lg-12,
.gx-lg-12 {
    --bs-gutter-x: 12px;
  }

  .g-lg-12,
.gy-lg-12 {
    --bs-gutter-y: 12px;
  }

  .g-lg-20,
.gx-lg-20 {
    --bs-gutter-x: 20px;
  }

  .g-lg-20,
.gy-lg-20 {
    --bs-gutter-y: 20px;
  }

  .g-lg-24,
.gx-lg-24 {
    --bs-gutter-x: 24px;
  }

  .g-lg-24,
.gy-lg-24 {
    --bs-gutter-y: 24px;
  }

  .g-lg-32,
.gx-lg-32 {
    --bs-gutter-x: 32px;
  }

  .g-lg-32,
.gy-lg-32 {
    --bs-gutter-y: 32px;
  }

  .g-lg-40,
.gx-lg-40 {
    --bs-gutter-x: 40px;
  }

  .g-lg-40,
.gy-lg-40 {
    --bs-gutter-y: 40px;
  }
}
@media (min-width: 1400px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 4px;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 4px;
  }

  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 8px;
  }

  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 8px;
  }

  .g-xl-12,
.gx-xl-12 {
    --bs-gutter-x: 12px;
  }

  .g-xl-12,
.gy-xl-12 {
    --bs-gutter-y: 12px;
  }

  .g-xl-20,
.gx-xl-20 {
    --bs-gutter-x: 20px;
  }

  .g-xl-20,
.gy-xl-20 {
    --bs-gutter-y: 20px;
  }

  .g-xl-24,
.gx-xl-24 {
    --bs-gutter-x: 24px;
  }

  .g-xl-24,
.gy-xl-24 {
    --bs-gutter-y: 24px;
  }

  .g-xl-32,
.gx-xl-32 {
    --bs-gutter-x: 32px;
  }

  .g-xl-32,
.gy-xl-32 {
    --bs-gutter-y: 32px;
  }

  .g-xl-40,
.gx-xl-40 {
    --bs-gutter-x: 40px;
  }

  .g-xl-40,
.gy-xl-40 {
    --bs-gutter-y: 40px;
  }
}
@media (min-width: 1600px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 4px;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 4px;
  }

  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 8px;
  }

  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 8px;
  }

  .g-xxl-12,
.gx-xxl-12 {
    --bs-gutter-x: 12px;
  }

  .g-xxl-12,
.gy-xxl-12 {
    --bs-gutter-y: 12px;
  }

  .g-xxl-20,
.gx-xxl-20 {
    --bs-gutter-x: 20px;
  }

  .g-xxl-20,
.gy-xxl-20 {
    --bs-gutter-y: 20px;
  }

  .g-xxl-24,
.gx-xxl-24 {
    --bs-gutter-x: 24px;
  }

  .g-xxl-24,
.gy-xxl-24 {
    --bs-gutter-y: 24px;
  }

  .g-xxl-32,
.gx-xxl-32 {
    --bs-gutter-x: 32px;
  }

  .g-xxl-32,
.gy-xxl-32 {
    --bs-gutter-y: 32px;
  }

  .g-xxl-40,
.gx-xxl-40 {
    --bs-gutter-x: 40px;
  }

  .g-xxl-40,
.gy-xxl-40 {
    --bs-gutter-y: 40px;
  }
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-4 {
  margin: 4px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-24 {
  margin: 24px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-32 {
  margin: 32px !important;
}

.m-36 {
  margin: 36px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-48 {
  margin: 48px !important;
}

.m-64 {
  margin: 64px !important;
}

.m-68 {
  margin: 68px !important;
}

.m-120 {
  margin: 120px !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-4 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.mx-8 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.mx-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.mx-12 {
  margin-right: 12px !important;
  margin-left: 12px !important;
}

.mx-15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.mx-16 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.mx-20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.mx-24 {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.mx-30 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.mx-32 {
  margin-right: 32px !important;
  margin-left: 32px !important;
}

.mx-36 {
  margin-right: 36px !important;
  margin-left: 36px !important;
}

.mx-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.mx-48 {
  margin-right: 48px !important;
  margin-left: 48px !important;
}

.mx-64 {
  margin-right: 64px !important;
  margin-left: 64px !important;
}

.mx-68 {
  margin-right: 68px !important;
  margin-left: 68px !important;
}

.mx-120 {
  margin-right: 120px !important;
  margin-left: 120px !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.my-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.my-36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.my-64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.my-68 {
  margin-top: 68px !important;
  margin-bottom: 68px !important;
}

.my-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.mt-64 {
  margin-top: 64px !important;
}

.mt-68 {
  margin-top: 68px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-4 {
  margin-right: 4px !important;
}

.me-8 {
  margin-right: 8px !important;
}

.me-10 {
  margin-right: 10px !important;
}

.me-12 {
  margin-right: 12px !important;
}

.me-15 {
  margin-right: 15px !important;
}

.me-16 {
  margin-right: 16px !important;
}

.me-20 {
  margin-right: 20px !important;
}

.me-24 {
  margin-right: 24px !important;
}

.me-30 {
  margin-right: 30px !important;
}

.me-32 {
  margin-right: 32px !important;
}

.me-36 {
  margin-right: 36px !important;
}

.me-40 {
  margin-right: 40px !important;
}

.me-48 {
  margin-right: 48px !important;
}

.me-64 {
  margin-right: 64px !important;
}

.me-68 {
  margin-right: 68px !important;
}

.me-120 {
  margin-right: 120px !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.mb-64 {
  margin-bottom: 64px !important;
}

.mb-68 {
  margin-bottom: 68px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-4 {
  margin-left: 4px !important;
}

.ms-8 {
  margin-left: 8px !important;
}

.ms-10 {
  margin-left: 10px !important;
}

.ms-12 {
  margin-left: 12px !important;
}

.ms-15 {
  margin-left: 15px !important;
}

.ms-16 {
  margin-left: 16px !important;
}

.ms-20 {
  margin-left: 20px !important;
}

.ms-24 {
  margin-left: 24px !important;
}

.ms-30 {
  margin-left: 30px !important;
}

.ms-32 {
  margin-left: 32px !important;
}

.ms-36 {
  margin-left: 36px !important;
}

.ms-40 {
  margin-left: 40px !important;
}

.ms-48 {
  margin-left: 48px !important;
}

.ms-64 {
  margin-left: 64px !important;
}

.ms-68 {
  margin-left: 68px !important;
}

.ms-120 {
  margin-left: 120px !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n4 {
  margin: -4px !important;
}

.m-n8 {
  margin: -8px !important;
}

.m-n10 {
  margin: -10px !important;
}

.m-n12 {
  margin: -12px !important;
}

.m-n15 {
  margin: -15px !important;
}

.m-n16 {
  margin: -16px !important;
}

.m-n20 {
  margin: -20px !important;
}

.m-n24 {
  margin: -24px !important;
}

.m-n30 {
  margin: -30px !important;
}

.m-n32 {
  margin: -32px !important;
}

.m-n36 {
  margin: -36px !important;
}

.m-n40 {
  margin: -40px !important;
}

.m-n48 {
  margin: -48px !important;
}

.m-n64 {
  margin: -64px !important;
}

.m-n68 {
  margin: -68px !important;
}

.m-n120 {
  margin: -120px !important;
}

.mx-n4 {
  margin-right: -4px !important;
  margin-left: -4px !important;
}

.mx-n8 {
  margin-right: -8px !important;
  margin-left: -8px !important;
}

.mx-n10 {
  margin-right: -10px !important;
  margin-left: -10px !important;
}

.mx-n12 {
  margin-right: -12px !important;
  margin-left: -12px !important;
}

.mx-n15 {
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.mx-n16 {
  margin-right: -16px !important;
  margin-left: -16px !important;
}

.mx-n20 {
  margin-right: -20px !important;
  margin-left: -20px !important;
}

.mx-n24 {
  margin-right: -24px !important;
  margin-left: -24px !important;
}

.mx-n30 {
  margin-right: -30px !important;
  margin-left: -30px !important;
}

.mx-n32 {
  margin-right: -32px !important;
  margin-left: -32px !important;
}

.mx-n36 {
  margin-right: -36px !important;
  margin-left: -36px !important;
}

.mx-n40 {
  margin-right: -40px !important;
  margin-left: -40px !important;
}

.mx-n48 {
  margin-right: -48px !important;
  margin-left: -48px !important;
}

.mx-n64 {
  margin-right: -64px !important;
  margin-left: -64px !important;
}

.mx-n68 {
  margin-right: -68px !important;
  margin-left: -68px !important;
}

.mx-n120 {
  margin-right: -120px !important;
  margin-left: -120px !important;
}

.my-n4 {
  margin-top: -4px !important;
  margin-bottom: -4px !important;
}

.my-n8 {
  margin-top: -8px !important;
  margin-bottom: -8px !important;
}

.my-n10 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}

.my-n12 {
  margin-top: -12px !important;
  margin-bottom: -12px !important;
}

.my-n15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}

.my-n16 {
  margin-top: -16px !important;
  margin-bottom: -16px !important;
}

.my-n20 {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}

.my-n24 {
  margin-top: -24px !important;
  margin-bottom: -24px !important;
}

.my-n30 {
  margin-top: -30px !important;
  margin-bottom: -30px !important;
}

.my-n32 {
  margin-top: -32px !important;
  margin-bottom: -32px !important;
}

.my-n36 {
  margin-top: -36px !important;
  margin-bottom: -36px !important;
}

.my-n40 {
  margin-top: -40px !important;
  margin-bottom: -40px !important;
}

.my-n48 {
  margin-top: -48px !important;
  margin-bottom: -48px !important;
}

.my-n64 {
  margin-top: -64px !important;
  margin-bottom: -64px !important;
}

.my-n68 {
  margin-top: -68px !important;
  margin-bottom: -68px !important;
}

.my-n120 {
  margin-top: -120px !important;
  margin-bottom: -120px !important;
}

.mt-n4 {
  margin-top: -4px !important;
}

.mt-n8 {
  margin-top: -8px !important;
}

.mt-n10 {
  margin-top: -10px !important;
}

.mt-n12 {
  margin-top: -12px !important;
}

.mt-n15 {
  margin-top: -15px !important;
}

.mt-n16 {
  margin-top: -16px !important;
}

.mt-n20 {
  margin-top: -20px !important;
}

.mt-n24 {
  margin-top: -24px !important;
}

.mt-n30 {
  margin-top: -30px !important;
}

.mt-n32 {
  margin-top: -32px !important;
}

.mt-n36 {
  margin-top: -36px !important;
}

.mt-n40 {
  margin-top: -40px !important;
}

.mt-n48 {
  margin-top: -48px !important;
}

.mt-n64 {
  margin-top: -64px !important;
}

.mt-n68 {
  margin-top: -68px !important;
}

.mt-n120 {
  margin-top: -120px !important;
}

.me-n4 {
  margin-right: -4px !important;
}

.me-n8 {
  margin-right: -8px !important;
}

.me-n10 {
  margin-right: -10px !important;
}

.me-n12 {
  margin-right: -12px !important;
}

.me-n15 {
  margin-right: -15px !important;
}

.me-n16 {
  margin-right: -16px !important;
}

.me-n20 {
  margin-right: -20px !important;
}

.me-n24 {
  margin-right: -24px !important;
}

.me-n30 {
  margin-right: -30px !important;
}

.me-n32 {
  margin-right: -32px !important;
}

.me-n36 {
  margin-right: -36px !important;
}

.me-n40 {
  margin-right: -40px !important;
}

.me-n48 {
  margin-right: -48px !important;
}

.me-n64 {
  margin-right: -64px !important;
}

.me-n68 {
  margin-right: -68px !important;
}

.me-n120 {
  margin-right: -120px !important;
}

.mb-n4 {
  margin-bottom: -4px !important;
}

.mb-n8 {
  margin-bottom: -8px !important;
}

.mb-n10 {
  margin-bottom: -10px !important;
}

.mb-n12 {
  margin-bottom: -12px !important;
}

.mb-n15 {
  margin-bottom: -15px !important;
}

.mb-n16 {
  margin-bottom: -16px !important;
}

.mb-n20 {
  margin-bottom: -20px !important;
}

.mb-n24 {
  margin-bottom: -24px !important;
}

.mb-n30 {
  margin-bottom: -30px !important;
}

.mb-n32 {
  margin-bottom: -32px !important;
}

.mb-n36 {
  margin-bottom: -36px !important;
}

.mb-n40 {
  margin-bottom: -40px !important;
}

.mb-n48 {
  margin-bottom: -48px !important;
}

.mb-n64 {
  margin-bottom: -64px !important;
}

.mb-n68 {
  margin-bottom: -68px !important;
}

.mb-n120 {
  margin-bottom: -120px !important;
}

.ms-n4 {
  margin-left: -4px !important;
}

.ms-n8 {
  margin-left: -8px !important;
}

.ms-n10 {
  margin-left: -10px !important;
}

.ms-n12 {
  margin-left: -12px !important;
}

.ms-n15 {
  margin-left: -15px !important;
}

.ms-n16 {
  margin-left: -16px !important;
}

.ms-n20 {
  margin-left: -20px !important;
}

.ms-n24 {
  margin-left: -24px !important;
}

.ms-n30 {
  margin-left: -30px !important;
}

.ms-n32 {
  margin-left: -32px !important;
}

.ms-n36 {
  margin-left: -36px !important;
}

.ms-n40 {
  margin-left: -40px !important;
}

.ms-n48 {
  margin-left: -48px !important;
}

.ms-n64 {
  margin-left: -64px !important;
}

.ms-n68 {
  margin-left: -68px !important;
}

.ms-n120 {
  margin-left: -120px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-4 {
  padding: 4px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-32 {
  padding: 32px !important;
}

.p-36 {
  padding: 36px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-48 {
  padding: 48px !important;
}

.p-64 {
  padding: 64px !important;
}

.p-68 {
  padding: 68px !important;
}

.p-120 {
  padding: 120px !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-4 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.px-8 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.px-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.px-12 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.px-15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.px-16 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.px-20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.px-24 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.px-30 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.px-32 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.px-36 {
  padding-right: 36px !important;
  padding-left: 36px !important;
}

.px-40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.px-48 {
  padding-right: 48px !important;
  padding-left: 48px !important;
}

.px-64 {
  padding-right: 64px !important;
  padding-left: 64px !important;
}

.px-68 {
  padding-right: 68px !important;
  padding-left: 68px !important;
}

.px-120 {
  padding-right: 120px !important;
  padding-left: 120px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.py-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.py-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.py-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.py-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.py-68 {
  padding-top: 68px !important;
  padding-bottom: 68px !important;
}

.py-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pt-36 {
  padding-top: 36px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-48 {
  padding-top: 48px !important;
}

.pt-64 {
  padding-top: 64px !important;
}

.pt-68 {
  padding-top: 68px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-4 {
  padding-right: 4px !important;
}

.pe-8 {
  padding-right: 8px !important;
}

.pe-10 {
  padding-right: 10px !important;
}

.pe-12 {
  padding-right: 12px !important;
}

.pe-15 {
  padding-right: 15px !important;
}

.pe-16 {
  padding-right: 16px !important;
}

.pe-20 {
  padding-right: 20px !important;
}

.pe-24 {
  padding-right: 24px !important;
}

.pe-30 {
  padding-right: 30px !important;
}

.pe-32 {
  padding-right: 32px !important;
}

.pe-36 {
  padding-right: 36px !important;
}

.pe-40 {
  padding-right: 40px !important;
}

.pe-48 {
  padding-right: 48px !important;
}

.pe-64 {
  padding-right: 64px !important;
}

.pe-68 {
  padding-right: 68px !important;
}

.pe-120 {
  padding-right: 120px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pb-36 {
  padding-bottom: 36px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-48 {
  padding-bottom: 48px !important;
}

.pb-64 {
  padding-bottom: 64px !important;
}

.pb-68 {
  padding-bottom: 68px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-4 {
  padding-left: 4px !important;
}

.ps-8 {
  padding-left: 8px !important;
}

.ps-10 {
  padding-left: 10px !important;
}

.ps-12 {
  padding-left: 12px !important;
}

.ps-15 {
  padding-left: 15px !important;
}

.ps-16 {
  padding-left: 16px !important;
}

.ps-20 {
  padding-left: 20px !important;
}

.ps-24 {
  padding-left: 24px !important;
}

.ps-30 {
  padding-left: 30px !important;
}

.ps-32 {
  padding-left: 32px !important;
}

.ps-36 {
  padding-left: 36px !important;
}

.ps-40 {
  padding-left: 40px !important;
}

.ps-48 {
  padding-left: 48px !important;
}

.ps-64 {
  padding-left: 64px !important;
}

.ps-68 {
  padding-left: 68px !important;
}

.ps-120 {
  padding-left: 120px !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-4 {
  gap: 4px !important;
}

.gap-8 {
  gap: 8px !important;
}

.gap-10 {
  gap: 10px !important;
}

.gap-12 {
  gap: 12px !important;
}

.gap-15 {
  gap: 15px !important;
}

.gap-16 {
  gap: 16px !important;
}

.gap-20 {
  gap: 20px !important;
}

.gap-24 {
  gap: 24px !important;
}

.gap-30 {
  gap: 30px !important;
}

.gap-32 {
  gap: 32px !important;
}

.gap-36 {
  gap: 36px !important;
}

.gap-40 {
  gap: 40px !important;
}

.gap-48 {
  gap: 48px !important;
}

.gap-64 {
  gap: 64px !important;
}

.gap-68 {
  gap: 68px !important;
}

.gap-120 {
  gap: 120px !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-4 {
    margin: 4px !important;
  }

  .m-sm-8 {
    margin: 8px !important;
  }

  .m-sm-10 {
    margin: 10px !important;
  }

  .m-sm-12 {
    margin: 12px !important;
  }

  .m-sm-15 {
    margin: 15px !important;
  }

  .m-sm-16 {
    margin: 16px !important;
  }

  .m-sm-20 {
    margin: 20px !important;
  }

  .m-sm-24 {
    margin: 24px !important;
  }

  .m-sm-30 {
    margin: 30px !important;
  }

  .m-sm-32 {
    margin: 32px !important;
  }

  .m-sm-36 {
    margin: 36px !important;
  }

  .m-sm-40 {
    margin: 40px !important;
  }

  .m-sm-48 {
    margin: 48px !important;
  }

  .m-sm-64 {
    margin: 64px !important;
  }

  .m-sm-68 {
    margin: 68px !important;
  }

  .m-sm-120 {
    margin: 120px !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-4 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }

  .mx-sm-8 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }

  .mx-sm-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .mx-sm-12 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }

  .mx-sm-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }

  .mx-sm-16 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }

  .mx-sm-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .mx-sm-24 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }

  .mx-sm-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }

  .mx-sm-32 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }

  .mx-sm-36 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }

  .mx-sm-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .mx-sm-48 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }

  .mx-sm-64 {
    margin-right: 64px !important;
    margin-left: 64px !important;
  }

  .mx-sm-68 {
    margin-right: 68px !important;
    margin-left: 68px !important;
  }

  .mx-sm-120 {
    margin-right: 120px !important;
    margin-left: 120px !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-4 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .my-sm-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .my-sm-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .my-sm-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .my-sm-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .my-sm-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .my-sm-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-sm-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .my-sm-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-sm-32 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .my-sm-36 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .my-sm-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-sm-48 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .my-sm-64 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }

  .my-sm-68 {
    margin-top: 68px !important;
    margin-bottom: 68px !important;
  }

  .my-sm-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-4 {
    margin-top: 4px !important;
  }

  .mt-sm-8 {
    margin-top: 8px !important;
  }

  .mt-sm-10 {
    margin-top: 10px !important;
  }

  .mt-sm-12 {
    margin-top: 12px !important;
  }

  .mt-sm-15 {
    margin-top: 15px !important;
  }

  .mt-sm-16 {
    margin-top: 16px !important;
  }

  .mt-sm-20 {
    margin-top: 20px !important;
  }

  .mt-sm-24 {
    margin-top: 24px !important;
  }

  .mt-sm-30 {
    margin-top: 30px !important;
  }

  .mt-sm-32 {
    margin-top: 32px !important;
  }

  .mt-sm-36 {
    margin-top: 36px !important;
  }

  .mt-sm-40 {
    margin-top: 40px !important;
  }

  .mt-sm-48 {
    margin-top: 48px !important;
  }

  .mt-sm-64 {
    margin-top: 64px !important;
  }

  .mt-sm-68 {
    margin-top: 68px !important;
  }

  .mt-sm-120 {
    margin-top: 120px !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-4 {
    margin-right: 4px !important;
  }

  .me-sm-8 {
    margin-right: 8px !important;
  }

  .me-sm-10 {
    margin-right: 10px !important;
  }

  .me-sm-12 {
    margin-right: 12px !important;
  }

  .me-sm-15 {
    margin-right: 15px !important;
  }

  .me-sm-16 {
    margin-right: 16px !important;
  }

  .me-sm-20 {
    margin-right: 20px !important;
  }

  .me-sm-24 {
    margin-right: 24px !important;
  }

  .me-sm-30 {
    margin-right: 30px !important;
  }

  .me-sm-32 {
    margin-right: 32px !important;
  }

  .me-sm-36 {
    margin-right: 36px !important;
  }

  .me-sm-40 {
    margin-right: 40px !important;
  }

  .me-sm-48 {
    margin-right: 48px !important;
  }

  .me-sm-64 {
    margin-right: 64px !important;
  }

  .me-sm-68 {
    margin-right: 68px !important;
  }

  .me-sm-120 {
    margin-right: 120px !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-4 {
    margin-bottom: 4px !important;
  }

  .mb-sm-8 {
    margin-bottom: 8px !important;
  }

  .mb-sm-10 {
    margin-bottom: 10px !important;
  }

  .mb-sm-12 {
    margin-bottom: 12px !important;
  }

  .mb-sm-15 {
    margin-bottom: 15px !important;
  }

  .mb-sm-16 {
    margin-bottom: 16px !important;
  }

  .mb-sm-20 {
    margin-bottom: 20px !important;
  }

  .mb-sm-24 {
    margin-bottom: 24px !important;
  }

  .mb-sm-30 {
    margin-bottom: 30px !important;
  }

  .mb-sm-32 {
    margin-bottom: 32px !important;
  }

  .mb-sm-36 {
    margin-bottom: 36px !important;
  }

  .mb-sm-40 {
    margin-bottom: 40px !important;
  }

  .mb-sm-48 {
    margin-bottom: 48px !important;
  }

  .mb-sm-64 {
    margin-bottom: 64px !important;
  }

  .mb-sm-68 {
    margin-bottom: 68px !important;
  }

  .mb-sm-120 {
    margin-bottom: 120px !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-4 {
    margin-left: 4px !important;
  }

  .ms-sm-8 {
    margin-left: 8px !important;
  }

  .ms-sm-10 {
    margin-left: 10px !important;
  }

  .ms-sm-12 {
    margin-left: 12px !important;
  }

  .ms-sm-15 {
    margin-left: 15px !important;
  }

  .ms-sm-16 {
    margin-left: 16px !important;
  }

  .ms-sm-20 {
    margin-left: 20px !important;
  }

  .ms-sm-24 {
    margin-left: 24px !important;
  }

  .ms-sm-30 {
    margin-left: 30px !important;
  }

  .ms-sm-32 {
    margin-left: 32px !important;
  }

  .ms-sm-36 {
    margin-left: 36px !important;
  }

  .ms-sm-40 {
    margin-left: 40px !important;
  }

  .ms-sm-48 {
    margin-left: 48px !important;
  }

  .ms-sm-64 {
    margin-left: 64px !important;
  }

  .ms-sm-68 {
    margin-left: 68px !important;
  }

  .ms-sm-120 {
    margin-left: 120px !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n4 {
    margin: -4px !important;
  }

  .m-sm-n8 {
    margin: -8px !important;
  }

  .m-sm-n10 {
    margin: -10px !important;
  }

  .m-sm-n12 {
    margin: -12px !important;
  }

  .m-sm-n15 {
    margin: -15px !important;
  }

  .m-sm-n16 {
    margin: -16px !important;
  }

  .m-sm-n20 {
    margin: -20px !important;
  }

  .m-sm-n24 {
    margin: -24px !important;
  }

  .m-sm-n30 {
    margin: -30px !important;
  }

  .m-sm-n32 {
    margin: -32px !important;
  }

  .m-sm-n36 {
    margin: -36px !important;
  }

  .m-sm-n40 {
    margin: -40px !important;
  }

  .m-sm-n48 {
    margin: -48px !important;
  }

  .m-sm-n64 {
    margin: -64px !important;
  }

  .m-sm-n68 {
    margin: -68px !important;
  }

  .m-sm-n120 {
    margin: -120px !important;
  }

  .mx-sm-n4 {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }

  .mx-sm-n8 {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }

  .mx-sm-n10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }

  .mx-sm-n12 {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }

  .mx-sm-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  .mx-sm-n16 {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }

  .mx-sm-n20 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }

  .mx-sm-n24 {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }

  .mx-sm-n30 {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }

  .mx-sm-n32 {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }

  .mx-sm-n36 {
    margin-right: -36px !important;
    margin-left: -36px !important;
  }

  .mx-sm-n40 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }

  .mx-sm-n48 {
    margin-right: -48px !important;
    margin-left: -48px !important;
  }

  .mx-sm-n64 {
    margin-right: -64px !important;
    margin-left: -64px !important;
  }

  .mx-sm-n68 {
    margin-right: -68px !important;
    margin-left: -68px !important;
  }

  .mx-sm-n120 {
    margin-right: -120px !important;
    margin-left: -120px !important;
  }

  .my-sm-n4 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .my-sm-n8 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .my-sm-n10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .my-sm-n12 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .my-sm-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .my-sm-n16 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .my-sm-n20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .my-sm-n24 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .my-sm-n30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }

  .my-sm-n32 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .my-sm-n36 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .my-sm-n40 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .my-sm-n48 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .my-sm-n64 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }

  .my-sm-n68 {
    margin-top: -68px !important;
    margin-bottom: -68px !important;
  }

  .my-sm-n120 {
    margin-top: -120px !important;
    margin-bottom: -120px !important;
  }

  .mt-sm-n4 {
    margin-top: -4px !important;
  }

  .mt-sm-n8 {
    margin-top: -8px !important;
  }

  .mt-sm-n10 {
    margin-top: -10px !important;
  }

  .mt-sm-n12 {
    margin-top: -12px !important;
  }

  .mt-sm-n15 {
    margin-top: -15px !important;
  }

  .mt-sm-n16 {
    margin-top: -16px !important;
  }

  .mt-sm-n20 {
    margin-top: -20px !important;
  }

  .mt-sm-n24 {
    margin-top: -24px !important;
  }

  .mt-sm-n30 {
    margin-top: -30px !important;
  }

  .mt-sm-n32 {
    margin-top: -32px !important;
  }

  .mt-sm-n36 {
    margin-top: -36px !important;
  }

  .mt-sm-n40 {
    margin-top: -40px !important;
  }

  .mt-sm-n48 {
    margin-top: -48px !important;
  }

  .mt-sm-n64 {
    margin-top: -64px !important;
  }

  .mt-sm-n68 {
    margin-top: -68px !important;
  }

  .mt-sm-n120 {
    margin-top: -120px !important;
  }

  .me-sm-n4 {
    margin-right: -4px !important;
  }

  .me-sm-n8 {
    margin-right: -8px !important;
  }

  .me-sm-n10 {
    margin-right: -10px !important;
  }

  .me-sm-n12 {
    margin-right: -12px !important;
  }

  .me-sm-n15 {
    margin-right: -15px !important;
  }

  .me-sm-n16 {
    margin-right: -16px !important;
  }

  .me-sm-n20 {
    margin-right: -20px !important;
  }

  .me-sm-n24 {
    margin-right: -24px !important;
  }

  .me-sm-n30 {
    margin-right: -30px !important;
  }

  .me-sm-n32 {
    margin-right: -32px !important;
  }

  .me-sm-n36 {
    margin-right: -36px !important;
  }

  .me-sm-n40 {
    margin-right: -40px !important;
  }

  .me-sm-n48 {
    margin-right: -48px !important;
  }

  .me-sm-n64 {
    margin-right: -64px !important;
  }

  .me-sm-n68 {
    margin-right: -68px !important;
  }

  .me-sm-n120 {
    margin-right: -120px !important;
  }

  .mb-sm-n4 {
    margin-bottom: -4px !important;
  }

  .mb-sm-n8 {
    margin-bottom: -8px !important;
  }

  .mb-sm-n10 {
    margin-bottom: -10px !important;
  }

  .mb-sm-n12 {
    margin-bottom: -12px !important;
  }

  .mb-sm-n15 {
    margin-bottom: -15px !important;
  }

  .mb-sm-n16 {
    margin-bottom: -16px !important;
  }

  .mb-sm-n20 {
    margin-bottom: -20px !important;
  }

  .mb-sm-n24 {
    margin-bottom: -24px !important;
  }

  .mb-sm-n30 {
    margin-bottom: -30px !important;
  }

  .mb-sm-n32 {
    margin-bottom: -32px !important;
  }

  .mb-sm-n36 {
    margin-bottom: -36px !important;
  }

  .mb-sm-n40 {
    margin-bottom: -40px !important;
  }

  .mb-sm-n48 {
    margin-bottom: -48px !important;
  }

  .mb-sm-n64 {
    margin-bottom: -64px !important;
  }

  .mb-sm-n68 {
    margin-bottom: -68px !important;
  }

  .mb-sm-n120 {
    margin-bottom: -120px !important;
  }

  .ms-sm-n4 {
    margin-left: -4px !important;
  }

  .ms-sm-n8 {
    margin-left: -8px !important;
  }

  .ms-sm-n10 {
    margin-left: -10px !important;
  }

  .ms-sm-n12 {
    margin-left: -12px !important;
  }

  .ms-sm-n15 {
    margin-left: -15px !important;
  }

  .ms-sm-n16 {
    margin-left: -16px !important;
  }

  .ms-sm-n20 {
    margin-left: -20px !important;
  }

  .ms-sm-n24 {
    margin-left: -24px !important;
  }

  .ms-sm-n30 {
    margin-left: -30px !important;
  }

  .ms-sm-n32 {
    margin-left: -32px !important;
  }

  .ms-sm-n36 {
    margin-left: -36px !important;
  }

  .ms-sm-n40 {
    margin-left: -40px !important;
  }

  .ms-sm-n48 {
    margin-left: -48px !important;
  }

  .ms-sm-n64 {
    margin-left: -64px !important;
  }

  .ms-sm-n68 {
    margin-left: -68px !important;
  }

  .ms-sm-n120 {
    margin-left: -120px !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-4 {
    padding: 4px !important;
  }

  .p-sm-8 {
    padding: 8px !important;
  }

  .p-sm-10 {
    padding: 10px !important;
  }

  .p-sm-12 {
    padding: 12px !important;
  }

  .p-sm-15 {
    padding: 15px !important;
  }

  .p-sm-16 {
    padding: 16px !important;
  }

  .p-sm-20 {
    padding: 20px !important;
  }

  .p-sm-24 {
    padding: 24px !important;
  }

  .p-sm-30 {
    padding: 30px !important;
  }

  .p-sm-32 {
    padding: 32px !important;
  }

  .p-sm-36 {
    padding: 36px !important;
  }

  .p-sm-40 {
    padding: 40px !important;
  }

  .p-sm-48 {
    padding: 48px !important;
  }

  .p-sm-64 {
    padding: 64px !important;
  }

  .p-sm-68 {
    padding: 68px !important;
  }

  .p-sm-120 {
    padding: 120px !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-4 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  .px-sm-8 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }

  .px-sm-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .px-sm-12 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }

  .px-sm-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .px-sm-16 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

  .px-sm-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .px-sm-24 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }

  .px-sm-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .px-sm-32 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }

  .px-sm-36 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }

  .px-sm-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .px-sm-48 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }

  .px-sm-64 {
    padding-right: 64px !important;
    padding-left: 64px !important;
  }

  .px-sm-68 {
    padding-right: 68px !important;
    padding-left: 68px !important;
  }

  .px-sm-120 {
    padding-right: 120px !important;
    padding-left: 120px !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .py-sm-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .py-sm-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .py-sm-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .py-sm-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .py-sm-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .py-sm-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-sm-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .py-sm-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-sm-32 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .py-sm-36 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .py-sm-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-sm-48 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .py-sm-64 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }

  .py-sm-68 {
    padding-top: 68px !important;
    padding-bottom: 68px !important;
  }

  .py-sm-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-4 {
    padding-top: 4px !important;
  }

  .pt-sm-8 {
    padding-top: 8px !important;
  }

  .pt-sm-10 {
    padding-top: 10px !important;
  }

  .pt-sm-12 {
    padding-top: 12px !important;
  }

  .pt-sm-15 {
    padding-top: 15px !important;
  }

  .pt-sm-16 {
    padding-top: 16px !important;
  }

  .pt-sm-20 {
    padding-top: 20px !important;
  }

  .pt-sm-24 {
    padding-top: 24px !important;
  }

  .pt-sm-30 {
    padding-top: 30px !important;
  }

  .pt-sm-32 {
    padding-top: 32px !important;
  }

  .pt-sm-36 {
    padding-top: 36px !important;
  }

  .pt-sm-40 {
    padding-top: 40px !important;
  }

  .pt-sm-48 {
    padding-top: 48px !important;
  }

  .pt-sm-64 {
    padding-top: 64px !important;
  }

  .pt-sm-68 {
    padding-top: 68px !important;
  }

  .pt-sm-120 {
    padding-top: 120px !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-4 {
    padding-right: 4px !important;
  }

  .pe-sm-8 {
    padding-right: 8px !important;
  }

  .pe-sm-10 {
    padding-right: 10px !important;
  }

  .pe-sm-12 {
    padding-right: 12px !important;
  }

  .pe-sm-15 {
    padding-right: 15px !important;
  }

  .pe-sm-16 {
    padding-right: 16px !important;
  }

  .pe-sm-20 {
    padding-right: 20px !important;
  }

  .pe-sm-24 {
    padding-right: 24px !important;
  }

  .pe-sm-30 {
    padding-right: 30px !important;
  }

  .pe-sm-32 {
    padding-right: 32px !important;
  }

  .pe-sm-36 {
    padding-right: 36px !important;
  }

  .pe-sm-40 {
    padding-right: 40px !important;
  }

  .pe-sm-48 {
    padding-right: 48px !important;
  }

  .pe-sm-64 {
    padding-right: 64px !important;
  }

  .pe-sm-68 {
    padding-right: 68px !important;
  }

  .pe-sm-120 {
    padding-right: 120px !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-4 {
    padding-bottom: 4px !important;
  }

  .pb-sm-8 {
    padding-bottom: 8px !important;
  }

  .pb-sm-10 {
    padding-bottom: 10px !important;
  }

  .pb-sm-12 {
    padding-bottom: 12px !important;
  }

  .pb-sm-15 {
    padding-bottom: 15px !important;
  }

  .pb-sm-16 {
    padding-bottom: 16px !important;
  }

  .pb-sm-20 {
    padding-bottom: 20px !important;
  }

  .pb-sm-24 {
    padding-bottom: 24px !important;
  }

  .pb-sm-30 {
    padding-bottom: 30px !important;
  }

  .pb-sm-32 {
    padding-bottom: 32px !important;
  }

  .pb-sm-36 {
    padding-bottom: 36px !important;
  }

  .pb-sm-40 {
    padding-bottom: 40px !important;
  }

  .pb-sm-48 {
    padding-bottom: 48px !important;
  }

  .pb-sm-64 {
    padding-bottom: 64px !important;
  }

  .pb-sm-68 {
    padding-bottom: 68px !important;
  }

  .pb-sm-120 {
    padding-bottom: 120px !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-4 {
    padding-left: 4px !important;
  }

  .ps-sm-8 {
    padding-left: 8px !important;
  }

  .ps-sm-10 {
    padding-left: 10px !important;
  }

  .ps-sm-12 {
    padding-left: 12px !important;
  }

  .ps-sm-15 {
    padding-left: 15px !important;
  }

  .ps-sm-16 {
    padding-left: 16px !important;
  }

  .ps-sm-20 {
    padding-left: 20px !important;
  }

  .ps-sm-24 {
    padding-left: 24px !important;
  }

  .ps-sm-30 {
    padding-left: 30px !important;
  }

  .ps-sm-32 {
    padding-left: 32px !important;
  }

  .ps-sm-36 {
    padding-left: 36px !important;
  }

  .ps-sm-40 {
    padding-left: 40px !important;
  }

  .ps-sm-48 {
    padding-left: 48px !important;
  }

  .ps-sm-64 {
    padding-left: 64px !important;
  }

  .ps-sm-68 {
    padding-left: 68px !important;
  }

  .ps-sm-120 {
    padding-left: 120px !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-4 {
    gap: 4px !important;
  }

  .gap-sm-8 {
    gap: 8px !important;
  }

  .gap-sm-10 {
    gap: 10px !important;
  }

  .gap-sm-12 {
    gap: 12px !important;
  }

  .gap-sm-15 {
    gap: 15px !important;
  }

  .gap-sm-16 {
    gap: 16px !important;
  }

  .gap-sm-20 {
    gap: 20px !important;
  }

  .gap-sm-24 {
    gap: 24px !important;
  }

  .gap-sm-30 {
    gap: 30px !important;
  }

  .gap-sm-32 {
    gap: 32px !important;
  }

  .gap-sm-36 {
    gap: 36px !important;
  }

  .gap-sm-40 {
    gap: 40px !important;
  }

  .gap-sm-48 {
    gap: 48px !important;
  }

  .gap-sm-64 {
    gap: 64px !important;
  }

  .gap-sm-68 {
    gap: 68px !important;
  }

  .gap-sm-120 {
    gap: 120px !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-4 {
    margin: 4px !important;
  }

  .m-md-8 {
    margin: 8px !important;
  }

  .m-md-10 {
    margin: 10px !important;
  }

  .m-md-12 {
    margin: 12px !important;
  }

  .m-md-15 {
    margin: 15px !important;
  }

  .m-md-16 {
    margin: 16px !important;
  }

  .m-md-20 {
    margin: 20px !important;
  }

  .m-md-24 {
    margin: 24px !important;
  }

  .m-md-30 {
    margin: 30px !important;
  }

  .m-md-32 {
    margin: 32px !important;
  }

  .m-md-36 {
    margin: 36px !important;
  }

  .m-md-40 {
    margin: 40px !important;
  }

  .m-md-48 {
    margin: 48px !important;
  }

  .m-md-64 {
    margin: 64px !important;
  }

  .m-md-68 {
    margin: 68px !important;
  }

  .m-md-120 {
    margin: 120px !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-4 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }

  .mx-md-8 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }

  .mx-md-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .mx-md-12 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }

  .mx-md-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }

  .mx-md-16 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }

  .mx-md-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .mx-md-24 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }

  .mx-md-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }

  .mx-md-32 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }

  .mx-md-36 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }

  .mx-md-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .mx-md-48 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }

  .mx-md-64 {
    margin-right: 64px !important;
    margin-left: 64px !important;
  }

  .mx-md-68 {
    margin-right: 68px !important;
    margin-left: 68px !important;
  }

  .mx-md-120 {
    margin-right: 120px !important;
    margin-left: 120px !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-4 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .my-md-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .my-md-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .my-md-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .my-md-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .my-md-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .my-md-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-md-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .my-md-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-md-32 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .my-md-36 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .my-md-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-md-48 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .my-md-64 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }

  .my-md-68 {
    margin-top: 68px !important;
    margin-bottom: 68px !important;
  }

  .my-md-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-4 {
    margin-top: 4px !important;
  }

  .mt-md-8 {
    margin-top: 8px !important;
  }

  .mt-md-10 {
    margin-top: 10px !important;
  }

  .mt-md-12 {
    margin-top: 12px !important;
  }

  .mt-md-15 {
    margin-top: 15px !important;
  }

  .mt-md-16 {
    margin-top: 16px !important;
  }

  .mt-md-20 {
    margin-top: 20px !important;
  }

  .mt-md-24 {
    margin-top: 24px !important;
  }

  .mt-md-30 {
    margin-top: 30px !important;
  }

  .mt-md-32 {
    margin-top: 32px !important;
  }

  .mt-md-36 {
    margin-top: 36px !important;
  }

  .mt-md-40 {
    margin-top: 40px !important;
  }

  .mt-md-48 {
    margin-top: 48px !important;
  }

  .mt-md-64 {
    margin-top: 64px !important;
  }

  .mt-md-68 {
    margin-top: 68px !important;
  }

  .mt-md-120 {
    margin-top: 120px !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-4 {
    margin-right: 4px !important;
  }

  .me-md-8 {
    margin-right: 8px !important;
  }

  .me-md-10 {
    margin-right: 10px !important;
  }

  .me-md-12 {
    margin-right: 12px !important;
  }

  .me-md-15 {
    margin-right: 15px !important;
  }

  .me-md-16 {
    margin-right: 16px !important;
  }

  .me-md-20 {
    margin-right: 20px !important;
  }

  .me-md-24 {
    margin-right: 24px !important;
  }

  .me-md-30 {
    margin-right: 30px !important;
  }

  .me-md-32 {
    margin-right: 32px !important;
  }

  .me-md-36 {
    margin-right: 36px !important;
  }

  .me-md-40 {
    margin-right: 40px !important;
  }

  .me-md-48 {
    margin-right: 48px !important;
  }

  .me-md-64 {
    margin-right: 64px !important;
  }

  .me-md-68 {
    margin-right: 68px !important;
  }

  .me-md-120 {
    margin-right: 120px !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-4 {
    margin-bottom: 4px !important;
  }

  .mb-md-8 {
    margin-bottom: 8px !important;
  }

  .mb-md-10 {
    margin-bottom: 10px !important;
  }

  .mb-md-12 {
    margin-bottom: 12px !important;
  }

  .mb-md-15 {
    margin-bottom: 15px !important;
  }

  .mb-md-16 {
    margin-bottom: 16px !important;
  }

  .mb-md-20 {
    margin-bottom: 20px !important;
  }

  .mb-md-24 {
    margin-bottom: 24px !important;
  }

  .mb-md-30 {
    margin-bottom: 30px !important;
  }

  .mb-md-32 {
    margin-bottom: 32px !important;
  }

  .mb-md-36 {
    margin-bottom: 36px !important;
  }

  .mb-md-40 {
    margin-bottom: 40px !important;
  }

  .mb-md-48 {
    margin-bottom: 48px !important;
  }

  .mb-md-64 {
    margin-bottom: 64px !important;
  }

  .mb-md-68 {
    margin-bottom: 68px !important;
  }

  .mb-md-120 {
    margin-bottom: 120px !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-4 {
    margin-left: 4px !important;
  }

  .ms-md-8 {
    margin-left: 8px !important;
  }

  .ms-md-10 {
    margin-left: 10px !important;
  }

  .ms-md-12 {
    margin-left: 12px !important;
  }

  .ms-md-15 {
    margin-left: 15px !important;
  }

  .ms-md-16 {
    margin-left: 16px !important;
  }

  .ms-md-20 {
    margin-left: 20px !important;
  }

  .ms-md-24 {
    margin-left: 24px !important;
  }

  .ms-md-30 {
    margin-left: 30px !important;
  }

  .ms-md-32 {
    margin-left: 32px !important;
  }

  .ms-md-36 {
    margin-left: 36px !important;
  }

  .ms-md-40 {
    margin-left: 40px !important;
  }

  .ms-md-48 {
    margin-left: 48px !important;
  }

  .ms-md-64 {
    margin-left: 64px !important;
  }

  .ms-md-68 {
    margin-left: 68px !important;
  }

  .ms-md-120 {
    margin-left: 120px !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n4 {
    margin: -4px !important;
  }

  .m-md-n8 {
    margin: -8px !important;
  }

  .m-md-n10 {
    margin: -10px !important;
  }

  .m-md-n12 {
    margin: -12px !important;
  }

  .m-md-n15 {
    margin: -15px !important;
  }

  .m-md-n16 {
    margin: -16px !important;
  }

  .m-md-n20 {
    margin: -20px !important;
  }

  .m-md-n24 {
    margin: -24px !important;
  }

  .m-md-n30 {
    margin: -30px !important;
  }

  .m-md-n32 {
    margin: -32px !important;
  }

  .m-md-n36 {
    margin: -36px !important;
  }

  .m-md-n40 {
    margin: -40px !important;
  }

  .m-md-n48 {
    margin: -48px !important;
  }

  .m-md-n64 {
    margin: -64px !important;
  }

  .m-md-n68 {
    margin: -68px !important;
  }

  .m-md-n120 {
    margin: -120px !important;
  }

  .mx-md-n4 {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }

  .mx-md-n8 {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }

  .mx-md-n10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }

  .mx-md-n12 {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }

  .mx-md-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  .mx-md-n16 {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }

  .mx-md-n20 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }

  .mx-md-n24 {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }

  .mx-md-n30 {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }

  .mx-md-n32 {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }

  .mx-md-n36 {
    margin-right: -36px !important;
    margin-left: -36px !important;
  }

  .mx-md-n40 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }

  .mx-md-n48 {
    margin-right: -48px !important;
    margin-left: -48px !important;
  }

  .mx-md-n64 {
    margin-right: -64px !important;
    margin-left: -64px !important;
  }

  .mx-md-n68 {
    margin-right: -68px !important;
    margin-left: -68px !important;
  }

  .mx-md-n120 {
    margin-right: -120px !important;
    margin-left: -120px !important;
  }

  .my-md-n4 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .my-md-n8 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .my-md-n10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .my-md-n12 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .my-md-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .my-md-n16 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .my-md-n20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .my-md-n24 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .my-md-n30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }

  .my-md-n32 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .my-md-n36 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .my-md-n40 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .my-md-n48 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .my-md-n64 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }

  .my-md-n68 {
    margin-top: -68px !important;
    margin-bottom: -68px !important;
  }

  .my-md-n120 {
    margin-top: -120px !important;
    margin-bottom: -120px !important;
  }

  .mt-md-n4 {
    margin-top: -4px !important;
  }

  .mt-md-n8 {
    margin-top: -8px !important;
  }

  .mt-md-n10 {
    margin-top: -10px !important;
  }

  .mt-md-n12 {
    margin-top: -12px !important;
  }

  .mt-md-n15 {
    margin-top: -15px !important;
  }

  .mt-md-n16 {
    margin-top: -16px !important;
  }

  .mt-md-n20 {
    margin-top: -20px !important;
  }

  .mt-md-n24 {
    margin-top: -24px !important;
  }

  .mt-md-n30 {
    margin-top: -30px !important;
  }

  .mt-md-n32 {
    margin-top: -32px !important;
  }

  .mt-md-n36 {
    margin-top: -36px !important;
  }

  .mt-md-n40 {
    margin-top: -40px !important;
  }

  .mt-md-n48 {
    margin-top: -48px !important;
  }

  .mt-md-n64 {
    margin-top: -64px !important;
  }

  .mt-md-n68 {
    margin-top: -68px !important;
  }

  .mt-md-n120 {
    margin-top: -120px !important;
  }

  .me-md-n4 {
    margin-right: -4px !important;
  }

  .me-md-n8 {
    margin-right: -8px !important;
  }

  .me-md-n10 {
    margin-right: -10px !important;
  }

  .me-md-n12 {
    margin-right: -12px !important;
  }

  .me-md-n15 {
    margin-right: -15px !important;
  }

  .me-md-n16 {
    margin-right: -16px !important;
  }

  .me-md-n20 {
    margin-right: -20px !important;
  }

  .me-md-n24 {
    margin-right: -24px !important;
  }

  .me-md-n30 {
    margin-right: -30px !important;
  }

  .me-md-n32 {
    margin-right: -32px !important;
  }

  .me-md-n36 {
    margin-right: -36px !important;
  }

  .me-md-n40 {
    margin-right: -40px !important;
  }

  .me-md-n48 {
    margin-right: -48px !important;
  }

  .me-md-n64 {
    margin-right: -64px !important;
  }

  .me-md-n68 {
    margin-right: -68px !important;
  }

  .me-md-n120 {
    margin-right: -120px !important;
  }

  .mb-md-n4 {
    margin-bottom: -4px !important;
  }

  .mb-md-n8 {
    margin-bottom: -8px !important;
  }

  .mb-md-n10 {
    margin-bottom: -10px !important;
  }

  .mb-md-n12 {
    margin-bottom: -12px !important;
  }

  .mb-md-n15 {
    margin-bottom: -15px !important;
  }

  .mb-md-n16 {
    margin-bottom: -16px !important;
  }

  .mb-md-n20 {
    margin-bottom: -20px !important;
  }

  .mb-md-n24 {
    margin-bottom: -24px !important;
  }

  .mb-md-n30 {
    margin-bottom: -30px !important;
  }

  .mb-md-n32 {
    margin-bottom: -32px !important;
  }

  .mb-md-n36 {
    margin-bottom: -36px !important;
  }

  .mb-md-n40 {
    margin-bottom: -40px !important;
  }

  .mb-md-n48 {
    margin-bottom: -48px !important;
  }

  .mb-md-n64 {
    margin-bottom: -64px !important;
  }

  .mb-md-n68 {
    margin-bottom: -68px !important;
  }

  .mb-md-n120 {
    margin-bottom: -120px !important;
  }

  .ms-md-n4 {
    margin-left: -4px !important;
  }

  .ms-md-n8 {
    margin-left: -8px !important;
  }

  .ms-md-n10 {
    margin-left: -10px !important;
  }

  .ms-md-n12 {
    margin-left: -12px !important;
  }

  .ms-md-n15 {
    margin-left: -15px !important;
  }

  .ms-md-n16 {
    margin-left: -16px !important;
  }

  .ms-md-n20 {
    margin-left: -20px !important;
  }

  .ms-md-n24 {
    margin-left: -24px !important;
  }

  .ms-md-n30 {
    margin-left: -30px !important;
  }

  .ms-md-n32 {
    margin-left: -32px !important;
  }

  .ms-md-n36 {
    margin-left: -36px !important;
  }

  .ms-md-n40 {
    margin-left: -40px !important;
  }

  .ms-md-n48 {
    margin-left: -48px !important;
  }

  .ms-md-n64 {
    margin-left: -64px !important;
  }

  .ms-md-n68 {
    margin-left: -68px !important;
  }

  .ms-md-n120 {
    margin-left: -120px !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-4 {
    padding: 4px !important;
  }

  .p-md-8 {
    padding: 8px !important;
  }

  .p-md-10 {
    padding: 10px !important;
  }

  .p-md-12 {
    padding: 12px !important;
  }

  .p-md-15 {
    padding: 15px !important;
  }

  .p-md-16 {
    padding: 16px !important;
  }

  .p-md-20 {
    padding: 20px !important;
  }

  .p-md-24 {
    padding: 24px !important;
  }

  .p-md-30 {
    padding: 30px !important;
  }

  .p-md-32 {
    padding: 32px !important;
  }

  .p-md-36 {
    padding: 36px !important;
  }

  .p-md-40 {
    padding: 40px !important;
  }

  .p-md-48 {
    padding: 48px !important;
  }

  .p-md-64 {
    padding: 64px !important;
  }

  .p-md-68 {
    padding: 68px !important;
  }

  .p-md-120 {
    padding: 120px !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-4 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  .px-md-8 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }

  .px-md-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .px-md-12 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }

  .px-md-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .px-md-16 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

  .px-md-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .px-md-24 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }

  .px-md-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .px-md-32 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }

  .px-md-36 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }

  .px-md-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .px-md-48 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }

  .px-md-64 {
    padding-right: 64px !important;
    padding-left: 64px !important;
  }

  .px-md-68 {
    padding-right: 68px !important;
    padding-left: 68px !important;
  }

  .px-md-120 {
    padding-right: 120px !important;
    padding-left: 120px !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .py-md-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .py-md-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .py-md-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .py-md-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .py-md-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .py-md-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-md-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .py-md-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-md-32 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .py-md-36 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .py-md-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-md-48 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .py-md-64 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }

  .py-md-68 {
    padding-top: 68px !important;
    padding-bottom: 68px !important;
  }

  .py-md-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-4 {
    padding-top: 4px !important;
  }

  .pt-md-8 {
    padding-top: 8px !important;
  }

  .pt-md-10 {
    padding-top: 10px !important;
  }

  .pt-md-12 {
    padding-top: 12px !important;
  }

  .pt-md-15 {
    padding-top: 15px !important;
  }

  .pt-md-16 {
    padding-top: 16px !important;
  }

  .pt-md-20 {
    padding-top: 20px !important;
  }

  .pt-md-24 {
    padding-top: 24px !important;
  }

  .pt-md-30 {
    padding-top: 30px !important;
  }

  .pt-md-32 {
    padding-top: 32px !important;
  }

  .pt-md-36 {
    padding-top: 36px !important;
  }

  .pt-md-40 {
    padding-top: 40px !important;
  }

  .pt-md-48 {
    padding-top: 48px !important;
  }

  .pt-md-64 {
    padding-top: 64px !important;
  }

  .pt-md-68 {
    padding-top: 68px !important;
  }

  .pt-md-120 {
    padding-top: 120px !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-4 {
    padding-right: 4px !important;
  }

  .pe-md-8 {
    padding-right: 8px !important;
  }

  .pe-md-10 {
    padding-right: 10px !important;
  }

  .pe-md-12 {
    padding-right: 12px !important;
  }

  .pe-md-15 {
    padding-right: 15px !important;
  }

  .pe-md-16 {
    padding-right: 16px !important;
  }

  .pe-md-20 {
    padding-right: 20px !important;
  }

  .pe-md-24 {
    padding-right: 24px !important;
  }

  .pe-md-30 {
    padding-right: 30px !important;
  }

  .pe-md-32 {
    padding-right: 32px !important;
  }

  .pe-md-36 {
    padding-right: 36px !important;
  }

  .pe-md-40 {
    padding-right: 40px !important;
  }

  .pe-md-48 {
    padding-right: 48px !important;
  }

  .pe-md-64 {
    padding-right: 64px !important;
  }

  .pe-md-68 {
    padding-right: 68px !important;
  }

  .pe-md-120 {
    padding-right: 120px !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-4 {
    padding-bottom: 4px !important;
  }

  .pb-md-8 {
    padding-bottom: 8px !important;
  }

  .pb-md-10 {
    padding-bottom: 10px !important;
  }

  .pb-md-12 {
    padding-bottom: 12px !important;
  }

  .pb-md-15 {
    padding-bottom: 15px !important;
  }

  .pb-md-16 {
    padding-bottom: 16px !important;
  }

  .pb-md-20 {
    padding-bottom: 20px !important;
  }

  .pb-md-24 {
    padding-bottom: 24px !important;
  }

  .pb-md-30 {
    padding-bottom: 30px !important;
  }

  .pb-md-32 {
    padding-bottom: 32px !important;
  }

  .pb-md-36 {
    padding-bottom: 36px !important;
  }

  .pb-md-40 {
    padding-bottom: 40px !important;
  }

  .pb-md-48 {
    padding-bottom: 48px !important;
  }

  .pb-md-64 {
    padding-bottom: 64px !important;
  }

  .pb-md-68 {
    padding-bottom: 68px !important;
  }

  .pb-md-120 {
    padding-bottom: 120px !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-4 {
    padding-left: 4px !important;
  }

  .ps-md-8 {
    padding-left: 8px !important;
  }

  .ps-md-10 {
    padding-left: 10px !important;
  }

  .ps-md-12 {
    padding-left: 12px !important;
  }

  .ps-md-15 {
    padding-left: 15px !important;
  }

  .ps-md-16 {
    padding-left: 16px !important;
  }

  .ps-md-20 {
    padding-left: 20px !important;
  }

  .ps-md-24 {
    padding-left: 24px !important;
  }

  .ps-md-30 {
    padding-left: 30px !important;
  }

  .ps-md-32 {
    padding-left: 32px !important;
  }

  .ps-md-36 {
    padding-left: 36px !important;
  }

  .ps-md-40 {
    padding-left: 40px !important;
  }

  .ps-md-48 {
    padding-left: 48px !important;
  }

  .ps-md-64 {
    padding-left: 64px !important;
  }

  .ps-md-68 {
    padding-left: 68px !important;
  }

  .ps-md-120 {
    padding-left: 120px !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-4 {
    gap: 4px !important;
  }

  .gap-md-8 {
    gap: 8px !important;
  }

  .gap-md-10 {
    gap: 10px !important;
  }

  .gap-md-12 {
    gap: 12px !important;
  }

  .gap-md-15 {
    gap: 15px !important;
  }

  .gap-md-16 {
    gap: 16px !important;
  }

  .gap-md-20 {
    gap: 20px !important;
  }

  .gap-md-24 {
    gap: 24px !important;
  }

  .gap-md-30 {
    gap: 30px !important;
  }

  .gap-md-32 {
    gap: 32px !important;
  }

  .gap-md-36 {
    gap: 36px !important;
  }

  .gap-md-40 {
    gap: 40px !important;
  }

  .gap-md-48 {
    gap: 48px !important;
  }

  .gap-md-64 {
    gap: 64px !important;
  }

  .gap-md-68 {
    gap: 68px !important;
  }

  .gap-md-120 {
    gap: 120px !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-4 {
    margin: 4px !important;
  }

  .m-lg-8 {
    margin: 8px !important;
  }

  .m-lg-10 {
    margin: 10px !important;
  }

  .m-lg-12 {
    margin: 12px !important;
  }

  .m-lg-15 {
    margin: 15px !important;
  }

  .m-lg-16 {
    margin: 16px !important;
  }

  .m-lg-20 {
    margin: 20px !important;
  }

  .m-lg-24 {
    margin: 24px !important;
  }

  .m-lg-30 {
    margin: 30px !important;
  }

  .m-lg-32 {
    margin: 32px !important;
  }

  .m-lg-36 {
    margin: 36px !important;
  }

  .m-lg-40 {
    margin: 40px !important;
  }

  .m-lg-48 {
    margin: 48px !important;
  }

  .m-lg-64 {
    margin: 64px !important;
  }

  .m-lg-68 {
    margin: 68px !important;
  }

  .m-lg-120 {
    margin: 120px !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-4 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }

  .mx-lg-8 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }

  .mx-lg-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .mx-lg-12 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }

  .mx-lg-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }

  .mx-lg-16 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }

  .mx-lg-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .mx-lg-24 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }

  .mx-lg-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }

  .mx-lg-32 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }

  .mx-lg-36 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }

  .mx-lg-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .mx-lg-48 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }

  .mx-lg-64 {
    margin-right: 64px !important;
    margin-left: 64px !important;
  }

  .mx-lg-68 {
    margin-right: 68px !important;
    margin-left: 68px !important;
  }

  .mx-lg-120 {
    margin-right: 120px !important;
    margin-left: 120px !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-4 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .my-lg-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .my-lg-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .my-lg-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .my-lg-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .my-lg-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .my-lg-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-lg-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .my-lg-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-lg-32 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .my-lg-36 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .my-lg-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-lg-48 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .my-lg-64 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }

  .my-lg-68 {
    margin-top: 68px !important;
    margin-bottom: 68px !important;
  }

  .my-lg-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-4 {
    margin-top: 4px !important;
  }

  .mt-lg-8 {
    margin-top: 8px !important;
  }

  .mt-lg-10 {
    margin-top: 10px !important;
  }

  .mt-lg-12 {
    margin-top: 12px !important;
  }

  .mt-lg-15 {
    margin-top: 15px !important;
  }

  .mt-lg-16 {
    margin-top: 16px !important;
  }

  .mt-lg-20 {
    margin-top: 20px !important;
  }

  .mt-lg-24 {
    margin-top: 24px !important;
  }

  .mt-lg-30 {
    margin-top: 30px !important;
  }

  .mt-lg-32 {
    margin-top: 32px !important;
  }

  .mt-lg-36 {
    margin-top: 36px !important;
  }

  .mt-lg-40 {
    margin-top: 40px !important;
  }

  .mt-lg-48 {
    margin-top: 48px !important;
  }

  .mt-lg-64 {
    margin-top: 64px !important;
  }

  .mt-lg-68 {
    margin-top: 68px !important;
  }

  .mt-lg-120 {
    margin-top: 120px !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-4 {
    margin-right: 4px !important;
  }

  .me-lg-8 {
    margin-right: 8px !important;
  }

  .me-lg-10 {
    margin-right: 10px !important;
  }

  .me-lg-12 {
    margin-right: 12px !important;
  }

  .me-lg-15 {
    margin-right: 15px !important;
  }

  .me-lg-16 {
    margin-right: 16px !important;
  }

  .me-lg-20 {
    margin-right: 20px !important;
  }

  .me-lg-24 {
    margin-right: 24px !important;
  }

  .me-lg-30 {
    margin-right: 30px !important;
  }

  .me-lg-32 {
    margin-right: 32px !important;
  }

  .me-lg-36 {
    margin-right: 36px !important;
  }

  .me-lg-40 {
    margin-right: 40px !important;
  }

  .me-lg-48 {
    margin-right: 48px !important;
  }

  .me-lg-64 {
    margin-right: 64px !important;
  }

  .me-lg-68 {
    margin-right: 68px !important;
  }

  .me-lg-120 {
    margin-right: 120px !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-4 {
    margin-bottom: 4px !important;
  }

  .mb-lg-8 {
    margin-bottom: 8px !important;
  }

  .mb-lg-10 {
    margin-bottom: 10px !important;
  }

  .mb-lg-12 {
    margin-bottom: 12px !important;
  }

  .mb-lg-15 {
    margin-bottom: 15px !important;
  }

  .mb-lg-16 {
    margin-bottom: 16px !important;
  }

  .mb-lg-20 {
    margin-bottom: 20px !important;
  }

  .mb-lg-24 {
    margin-bottom: 24px !important;
  }

  .mb-lg-30 {
    margin-bottom: 30px !important;
  }

  .mb-lg-32 {
    margin-bottom: 32px !important;
  }

  .mb-lg-36 {
    margin-bottom: 36px !important;
  }

  .mb-lg-40 {
    margin-bottom: 40px !important;
  }

  .mb-lg-48 {
    margin-bottom: 48px !important;
  }

  .mb-lg-64 {
    margin-bottom: 64px !important;
  }

  .mb-lg-68 {
    margin-bottom: 68px !important;
  }

  .mb-lg-120 {
    margin-bottom: 120px !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-4 {
    margin-left: 4px !important;
  }

  .ms-lg-8 {
    margin-left: 8px !important;
  }

  .ms-lg-10 {
    margin-left: 10px !important;
  }

  .ms-lg-12 {
    margin-left: 12px !important;
  }

  .ms-lg-15 {
    margin-left: 15px !important;
  }

  .ms-lg-16 {
    margin-left: 16px !important;
  }

  .ms-lg-20 {
    margin-left: 20px !important;
  }

  .ms-lg-24 {
    margin-left: 24px !important;
  }

  .ms-lg-30 {
    margin-left: 30px !important;
  }

  .ms-lg-32 {
    margin-left: 32px !important;
  }

  .ms-lg-36 {
    margin-left: 36px !important;
  }

  .ms-lg-40 {
    margin-left: 40px !important;
  }

  .ms-lg-48 {
    margin-left: 48px !important;
  }

  .ms-lg-64 {
    margin-left: 64px !important;
  }

  .ms-lg-68 {
    margin-left: 68px !important;
  }

  .ms-lg-120 {
    margin-left: 120px !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n4 {
    margin: -4px !important;
  }

  .m-lg-n8 {
    margin: -8px !important;
  }

  .m-lg-n10 {
    margin: -10px !important;
  }

  .m-lg-n12 {
    margin: -12px !important;
  }

  .m-lg-n15 {
    margin: -15px !important;
  }

  .m-lg-n16 {
    margin: -16px !important;
  }

  .m-lg-n20 {
    margin: -20px !important;
  }

  .m-lg-n24 {
    margin: -24px !important;
  }

  .m-lg-n30 {
    margin: -30px !important;
  }

  .m-lg-n32 {
    margin: -32px !important;
  }

  .m-lg-n36 {
    margin: -36px !important;
  }

  .m-lg-n40 {
    margin: -40px !important;
  }

  .m-lg-n48 {
    margin: -48px !important;
  }

  .m-lg-n64 {
    margin: -64px !important;
  }

  .m-lg-n68 {
    margin: -68px !important;
  }

  .m-lg-n120 {
    margin: -120px !important;
  }

  .mx-lg-n4 {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }

  .mx-lg-n8 {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }

  .mx-lg-n10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }

  .mx-lg-n12 {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }

  .mx-lg-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  .mx-lg-n16 {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }

  .mx-lg-n20 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }

  .mx-lg-n24 {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }

  .mx-lg-n30 {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }

  .mx-lg-n32 {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }

  .mx-lg-n36 {
    margin-right: -36px !important;
    margin-left: -36px !important;
  }

  .mx-lg-n40 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }

  .mx-lg-n48 {
    margin-right: -48px !important;
    margin-left: -48px !important;
  }

  .mx-lg-n64 {
    margin-right: -64px !important;
    margin-left: -64px !important;
  }

  .mx-lg-n68 {
    margin-right: -68px !important;
    margin-left: -68px !important;
  }

  .mx-lg-n120 {
    margin-right: -120px !important;
    margin-left: -120px !important;
  }

  .my-lg-n4 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .my-lg-n8 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .my-lg-n10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .my-lg-n12 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .my-lg-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .my-lg-n16 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .my-lg-n20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .my-lg-n24 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .my-lg-n30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }

  .my-lg-n32 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .my-lg-n36 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .my-lg-n40 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .my-lg-n48 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .my-lg-n64 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }

  .my-lg-n68 {
    margin-top: -68px !important;
    margin-bottom: -68px !important;
  }

  .my-lg-n120 {
    margin-top: -120px !important;
    margin-bottom: -120px !important;
  }

  .mt-lg-n4 {
    margin-top: -4px !important;
  }

  .mt-lg-n8 {
    margin-top: -8px !important;
  }

  .mt-lg-n10 {
    margin-top: -10px !important;
  }

  .mt-lg-n12 {
    margin-top: -12px !important;
  }

  .mt-lg-n15 {
    margin-top: -15px !important;
  }

  .mt-lg-n16 {
    margin-top: -16px !important;
  }

  .mt-lg-n20 {
    margin-top: -20px !important;
  }

  .mt-lg-n24 {
    margin-top: -24px !important;
  }

  .mt-lg-n30 {
    margin-top: -30px !important;
  }

  .mt-lg-n32 {
    margin-top: -32px !important;
  }

  .mt-lg-n36 {
    margin-top: -36px !important;
  }

  .mt-lg-n40 {
    margin-top: -40px !important;
  }

  .mt-lg-n48 {
    margin-top: -48px !important;
  }

  .mt-lg-n64 {
    margin-top: -64px !important;
  }

  .mt-lg-n68 {
    margin-top: -68px !important;
  }

  .mt-lg-n120 {
    margin-top: -120px !important;
  }

  .me-lg-n4 {
    margin-right: -4px !important;
  }

  .me-lg-n8 {
    margin-right: -8px !important;
  }

  .me-lg-n10 {
    margin-right: -10px !important;
  }

  .me-lg-n12 {
    margin-right: -12px !important;
  }

  .me-lg-n15 {
    margin-right: -15px !important;
  }

  .me-lg-n16 {
    margin-right: -16px !important;
  }

  .me-lg-n20 {
    margin-right: -20px !important;
  }

  .me-lg-n24 {
    margin-right: -24px !important;
  }

  .me-lg-n30 {
    margin-right: -30px !important;
  }

  .me-lg-n32 {
    margin-right: -32px !important;
  }

  .me-lg-n36 {
    margin-right: -36px !important;
  }

  .me-lg-n40 {
    margin-right: -40px !important;
  }

  .me-lg-n48 {
    margin-right: -48px !important;
  }

  .me-lg-n64 {
    margin-right: -64px !important;
  }

  .me-lg-n68 {
    margin-right: -68px !important;
  }

  .me-lg-n120 {
    margin-right: -120px !important;
  }

  .mb-lg-n4 {
    margin-bottom: -4px !important;
  }

  .mb-lg-n8 {
    margin-bottom: -8px !important;
  }

  .mb-lg-n10 {
    margin-bottom: -10px !important;
  }

  .mb-lg-n12 {
    margin-bottom: -12px !important;
  }

  .mb-lg-n15 {
    margin-bottom: -15px !important;
  }

  .mb-lg-n16 {
    margin-bottom: -16px !important;
  }

  .mb-lg-n20 {
    margin-bottom: -20px !important;
  }

  .mb-lg-n24 {
    margin-bottom: -24px !important;
  }

  .mb-lg-n30 {
    margin-bottom: -30px !important;
  }

  .mb-lg-n32 {
    margin-bottom: -32px !important;
  }

  .mb-lg-n36 {
    margin-bottom: -36px !important;
  }

  .mb-lg-n40 {
    margin-bottom: -40px !important;
  }

  .mb-lg-n48 {
    margin-bottom: -48px !important;
  }

  .mb-lg-n64 {
    margin-bottom: -64px !important;
  }

  .mb-lg-n68 {
    margin-bottom: -68px !important;
  }

  .mb-lg-n120 {
    margin-bottom: -120px !important;
  }

  .ms-lg-n4 {
    margin-left: -4px !important;
  }

  .ms-lg-n8 {
    margin-left: -8px !important;
  }

  .ms-lg-n10 {
    margin-left: -10px !important;
  }

  .ms-lg-n12 {
    margin-left: -12px !important;
  }

  .ms-lg-n15 {
    margin-left: -15px !important;
  }

  .ms-lg-n16 {
    margin-left: -16px !important;
  }

  .ms-lg-n20 {
    margin-left: -20px !important;
  }

  .ms-lg-n24 {
    margin-left: -24px !important;
  }

  .ms-lg-n30 {
    margin-left: -30px !important;
  }

  .ms-lg-n32 {
    margin-left: -32px !important;
  }

  .ms-lg-n36 {
    margin-left: -36px !important;
  }

  .ms-lg-n40 {
    margin-left: -40px !important;
  }

  .ms-lg-n48 {
    margin-left: -48px !important;
  }

  .ms-lg-n64 {
    margin-left: -64px !important;
  }

  .ms-lg-n68 {
    margin-left: -68px !important;
  }

  .ms-lg-n120 {
    margin-left: -120px !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-4 {
    padding: 4px !important;
  }

  .p-lg-8 {
    padding: 8px !important;
  }

  .p-lg-10 {
    padding: 10px !important;
  }

  .p-lg-12 {
    padding: 12px !important;
  }

  .p-lg-15 {
    padding: 15px !important;
  }

  .p-lg-16 {
    padding: 16px !important;
  }

  .p-lg-20 {
    padding: 20px !important;
  }

  .p-lg-24 {
    padding: 24px !important;
  }

  .p-lg-30 {
    padding: 30px !important;
  }

  .p-lg-32 {
    padding: 32px !important;
  }

  .p-lg-36 {
    padding: 36px !important;
  }

  .p-lg-40 {
    padding: 40px !important;
  }

  .p-lg-48 {
    padding: 48px !important;
  }

  .p-lg-64 {
    padding: 64px !important;
  }

  .p-lg-68 {
    padding: 68px !important;
  }

  .p-lg-120 {
    padding: 120px !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-4 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  .px-lg-8 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }

  .px-lg-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .px-lg-12 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }

  .px-lg-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .px-lg-16 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

  .px-lg-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .px-lg-24 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }

  .px-lg-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .px-lg-32 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }

  .px-lg-36 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }

  .px-lg-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .px-lg-48 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }

  .px-lg-64 {
    padding-right: 64px !important;
    padding-left: 64px !important;
  }

  .px-lg-68 {
    padding-right: 68px !important;
    padding-left: 68px !important;
  }

  .px-lg-120 {
    padding-right: 120px !important;
    padding-left: 120px !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .py-lg-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .py-lg-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .py-lg-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .py-lg-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .py-lg-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .py-lg-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-lg-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .py-lg-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-lg-32 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .py-lg-36 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .py-lg-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-lg-48 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .py-lg-64 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }

  .py-lg-68 {
    padding-top: 68px !important;
    padding-bottom: 68px !important;
  }

  .py-lg-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-4 {
    padding-top: 4px !important;
  }

  .pt-lg-8 {
    padding-top: 8px !important;
  }

  .pt-lg-10 {
    padding-top: 10px !important;
  }

  .pt-lg-12 {
    padding-top: 12px !important;
  }

  .pt-lg-15 {
    padding-top: 15px !important;
  }

  .pt-lg-16 {
    padding-top: 16px !important;
  }

  .pt-lg-20 {
    padding-top: 20px !important;
  }

  .pt-lg-24 {
    padding-top: 24px !important;
  }

  .pt-lg-30 {
    padding-top: 30px !important;
  }

  .pt-lg-32 {
    padding-top: 32px !important;
  }

  .pt-lg-36 {
    padding-top: 36px !important;
  }

  .pt-lg-40 {
    padding-top: 40px !important;
  }

  .pt-lg-48 {
    padding-top: 48px !important;
  }

  .pt-lg-64 {
    padding-top: 64px !important;
  }

  .pt-lg-68 {
    padding-top: 68px !important;
  }

  .pt-lg-120 {
    padding-top: 120px !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-4 {
    padding-right: 4px !important;
  }

  .pe-lg-8 {
    padding-right: 8px !important;
  }

  .pe-lg-10 {
    padding-right: 10px !important;
  }

  .pe-lg-12 {
    padding-right: 12px !important;
  }

  .pe-lg-15 {
    padding-right: 15px !important;
  }

  .pe-lg-16 {
    padding-right: 16px !important;
  }

  .pe-lg-20 {
    padding-right: 20px !important;
  }

  .pe-lg-24 {
    padding-right: 24px !important;
  }

  .pe-lg-30 {
    padding-right: 30px !important;
  }

  .pe-lg-32 {
    padding-right: 32px !important;
  }

  .pe-lg-36 {
    padding-right: 36px !important;
  }

  .pe-lg-40 {
    padding-right: 40px !important;
  }

  .pe-lg-48 {
    padding-right: 48px !important;
  }

  .pe-lg-64 {
    padding-right: 64px !important;
  }

  .pe-lg-68 {
    padding-right: 68px !important;
  }

  .pe-lg-120 {
    padding-right: 120px !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-4 {
    padding-bottom: 4px !important;
  }

  .pb-lg-8 {
    padding-bottom: 8px !important;
  }

  .pb-lg-10 {
    padding-bottom: 10px !important;
  }

  .pb-lg-12 {
    padding-bottom: 12px !important;
  }

  .pb-lg-15 {
    padding-bottom: 15px !important;
  }

  .pb-lg-16 {
    padding-bottom: 16px !important;
  }

  .pb-lg-20 {
    padding-bottom: 20px !important;
  }

  .pb-lg-24 {
    padding-bottom: 24px !important;
  }

  .pb-lg-30 {
    padding-bottom: 30px !important;
  }

  .pb-lg-32 {
    padding-bottom: 32px !important;
  }

  .pb-lg-36 {
    padding-bottom: 36px !important;
  }

  .pb-lg-40 {
    padding-bottom: 40px !important;
  }

  .pb-lg-48 {
    padding-bottom: 48px !important;
  }

  .pb-lg-64 {
    padding-bottom: 64px !important;
  }

  .pb-lg-68 {
    padding-bottom: 68px !important;
  }

  .pb-lg-120 {
    padding-bottom: 120px !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-4 {
    padding-left: 4px !important;
  }

  .ps-lg-8 {
    padding-left: 8px !important;
  }

  .ps-lg-10 {
    padding-left: 10px !important;
  }

  .ps-lg-12 {
    padding-left: 12px !important;
  }

  .ps-lg-15 {
    padding-left: 15px !important;
  }

  .ps-lg-16 {
    padding-left: 16px !important;
  }

  .ps-lg-20 {
    padding-left: 20px !important;
  }

  .ps-lg-24 {
    padding-left: 24px !important;
  }

  .ps-lg-30 {
    padding-left: 30px !important;
  }

  .ps-lg-32 {
    padding-left: 32px !important;
  }

  .ps-lg-36 {
    padding-left: 36px !important;
  }

  .ps-lg-40 {
    padding-left: 40px !important;
  }

  .ps-lg-48 {
    padding-left: 48px !important;
  }

  .ps-lg-64 {
    padding-left: 64px !important;
  }

  .ps-lg-68 {
    padding-left: 68px !important;
  }

  .ps-lg-120 {
    padding-left: 120px !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-4 {
    gap: 4px !important;
  }

  .gap-lg-8 {
    gap: 8px !important;
  }

  .gap-lg-10 {
    gap: 10px !important;
  }

  .gap-lg-12 {
    gap: 12px !important;
  }

  .gap-lg-15 {
    gap: 15px !important;
  }

  .gap-lg-16 {
    gap: 16px !important;
  }

  .gap-lg-20 {
    gap: 20px !important;
  }

  .gap-lg-24 {
    gap: 24px !important;
  }

  .gap-lg-30 {
    gap: 30px !important;
  }

  .gap-lg-32 {
    gap: 32px !important;
  }

  .gap-lg-36 {
    gap: 36px !important;
  }

  .gap-lg-40 {
    gap: 40px !important;
  }

  .gap-lg-48 {
    gap: 48px !important;
  }

  .gap-lg-64 {
    gap: 64px !important;
  }

  .gap-lg-68 {
    gap: 68px !important;
  }

  .gap-lg-120 {
    gap: 120px !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-4 {
    margin: 4px !important;
  }

  .m-xl-8 {
    margin: 8px !important;
  }

  .m-xl-10 {
    margin: 10px !important;
  }

  .m-xl-12 {
    margin: 12px !important;
  }

  .m-xl-15 {
    margin: 15px !important;
  }

  .m-xl-16 {
    margin: 16px !important;
  }

  .m-xl-20 {
    margin: 20px !important;
  }

  .m-xl-24 {
    margin: 24px !important;
  }

  .m-xl-30 {
    margin: 30px !important;
  }

  .m-xl-32 {
    margin: 32px !important;
  }

  .m-xl-36 {
    margin: 36px !important;
  }

  .m-xl-40 {
    margin: 40px !important;
  }

  .m-xl-48 {
    margin: 48px !important;
  }

  .m-xl-64 {
    margin: 64px !important;
  }

  .m-xl-68 {
    margin: 68px !important;
  }

  .m-xl-120 {
    margin: 120px !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-4 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }

  .mx-xl-8 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }

  .mx-xl-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .mx-xl-12 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }

  .mx-xl-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }

  .mx-xl-16 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }

  .mx-xl-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .mx-xl-24 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }

  .mx-xl-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }

  .mx-xl-32 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }

  .mx-xl-36 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }

  .mx-xl-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .mx-xl-48 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }

  .mx-xl-64 {
    margin-right: 64px !important;
    margin-left: 64px !important;
  }

  .mx-xl-68 {
    margin-right: 68px !important;
    margin-left: 68px !important;
  }

  .mx-xl-120 {
    margin-right: 120px !important;
    margin-left: 120px !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-4 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .my-xl-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .my-xl-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .my-xl-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .my-xl-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .my-xl-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .my-xl-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-xl-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .my-xl-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-xl-32 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .my-xl-36 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .my-xl-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-xl-48 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .my-xl-64 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }

  .my-xl-68 {
    margin-top: 68px !important;
    margin-bottom: 68px !important;
  }

  .my-xl-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-4 {
    margin-top: 4px !important;
  }

  .mt-xl-8 {
    margin-top: 8px !important;
  }

  .mt-xl-10 {
    margin-top: 10px !important;
  }

  .mt-xl-12 {
    margin-top: 12px !important;
  }

  .mt-xl-15 {
    margin-top: 15px !important;
  }

  .mt-xl-16 {
    margin-top: 16px !important;
  }

  .mt-xl-20 {
    margin-top: 20px !important;
  }

  .mt-xl-24 {
    margin-top: 24px !important;
  }

  .mt-xl-30 {
    margin-top: 30px !important;
  }

  .mt-xl-32 {
    margin-top: 32px !important;
  }

  .mt-xl-36 {
    margin-top: 36px !important;
  }

  .mt-xl-40 {
    margin-top: 40px !important;
  }

  .mt-xl-48 {
    margin-top: 48px !important;
  }

  .mt-xl-64 {
    margin-top: 64px !important;
  }

  .mt-xl-68 {
    margin-top: 68px !important;
  }

  .mt-xl-120 {
    margin-top: 120px !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-4 {
    margin-right: 4px !important;
  }

  .me-xl-8 {
    margin-right: 8px !important;
  }

  .me-xl-10 {
    margin-right: 10px !important;
  }

  .me-xl-12 {
    margin-right: 12px !important;
  }

  .me-xl-15 {
    margin-right: 15px !important;
  }

  .me-xl-16 {
    margin-right: 16px !important;
  }

  .me-xl-20 {
    margin-right: 20px !important;
  }

  .me-xl-24 {
    margin-right: 24px !important;
  }

  .me-xl-30 {
    margin-right: 30px !important;
  }

  .me-xl-32 {
    margin-right: 32px !important;
  }

  .me-xl-36 {
    margin-right: 36px !important;
  }

  .me-xl-40 {
    margin-right: 40px !important;
  }

  .me-xl-48 {
    margin-right: 48px !important;
  }

  .me-xl-64 {
    margin-right: 64px !important;
  }

  .me-xl-68 {
    margin-right: 68px !important;
  }

  .me-xl-120 {
    margin-right: 120px !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-4 {
    margin-bottom: 4px !important;
  }

  .mb-xl-8 {
    margin-bottom: 8px !important;
  }

  .mb-xl-10 {
    margin-bottom: 10px !important;
  }

  .mb-xl-12 {
    margin-bottom: 12px !important;
  }

  .mb-xl-15 {
    margin-bottom: 15px !important;
  }

  .mb-xl-16 {
    margin-bottom: 16px !important;
  }

  .mb-xl-20 {
    margin-bottom: 20px !important;
  }

  .mb-xl-24 {
    margin-bottom: 24px !important;
  }

  .mb-xl-30 {
    margin-bottom: 30px !important;
  }

  .mb-xl-32 {
    margin-bottom: 32px !important;
  }

  .mb-xl-36 {
    margin-bottom: 36px !important;
  }

  .mb-xl-40 {
    margin-bottom: 40px !important;
  }

  .mb-xl-48 {
    margin-bottom: 48px !important;
  }

  .mb-xl-64 {
    margin-bottom: 64px !important;
  }

  .mb-xl-68 {
    margin-bottom: 68px !important;
  }

  .mb-xl-120 {
    margin-bottom: 120px !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-4 {
    margin-left: 4px !important;
  }

  .ms-xl-8 {
    margin-left: 8px !important;
  }

  .ms-xl-10 {
    margin-left: 10px !important;
  }

  .ms-xl-12 {
    margin-left: 12px !important;
  }

  .ms-xl-15 {
    margin-left: 15px !important;
  }

  .ms-xl-16 {
    margin-left: 16px !important;
  }

  .ms-xl-20 {
    margin-left: 20px !important;
  }

  .ms-xl-24 {
    margin-left: 24px !important;
  }

  .ms-xl-30 {
    margin-left: 30px !important;
  }

  .ms-xl-32 {
    margin-left: 32px !important;
  }

  .ms-xl-36 {
    margin-left: 36px !important;
  }

  .ms-xl-40 {
    margin-left: 40px !important;
  }

  .ms-xl-48 {
    margin-left: 48px !important;
  }

  .ms-xl-64 {
    margin-left: 64px !important;
  }

  .ms-xl-68 {
    margin-left: 68px !important;
  }

  .ms-xl-120 {
    margin-left: 120px !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n4 {
    margin: -4px !important;
  }

  .m-xl-n8 {
    margin: -8px !important;
  }

  .m-xl-n10 {
    margin: -10px !important;
  }

  .m-xl-n12 {
    margin: -12px !important;
  }

  .m-xl-n15 {
    margin: -15px !important;
  }

  .m-xl-n16 {
    margin: -16px !important;
  }

  .m-xl-n20 {
    margin: -20px !important;
  }

  .m-xl-n24 {
    margin: -24px !important;
  }

  .m-xl-n30 {
    margin: -30px !important;
  }

  .m-xl-n32 {
    margin: -32px !important;
  }

  .m-xl-n36 {
    margin: -36px !important;
  }

  .m-xl-n40 {
    margin: -40px !important;
  }

  .m-xl-n48 {
    margin: -48px !important;
  }

  .m-xl-n64 {
    margin: -64px !important;
  }

  .m-xl-n68 {
    margin: -68px !important;
  }

  .m-xl-n120 {
    margin: -120px !important;
  }

  .mx-xl-n4 {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }

  .mx-xl-n8 {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }

  .mx-xl-n10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }

  .mx-xl-n12 {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }

  .mx-xl-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  .mx-xl-n16 {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }

  .mx-xl-n20 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }

  .mx-xl-n24 {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }

  .mx-xl-n30 {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }

  .mx-xl-n32 {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }

  .mx-xl-n36 {
    margin-right: -36px !important;
    margin-left: -36px !important;
  }

  .mx-xl-n40 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }

  .mx-xl-n48 {
    margin-right: -48px !important;
    margin-left: -48px !important;
  }

  .mx-xl-n64 {
    margin-right: -64px !important;
    margin-left: -64px !important;
  }

  .mx-xl-n68 {
    margin-right: -68px !important;
    margin-left: -68px !important;
  }

  .mx-xl-n120 {
    margin-right: -120px !important;
    margin-left: -120px !important;
  }

  .my-xl-n4 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .my-xl-n8 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .my-xl-n10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .my-xl-n12 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .my-xl-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .my-xl-n16 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .my-xl-n20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .my-xl-n24 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .my-xl-n30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }

  .my-xl-n32 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .my-xl-n36 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .my-xl-n40 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .my-xl-n48 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .my-xl-n64 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }

  .my-xl-n68 {
    margin-top: -68px !important;
    margin-bottom: -68px !important;
  }

  .my-xl-n120 {
    margin-top: -120px !important;
    margin-bottom: -120px !important;
  }

  .mt-xl-n4 {
    margin-top: -4px !important;
  }

  .mt-xl-n8 {
    margin-top: -8px !important;
  }

  .mt-xl-n10 {
    margin-top: -10px !important;
  }

  .mt-xl-n12 {
    margin-top: -12px !important;
  }

  .mt-xl-n15 {
    margin-top: -15px !important;
  }

  .mt-xl-n16 {
    margin-top: -16px !important;
  }

  .mt-xl-n20 {
    margin-top: -20px !important;
  }

  .mt-xl-n24 {
    margin-top: -24px !important;
  }

  .mt-xl-n30 {
    margin-top: -30px !important;
  }

  .mt-xl-n32 {
    margin-top: -32px !important;
  }

  .mt-xl-n36 {
    margin-top: -36px !important;
  }

  .mt-xl-n40 {
    margin-top: -40px !important;
  }

  .mt-xl-n48 {
    margin-top: -48px !important;
  }

  .mt-xl-n64 {
    margin-top: -64px !important;
  }

  .mt-xl-n68 {
    margin-top: -68px !important;
  }

  .mt-xl-n120 {
    margin-top: -120px !important;
  }

  .me-xl-n4 {
    margin-right: -4px !important;
  }

  .me-xl-n8 {
    margin-right: -8px !important;
  }

  .me-xl-n10 {
    margin-right: -10px !important;
  }

  .me-xl-n12 {
    margin-right: -12px !important;
  }

  .me-xl-n15 {
    margin-right: -15px !important;
  }

  .me-xl-n16 {
    margin-right: -16px !important;
  }

  .me-xl-n20 {
    margin-right: -20px !important;
  }

  .me-xl-n24 {
    margin-right: -24px !important;
  }

  .me-xl-n30 {
    margin-right: -30px !important;
  }

  .me-xl-n32 {
    margin-right: -32px !important;
  }

  .me-xl-n36 {
    margin-right: -36px !important;
  }

  .me-xl-n40 {
    margin-right: -40px !important;
  }

  .me-xl-n48 {
    margin-right: -48px !important;
  }

  .me-xl-n64 {
    margin-right: -64px !important;
  }

  .me-xl-n68 {
    margin-right: -68px !important;
  }

  .me-xl-n120 {
    margin-right: -120px !important;
  }

  .mb-xl-n4 {
    margin-bottom: -4px !important;
  }

  .mb-xl-n8 {
    margin-bottom: -8px !important;
  }

  .mb-xl-n10 {
    margin-bottom: -10px !important;
  }

  .mb-xl-n12 {
    margin-bottom: -12px !important;
  }

  .mb-xl-n15 {
    margin-bottom: -15px !important;
  }

  .mb-xl-n16 {
    margin-bottom: -16px !important;
  }

  .mb-xl-n20 {
    margin-bottom: -20px !important;
  }

  .mb-xl-n24 {
    margin-bottom: -24px !important;
  }

  .mb-xl-n30 {
    margin-bottom: -30px !important;
  }

  .mb-xl-n32 {
    margin-bottom: -32px !important;
  }

  .mb-xl-n36 {
    margin-bottom: -36px !important;
  }

  .mb-xl-n40 {
    margin-bottom: -40px !important;
  }

  .mb-xl-n48 {
    margin-bottom: -48px !important;
  }

  .mb-xl-n64 {
    margin-bottom: -64px !important;
  }

  .mb-xl-n68 {
    margin-bottom: -68px !important;
  }

  .mb-xl-n120 {
    margin-bottom: -120px !important;
  }

  .ms-xl-n4 {
    margin-left: -4px !important;
  }

  .ms-xl-n8 {
    margin-left: -8px !important;
  }

  .ms-xl-n10 {
    margin-left: -10px !important;
  }

  .ms-xl-n12 {
    margin-left: -12px !important;
  }

  .ms-xl-n15 {
    margin-left: -15px !important;
  }

  .ms-xl-n16 {
    margin-left: -16px !important;
  }

  .ms-xl-n20 {
    margin-left: -20px !important;
  }

  .ms-xl-n24 {
    margin-left: -24px !important;
  }

  .ms-xl-n30 {
    margin-left: -30px !important;
  }

  .ms-xl-n32 {
    margin-left: -32px !important;
  }

  .ms-xl-n36 {
    margin-left: -36px !important;
  }

  .ms-xl-n40 {
    margin-left: -40px !important;
  }

  .ms-xl-n48 {
    margin-left: -48px !important;
  }

  .ms-xl-n64 {
    margin-left: -64px !important;
  }

  .ms-xl-n68 {
    margin-left: -68px !important;
  }

  .ms-xl-n120 {
    margin-left: -120px !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-4 {
    padding: 4px !important;
  }

  .p-xl-8 {
    padding: 8px !important;
  }

  .p-xl-10 {
    padding: 10px !important;
  }

  .p-xl-12 {
    padding: 12px !important;
  }

  .p-xl-15 {
    padding: 15px !important;
  }

  .p-xl-16 {
    padding: 16px !important;
  }

  .p-xl-20 {
    padding: 20px !important;
  }

  .p-xl-24 {
    padding: 24px !important;
  }

  .p-xl-30 {
    padding: 30px !important;
  }

  .p-xl-32 {
    padding: 32px !important;
  }

  .p-xl-36 {
    padding: 36px !important;
  }

  .p-xl-40 {
    padding: 40px !important;
  }

  .p-xl-48 {
    padding: 48px !important;
  }

  .p-xl-64 {
    padding: 64px !important;
  }

  .p-xl-68 {
    padding: 68px !important;
  }

  .p-xl-120 {
    padding: 120px !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-4 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  .px-xl-8 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }

  .px-xl-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .px-xl-12 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }

  .px-xl-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .px-xl-16 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

  .px-xl-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .px-xl-24 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }

  .px-xl-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .px-xl-32 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }

  .px-xl-36 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }

  .px-xl-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .px-xl-48 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }

  .px-xl-64 {
    padding-right: 64px !important;
    padding-left: 64px !important;
  }

  .px-xl-68 {
    padding-right: 68px !important;
    padding-left: 68px !important;
  }

  .px-xl-120 {
    padding-right: 120px !important;
    padding-left: 120px !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .py-xl-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .py-xl-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .py-xl-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .py-xl-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .py-xl-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .py-xl-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-xl-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .py-xl-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-xl-32 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .py-xl-36 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .py-xl-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-xl-48 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .py-xl-64 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }

  .py-xl-68 {
    padding-top: 68px !important;
    padding-bottom: 68px !important;
  }

  .py-xl-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-4 {
    padding-top: 4px !important;
  }

  .pt-xl-8 {
    padding-top: 8px !important;
  }

  .pt-xl-10 {
    padding-top: 10px !important;
  }

  .pt-xl-12 {
    padding-top: 12px !important;
  }

  .pt-xl-15 {
    padding-top: 15px !important;
  }

  .pt-xl-16 {
    padding-top: 16px !important;
  }

  .pt-xl-20 {
    padding-top: 20px !important;
  }

  .pt-xl-24 {
    padding-top: 24px !important;
  }

  .pt-xl-30 {
    padding-top: 30px !important;
  }

  .pt-xl-32 {
    padding-top: 32px !important;
  }

  .pt-xl-36 {
    padding-top: 36px !important;
  }

  .pt-xl-40 {
    padding-top: 40px !important;
  }

  .pt-xl-48 {
    padding-top: 48px !important;
  }

  .pt-xl-64 {
    padding-top: 64px !important;
  }

  .pt-xl-68 {
    padding-top: 68px !important;
  }

  .pt-xl-120 {
    padding-top: 120px !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-4 {
    padding-right: 4px !important;
  }

  .pe-xl-8 {
    padding-right: 8px !important;
  }

  .pe-xl-10 {
    padding-right: 10px !important;
  }

  .pe-xl-12 {
    padding-right: 12px !important;
  }

  .pe-xl-15 {
    padding-right: 15px !important;
  }

  .pe-xl-16 {
    padding-right: 16px !important;
  }

  .pe-xl-20 {
    padding-right: 20px !important;
  }

  .pe-xl-24 {
    padding-right: 24px !important;
  }

  .pe-xl-30 {
    padding-right: 30px !important;
  }

  .pe-xl-32 {
    padding-right: 32px !important;
  }

  .pe-xl-36 {
    padding-right: 36px !important;
  }

  .pe-xl-40 {
    padding-right: 40px !important;
  }

  .pe-xl-48 {
    padding-right: 48px !important;
  }

  .pe-xl-64 {
    padding-right: 64px !important;
  }

  .pe-xl-68 {
    padding-right: 68px !important;
  }

  .pe-xl-120 {
    padding-right: 120px !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-4 {
    padding-bottom: 4px !important;
  }

  .pb-xl-8 {
    padding-bottom: 8px !important;
  }

  .pb-xl-10 {
    padding-bottom: 10px !important;
  }

  .pb-xl-12 {
    padding-bottom: 12px !important;
  }

  .pb-xl-15 {
    padding-bottom: 15px !important;
  }

  .pb-xl-16 {
    padding-bottom: 16px !important;
  }

  .pb-xl-20 {
    padding-bottom: 20px !important;
  }

  .pb-xl-24 {
    padding-bottom: 24px !important;
  }

  .pb-xl-30 {
    padding-bottom: 30px !important;
  }

  .pb-xl-32 {
    padding-bottom: 32px !important;
  }

  .pb-xl-36 {
    padding-bottom: 36px !important;
  }

  .pb-xl-40 {
    padding-bottom: 40px !important;
  }

  .pb-xl-48 {
    padding-bottom: 48px !important;
  }

  .pb-xl-64 {
    padding-bottom: 64px !important;
  }

  .pb-xl-68 {
    padding-bottom: 68px !important;
  }

  .pb-xl-120 {
    padding-bottom: 120px !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-4 {
    padding-left: 4px !important;
  }

  .ps-xl-8 {
    padding-left: 8px !important;
  }

  .ps-xl-10 {
    padding-left: 10px !important;
  }

  .ps-xl-12 {
    padding-left: 12px !important;
  }

  .ps-xl-15 {
    padding-left: 15px !important;
  }

  .ps-xl-16 {
    padding-left: 16px !important;
  }

  .ps-xl-20 {
    padding-left: 20px !important;
  }

  .ps-xl-24 {
    padding-left: 24px !important;
  }

  .ps-xl-30 {
    padding-left: 30px !important;
  }

  .ps-xl-32 {
    padding-left: 32px !important;
  }

  .ps-xl-36 {
    padding-left: 36px !important;
  }

  .ps-xl-40 {
    padding-left: 40px !important;
  }

  .ps-xl-48 {
    padding-left: 48px !important;
  }

  .ps-xl-64 {
    padding-left: 64px !important;
  }

  .ps-xl-68 {
    padding-left: 68px !important;
  }

  .ps-xl-120 {
    padding-left: 120px !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-4 {
    gap: 4px !important;
  }

  .gap-xl-8 {
    gap: 8px !important;
  }

  .gap-xl-10 {
    gap: 10px !important;
  }

  .gap-xl-12 {
    gap: 12px !important;
  }

  .gap-xl-15 {
    gap: 15px !important;
  }

  .gap-xl-16 {
    gap: 16px !important;
  }

  .gap-xl-20 {
    gap: 20px !important;
  }

  .gap-xl-24 {
    gap: 24px !important;
  }

  .gap-xl-30 {
    gap: 30px !important;
  }

  .gap-xl-32 {
    gap: 32px !important;
  }

  .gap-xl-36 {
    gap: 36px !important;
  }

  .gap-xl-40 {
    gap: 40px !important;
  }

  .gap-xl-48 {
    gap: 48px !important;
  }

  .gap-xl-64 {
    gap: 64px !important;
  }

  .gap-xl-68 {
    gap: 68px !important;
  }

  .gap-xl-120 {
    gap: 120px !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-4 {
    margin: 4px !important;
  }

  .m-xxl-8 {
    margin: 8px !important;
  }

  .m-xxl-10 {
    margin: 10px !important;
  }

  .m-xxl-12 {
    margin: 12px !important;
  }

  .m-xxl-15 {
    margin: 15px !important;
  }

  .m-xxl-16 {
    margin: 16px !important;
  }

  .m-xxl-20 {
    margin: 20px !important;
  }

  .m-xxl-24 {
    margin: 24px !important;
  }

  .m-xxl-30 {
    margin: 30px !important;
  }

  .m-xxl-32 {
    margin: 32px !important;
  }

  .m-xxl-36 {
    margin: 36px !important;
  }

  .m-xxl-40 {
    margin: 40px !important;
  }

  .m-xxl-48 {
    margin: 48px !important;
  }

  .m-xxl-64 {
    margin: 64px !important;
  }

  .m-xxl-68 {
    margin: 68px !important;
  }

  .m-xxl-120 {
    margin: 120px !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-4 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }

  .mx-xxl-8 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }

  .mx-xxl-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .mx-xxl-12 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }

  .mx-xxl-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }

  .mx-xxl-16 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }

  .mx-xxl-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .mx-xxl-24 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }

  .mx-xxl-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }

  .mx-xxl-32 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }

  .mx-xxl-36 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }

  .mx-xxl-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .mx-xxl-48 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }

  .mx-xxl-64 {
    margin-right: 64px !important;
    margin-left: 64px !important;
  }

  .mx-xxl-68 {
    margin-right: 68px !important;
    margin-left: 68px !important;
  }

  .mx-xxl-120 {
    margin-right: 120px !important;
    margin-left: 120px !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-4 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .my-xxl-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .my-xxl-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .my-xxl-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .my-xxl-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .my-xxl-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .my-xxl-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-xxl-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .my-xxl-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-xxl-32 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .my-xxl-36 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .my-xxl-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-xxl-48 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .my-xxl-64 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }

  .my-xxl-68 {
    margin-top: 68px !important;
    margin-bottom: 68px !important;
  }

  .my-xxl-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-4 {
    margin-top: 4px !important;
  }

  .mt-xxl-8 {
    margin-top: 8px !important;
  }

  .mt-xxl-10 {
    margin-top: 10px !important;
  }

  .mt-xxl-12 {
    margin-top: 12px !important;
  }

  .mt-xxl-15 {
    margin-top: 15px !important;
  }

  .mt-xxl-16 {
    margin-top: 16px !important;
  }

  .mt-xxl-20 {
    margin-top: 20px !important;
  }

  .mt-xxl-24 {
    margin-top: 24px !important;
  }

  .mt-xxl-30 {
    margin-top: 30px !important;
  }

  .mt-xxl-32 {
    margin-top: 32px !important;
  }

  .mt-xxl-36 {
    margin-top: 36px !important;
  }

  .mt-xxl-40 {
    margin-top: 40px !important;
  }

  .mt-xxl-48 {
    margin-top: 48px !important;
  }

  .mt-xxl-64 {
    margin-top: 64px !important;
  }

  .mt-xxl-68 {
    margin-top: 68px !important;
  }

  .mt-xxl-120 {
    margin-top: 120px !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-4 {
    margin-right: 4px !important;
  }

  .me-xxl-8 {
    margin-right: 8px !important;
  }

  .me-xxl-10 {
    margin-right: 10px !important;
  }

  .me-xxl-12 {
    margin-right: 12px !important;
  }

  .me-xxl-15 {
    margin-right: 15px !important;
  }

  .me-xxl-16 {
    margin-right: 16px !important;
  }

  .me-xxl-20 {
    margin-right: 20px !important;
  }

  .me-xxl-24 {
    margin-right: 24px !important;
  }

  .me-xxl-30 {
    margin-right: 30px !important;
  }

  .me-xxl-32 {
    margin-right: 32px !important;
  }

  .me-xxl-36 {
    margin-right: 36px !important;
  }

  .me-xxl-40 {
    margin-right: 40px !important;
  }

  .me-xxl-48 {
    margin-right: 48px !important;
  }

  .me-xxl-64 {
    margin-right: 64px !important;
  }

  .me-xxl-68 {
    margin-right: 68px !important;
  }

  .me-xxl-120 {
    margin-right: 120px !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-4 {
    margin-bottom: 4px !important;
  }

  .mb-xxl-8 {
    margin-bottom: 8px !important;
  }

  .mb-xxl-10 {
    margin-bottom: 10px !important;
  }

  .mb-xxl-12 {
    margin-bottom: 12px !important;
  }

  .mb-xxl-15 {
    margin-bottom: 15px !important;
  }

  .mb-xxl-16 {
    margin-bottom: 16px !important;
  }

  .mb-xxl-20 {
    margin-bottom: 20px !important;
  }

  .mb-xxl-24 {
    margin-bottom: 24px !important;
  }

  .mb-xxl-30 {
    margin-bottom: 30px !important;
  }

  .mb-xxl-32 {
    margin-bottom: 32px !important;
  }

  .mb-xxl-36 {
    margin-bottom: 36px !important;
  }

  .mb-xxl-40 {
    margin-bottom: 40px !important;
  }

  .mb-xxl-48 {
    margin-bottom: 48px !important;
  }

  .mb-xxl-64 {
    margin-bottom: 64px !important;
  }

  .mb-xxl-68 {
    margin-bottom: 68px !important;
  }

  .mb-xxl-120 {
    margin-bottom: 120px !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-4 {
    margin-left: 4px !important;
  }

  .ms-xxl-8 {
    margin-left: 8px !important;
  }

  .ms-xxl-10 {
    margin-left: 10px !important;
  }

  .ms-xxl-12 {
    margin-left: 12px !important;
  }

  .ms-xxl-15 {
    margin-left: 15px !important;
  }

  .ms-xxl-16 {
    margin-left: 16px !important;
  }

  .ms-xxl-20 {
    margin-left: 20px !important;
  }

  .ms-xxl-24 {
    margin-left: 24px !important;
  }

  .ms-xxl-30 {
    margin-left: 30px !important;
  }

  .ms-xxl-32 {
    margin-left: 32px !important;
  }

  .ms-xxl-36 {
    margin-left: 36px !important;
  }

  .ms-xxl-40 {
    margin-left: 40px !important;
  }

  .ms-xxl-48 {
    margin-left: 48px !important;
  }

  .ms-xxl-64 {
    margin-left: 64px !important;
  }

  .ms-xxl-68 {
    margin-left: 68px !important;
  }

  .ms-xxl-120 {
    margin-left: 120px !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n4 {
    margin: -4px !important;
  }

  .m-xxl-n8 {
    margin: -8px !important;
  }

  .m-xxl-n10 {
    margin: -10px !important;
  }

  .m-xxl-n12 {
    margin: -12px !important;
  }

  .m-xxl-n15 {
    margin: -15px !important;
  }

  .m-xxl-n16 {
    margin: -16px !important;
  }

  .m-xxl-n20 {
    margin: -20px !important;
  }

  .m-xxl-n24 {
    margin: -24px !important;
  }

  .m-xxl-n30 {
    margin: -30px !important;
  }

  .m-xxl-n32 {
    margin: -32px !important;
  }

  .m-xxl-n36 {
    margin: -36px !important;
  }

  .m-xxl-n40 {
    margin: -40px !important;
  }

  .m-xxl-n48 {
    margin: -48px !important;
  }

  .m-xxl-n64 {
    margin: -64px !important;
  }

  .m-xxl-n68 {
    margin: -68px !important;
  }

  .m-xxl-n120 {
    margin: -120px !important;
  }

  .mx-xxl-n4 {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }

  .mx-xxl-n8 {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }

  .mx-xxl-n10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }

  .mx-xxl-n12 {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }

  .mx-xxl-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  .mx-xxl-n16 {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }

  .mx-xxl-n20 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }

  .mx-xxl-n24 {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }

  .mx-xxl-n30 {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }

  .mx-xxl-n32 {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }

  .mx-xxl-n36 {
    margin-right: -36px !important;
    margin-left: -36px !important;
  }

  .mx-xxl-n40 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }

  .mx-xxl-n48 {
    margin-right: -48px !important;
    margin-left: -48px !important;
  }

  .mx-xxl-n64 {
    margin-right: -64px !important;
    margin-left: -64px !important;
  }

  .mx-xxl-n68 {
    margin-right: -68px !important;
    margin-left: -68px !important;
  }

  .mx-xxl-n120 {
    margin-right: -120px !important;
    margin-left: -120px !important;
  }

  .my-xxl-n4 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .my-xxl-n8 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .my-xxl-n10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .my-xxl-n12 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .my-xxl-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .my-xxl-n16 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .my-xxl-n20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .my-xxl-n24 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .my-xxl-n30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }

  .my-xxl-n32 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .my-xxl-n36 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .my-xxl-n40 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .my-xxl-n48 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .my-xxl-n64 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }

  .my-xxl-n68 {
    margin-top: -68px !important;
    margin-bottom: -68px !important;
  }

  .my-xxl-n120 {
    margin-top: -120px !important;
    margin-bottom: -120px !important;
  }

  .mt-xxl-n4 {
    margin-top: -4px !important;
  }

  .mt-xxl-n8 {
    margin-top: -8px !important;
  }

  .mt-xxl-n10 {
    margin-top: -10px !important;
  }

  .mt-xxl-n12 {
    margin-top: -12px !important;
  }

  .mt-xxl-n15 {
    margin-top: -15px !important;
  }

  .mt-xxl-n16 {
    margin-top: -16px !important;
  }

  .mt-xxl-n20 {
    margin-top: -20px !important;
  }

  .mt-xxl-n24 {
    margin-top: -24px !important;
  }

  .mt-xxl-n30 {
    margin-top: -30px !important;
  }

  .mt-xxl-n32 {
    margin-top: -32px !important;
  }

  .mt-xxl-n36 {
    margin-top: -36px !important;
  }

  .mt-xxl-n40 {
    margin-top: -40px !important;
  }

  .mt-xxl-n48 {
    margin-top: -48px !important;
  }

  .mt-xxl-n64 {
    margin-top: -64px !important;
  }

  .mt-xxl-n68 {
    margin-top: -68px !important;
  }

  .mt-xxl-n120 {
    margin-top: -120px !important;
  }

  .me-xxl-n4 {
    margin-right: -4px !important;
  }

  .me-xxl-n8 {
    margin-right: -8px !important;
  }

  .me-xxl-n10 {
    margin-right: -10px !important;
  }

  .me-xxl-n12 {
    margin-right: -12px !important;
  }

  .me-xxl-n15 {
    margin-right: -15px !important;
  }

  .me-xxl-n16 {
    margin-right: -16px !important;
  }

  .me-xxl-n20 {
    margin-right: -20px !important;
  }

  .me-xxl-n24 {
    margin-right: -24px !important;
  }

  .me-xxl-n30 {
    margin-right: -30px !important;
  }

  .me-xxl-n32 {
    margin-right: -32px !important;
  }

  .me-xxl-n36 {
    margin-right: -36px !important;
  }

  .me-xxl-n40 {
    margin-right: -40px !important;
  }

  .me-xxl-n48 {
    margin-right: -48px !important;
  }

  .me-xxl-n64 {
    margin-right: -64px !important;
  }

  .me-xxl-n68 {
    margin-right: -68px !important;
  }

  .me-xxl-n120 {
    margin-right: -120px !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -4px !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -8px !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -10px !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -12px !important;
  }

  .mb-xxl-n15 {
    margin-bottom: -15px !important;
  }

  .mb-xxl-n16 {
    margin-bottom: -16px !important;
  }

  .mb-xxl-n20 {
    margin-bottom: -20px !important;
  }

  .mb-xxl-n24 {
    margin-bottom: -24px !important;
  }

  .mb-xxl-n30 {
    margin-bottom: -30px !important;
  }

  .mb-xxl-n32 {
    margin-bottom: -32px !important;
  }

  .mb-xxl-n36 {
    margin-bottom: -36px !important;
  }

  .mb-xxl-n40 {
    margin-bottom: -40px !important;
  }

  .mb-xxl-n48 {
    margin-bottom: -48px !important;
  }

  .mb-xxl-n64 {
    margin-bottom: -64px !important;
  }

  .mb-xxl-n68 {
    margin-bottom: -68px !important;
  }

  .mb-xxl-n120 {
    margin-bottom: -120px !important;
  }

  .ms-xxl-n4 {
    margin-left: -4px !important;
  }

  .ms-xxl-n8 {
    margin-left: -8px !important;
  }

  .ms-xxl-n10 {
    margin-left: -10px !important;
  }

  .ms-xxl-n12 {
    margin-left: -12px !important;
  }

  .ms-xxl-n15 {
    margin-left: -15px !important;
  }

  .ms-xxl-n16 {
    margin-left: -16px !important;
  }

  .ms-xxl-n20 {
    margin-left: -20px !important;
  }

  .ms-xxl-n24 {
    margin-left: -24px !important;
  }

  .ms-xxl-n30 {
    margin-left: -30px !important;
  }

  .ms-xxl-n32 {
    margin-left: -32px !important;
  }

  .ms-xxl-n36 {
    margin-left: -36px !important;
  }

  .ms-xxl-n40 {
    margin-left: -40px !important;
  }

  .ms-xxl-n48 {
    margin-left: -48px !important;
  }

  .ms-xxl-n64 {
    margin-left: -64px !important;
  }

  .ms-xxl-n68 {
    margin-left: -68px !important;
  }

  .ms-xxl-n120 {
    margin-left: -120px !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-4 {
    padding: 4px !important;
  }

  .p-xxl-8 {
    padding: 8px !important;
  }

  .p-xxl-10 {
    padding: 10px !important;
  }

  .p-xxl-12 {
    padding: 12px !important;
  }

  .p-xxl-15 {
    padding: 15px !important;
  }

  .p-xxl-16 {
    padding: 16px !important;
  }

  .p-xxl-20 {
    padding: 20px !important;
  }

  .p-xxl-24 {
    padding: 24px !important;
  }

  .p-xxl-30 {
    padding: 30px !important;
  }

  .p-xxl-32 {
    padding: 32px !important;
  }

  .p-xxl-36 {
    padding: 36px !important;
  }

  .p-xxl-40 {
    padding: 40px !important;
  }

  .p-xxl-48 {
    padding: 48px !important;
  }

  .p-xxl-64 {
    padding: 64px !important;
  }

  .p-xxl-68 {
    padding: 68px !important;
  }

  .p-xxl-120 {
    padding: 120px !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-4 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  .px-xxl-8 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }

  .px-xxl-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .px-xxl-12 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }

  .px-xxl-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .px-xxl-16 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

  .px-xxl-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .px-xxl-24 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }

  .px-xxl-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .px-xxl-32 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }

  .px-xxl-36 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }

  .px-xxl-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .px-xxl-48 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }

  .px-xxl-64 {
    padding-right: 64px !important;
    padding-left: 64px !important;
  }

  .px-xxl-68 {
    padding-right: 68px !important;
    padding-left: 68px !important;
  }

  .px-xxl-120 {
    padding-right: 120px !important;
    padding-left: 120px !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .py-xxl-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .py-xxl-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .py-xxl-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .py-xxl-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .py-xxl-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .py-xxl-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-xxl-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .py-xxl-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-xxl-32 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .py-xxl-36 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .py-xxl-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-xxl-48 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .py-xxl-64 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }

  .py-xxl-68 {
    padding-top: 68px !important;
    padding-bottom: 68px !important;
  }

  .py-xxl-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-4 {
    padding-top: 4px !important;
  }

  .pt-xxl-8 {
    padding-top: 8px !important;
  }

  .pt-xxl-10 {
    padding-top: 10px !important;
  }

  .pt-xxl-12 {
    padding-top: 12px !important;
  }

  .pt-xxl-15 {
    padding-top: 15px !important;
  }

  .pt-xxl-16 {
    padding-top: 16px !important;
  }

  .pt-xxl-20 {
    padding-top: 20px !important;
  }

  .pt-xxl-24 {
    padding-top: 24px !important;
  }

  .pt-xxl-30 {
    padding-top: 30px !important;
  }

  .pt-xxl-32 {
    padding-top: 32px !important;
  }

  .pt-xxl-36 {
    padding-top: 36px !important;
  }

  .pt-xxl-40 {
    padding-top: 40px !important;
  }

  .pt-xxl-48 {
    padding-top: 48px !important;
  }

  .pt-xxl-64 {
    padding-top: 64px !important;
  }

  .pt-xxl-68 {
    padding-top: 68px !important;
  }

  .pt-xxl-120 {
    padding-top: 120px !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-4 {
    padding-right: 4px !important;
  }

  .pe-xxl-8 {
    padding-right: 8px !important;
  }

  .pe-xxl-10 {
    padding-right: 10px !important;
  }

  .pe-xxl-12 {
    padding-right: 12px !important;
  }

  .pe-xxl-15 {
    padding-right: 15px !important;
  }

  .pe-xxl-16 {
    padding-right: 16px !important;
  }

  .pe-xxl-20 {
    padding-right: 20px !important;
  }

  .pe-xxl-24 {
    padding-right: 24px !important;
  }

  .pe-xxl-30 {
    padding-right: 30px !important;
  }

  .pe-xxl-32 {
    padding-right: 32px !important;
  }

  .pe-xxl-36 {
    padding-right: 36px !important;
  }

  .pe-xxl-40 {
    padding-right: 40px !important;
  }

  .pe-xxl-48 {
    padding-right: 48px !important;
  }

  .pe-xxl-64 {
    padding-right: 64px !important;
  }

  .pe-xxl-68 {
    padding-right: 68px !important;
  }

  .pe-xxl-120 {
    padding-right: 120px !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-4 {
    padding-bottom: 4px !important;
  }

  .pb-xxl-8 {
    padding-bottom: 8px !important;
  }

  .pb-xxl-10 {
    padding-bottom: 10px !important;
  }

  .pb-xxl-12 {
    padding-bottom: 12px !important;
  }

  .pb-xxl-15 {
    padding-bottom: 15px !important;
  }

  .pb-xxl-16 {
    padding-bottom: 16px !important;
  }

  .pb-xxl-20 {
    padding-bottom: 20px !important;
  }

  .pb-xxl-24 {
    padding-bottom: 24px !important;
  }

  .pb-xxl-30 {
    padding-bottom: 30px !important;
  }

  .pb-xxl-32 {
    padding-bottom: 32px !important;
  }

  .pb-xxl-36 {
    padding-bottom: 36px !important;
  }

  .pb-xxl-40 {
    padding-bottom: 40px !important;
  }

  .pb-xxl-48 {
    padding-bottom: 48px !important;
  }

  .pb-xxl-64 {
    padding-bottom: 64px !important;
  }

  .pb-xxl-68 {
    padding-bottom: 68px !important;
  }

  .pb-xxl-120 {
    padding-bottom: 120px !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-4 {
    padding-left: 4px !important;
  }

  .ps-xxl-8 {
    padding-left: 8px !important;
  }

  .ps-xxl-10 {
    padding-left: 10px !important;
  }

  .ps-xxl-12 {
    padding-left: 12px !important;
  }

  .ps-xxl-15 {
    padding-left: 15px !important;
  }

  .ps-xxl-16 {
    padding-left: 16px !important;
  }

  .ps-xxl-20 {
    padding-left: 20px !important;
  }

  .ps-xxl-24 {
    padding-left: 24px !important;
  }

  .ps-xxl-30 {
    padding-left: 30px !important;
  }

  .ps-xxl-32 {
    padding-left: 32px !important;
  }

  .ps-xxl-36 {
    padding-left: 36px !important;
  }

  .ps-xxl-40 {
    padding-left: 40px !important;
  }

  .ps-xxl-48 {
    padding-left: 48px !important;
  }

  .ps-xxl-64 {
    padding-left: 64px !important;
  }

  .ps-xxl-68 {
    padding-left: 68px !important;
  }

  .ps-xxl-120 {
    padding-left: 120px !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-4 {
    gap: 4px !important;
  }

  .gap-xxl-8 {
    gap: 8px !important;
  }

  .gap-xxl-10 {
    gap: 10px !important;
  }

  .gap-xxl-12 {
    gap: 12px !important;
  }

  .gap-xxl-15 {
    gap: 15px !important;
  }

  .gap-xxl-16 {
    gap: 16px !important;
  }

  .gap-xxl-20 {
    gap: 20px !important;
  }

  .gap-xxl-24 {
    gap: 24px !important;
  }

  .gap-xxl-30 {
    gap: 30px !important;
  }

  .gap-xxl-32 {
    gap: 32px !important;
  }

  .gap-xxl-36 {
    gap: 36px !important;
  }

  .gap-xxl-40 {
    gap: 40px !important;
  }

  .gap-xxl-48 {
    gap: 48px !important;
  }

  .gap-xxl-64 {
    gap: 64px !important;
  }

  .gap-xxl-68 {
    gap: 68px !important;
  }

  .gap-xxl-120 {
    gap: 120px !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
* {
  outline: none;
  box-sizing: border-box;
}
*:after, *:before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  color: inherit;
}

img {
  max-width: 100%;
}

button {
  background: none;
  padding: 0;
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;
}
button:focus, button:active {
  box-shadow: none;
  outline: none;
}

table {
  border-collapse: collapse;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 1599.98px) {
  .container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    padding-left: 20px;
    padding-right: 20px;
  }
}

:root {
  --snt-blue: #0000ff;
}

html {
  font-size: 16px;
}

body {
  display: flex;
  flex-direction: column;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  min-height: 100vh;
  min-width: 320px;
  font-family: "Exo 2", sans-serif;
  color: #434343;
  background-color: #f4f4f4;
}
body .footer {
  margin-top: auto;
}

.btn, .btn-disabled, .btn-4, .btn-3, .btn-2 {
  background-color: #ee702a;
  padding: 12px 16px;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  white-space: nowrap;
  min-width: 100px;
}
.btn svg, .btn-disabled svg, .btn-4 svg, .btn-3 svg, .btn-2 svg {
  width: 20px;
  height: 20px;
}
.btn:hover, .btn-disabled:hover, .btn-4:hover, .btn-3:hover, .btn-2:hover {
  transition: 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.8;
}

.btn-2 {
  background-color: #ee702a26;
  color: #ee702a;
}

.btn-3 {
  color: #000;
  background-color: #fff;
  border: 1px solid #d2dde5;
}

.btn-4 {
  color: fff;
  background-color: #434343;
}

.btn-disabled {
  color: #fff;
  background-color: #b8bdc3;
  user-select: none;
}
.btn-disabled:hover {
  opacity: 1;
  cursor: default;
}

.color-gray {
  color: #adb4ba;
}
.color-gray:hover {
  color: #adb4ba;
}

.title {
  font-size: 36px;
  line-height: 150%;
  font-weight: 600;
  margin-bottom: 24px;
}

.title-small {
  font-size: 18px;
  font-weight: 600;
  line-height: 140%;
}

.block__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text {
  font-size: 18px;
  max-width: 750px;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

/* exo-2-300 - latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/exo-2-v19-latin-300.woff2") format("woff2"), url("../fonts/exo-2-v19-latin-300.woff") format("woff");
}
/* exo-2-regular - latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/exo-2-v19-latin-regular.woff2") format("woff2"), url("../fonts/exo-2-v19-latin-regular.woff") format("woff");
}
/* exo-2-500 - latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/exo-2-v19-latin-500.woff2") format("woff2"), url("../fonts/exo-2-v19-latin-500.woff") format("woff");
}
/* exo-2-600 - latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/exo-2-v19-latin-600.woff2") format("woff2"), url("../fonts/exo-2-v19-latin-600.woff") format("woff");
}
/* exo-2-700 - latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/exo-2-v19-latin-700.woff2") format("woff2"), url("../fonts/exo-2-v19-latin-700.woff") format("woff");
}
/* exo-2-300italic - latin */
@font-face {
  font-family: "Exo 2";
  font-style: italic;
  font-weight: 300;
  src: local(""), url("../fonts/exo-2-v19-latin-300italic.woff2") format("woff2"), url("../fonts/exo-2-v19-latin-300italic.woff") format("woff");
}
/* exo-2-italic - latin */
@font-face {
  font-family: "Exo 2";
  font-style: italic;
  font-weight: 400;
  src: local(""), url("../fonts/exo-2-v19-latin-italic.woff2") format("woff2"), url("../fonts/exo-2-v19-latin-italic.woff") format("woff");
}
.page-wrapper {
  padding: 40px;
}

.availability {
  margin-bottom: 24px;
}
.availability:last-child {
  margin-bottom: 0;
}
.availability__title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.availability__main {
  padding: 6px 0;
}

.icons-block {
  display: flex;
  gap: 24px;
}
.icons-block svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.resources-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.resources-block__text {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
}

.list-links {
  display: flex;
  flex-direction: column;
}

.list-link {
  cursor: pointer;
  color: #ee702a;
  font-weight: 500;
  margin-bottom: 16px;
}
.list-link:last-child {
  margin-bottom: 0;
}
.list-link:hover {
  opacity: 0.6;
  color: #ee702a;
}

.review-block {
  display: flex;
  flex-direction: column;
}
.review-block__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
.review-block__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
}
.review-block__subtitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}
.review-block__key {
  font-size: 18px;
  font-weight: 600;
}
.review-block__value {
  font-size: 18px;
  color: #858a8f;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.review-block__total {
  margin-top: auto;
}
.review-block__wrap {
  max-width: 504px;
  height: 77px;
  border-radius: 10px;
  padding: 0 24px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}
.review-block__price {
  font-weight: 500;
  font-size: 20px;
  text-align: end;
}

.box {
  border: 1px solid #c0c7cd;
  border-radius: 10px;
}

.box-header {
  padding: 16px 24px;
  border-bottom: 1px solid #c0c7cd;
}

.box-main {
  padding: 24px;
}
.box-main__content {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.badge {
  width: 100%;
  height: 60px;
  padding: 10px 16px 8px;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 16px;
  border-radius: 4px;
  background-color: #ee702a26;
  color: #ee702a;
  cursor: pointer;
}
.badge__content {
  display: flex;
  align-items: baseline;
  gap: 4px;
}
.badge input {
  height: 42px;
}

.error-badge {
  width: 100%;
  height: 60px;
  padding: 10px 16px 8px;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 16px;
  border-radius: 4px;
  background-color: #f4bfba;
  color: #676362;
  cursor: pointer;
}
.error-badge__content {
  display: flex;
  align-items: baseline;
  gap: 4px;
}
.error-badge input {
  height: 42px;
}

.accordion__btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  font-size: 24px;
  font-weight: 600;
  padding: 12px 0;
  font-size: 18px;
  color: #434343;
  cursor: pointer;
  transition: background-color 0.2s linear;
}

.accordion__icon {
  width: 20px;
  height: 20px;
}

.accordion__btn.is-open .accordion__icon {
  transform: rotate(180deg);
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.small-text {
  font-size: 18px;
  font-style: italic;
}

.file {
  display: flex;
  align-items: baseline;
  gap: 24px;
}

.file-label {
  cursor: pointer;
}

#file-upload,
#file-upload2 {
  display: none;
}

.manage-actions {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}
@media (max-width: 1399.98px) {
  .manage-actions {
    flex-wrap: wrap;
  }
  .manage-actions .progressbar-block {
    width: 100%;
  }
  .manage-actions .input-wrapper {
    flex-grow: 1;
  }
}

.delete {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.action {
  border: 1px solid #c0c7cd;
  border-radius: 4px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.action__text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.metadata-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}

.metadata-subtitle {
  font-size: 20px;
  margin-bottom: 16px;
}

.metadata-text {
  font-size: 16px;
  color: #858a8f;
  font-weight: 500;
}

.more-icon svg {
  width: 10px;
  height: 17px;
}
.more-icon:hover svg {
  fill: #ee702a;
}

.action-icon {
  width: 20px;
  height: 20px;
}

.info-block {
  padding-bottom: 24px;
}
.info-block__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
.info-block__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 24px;
}
.info-block__subtitle {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}
.info-block__row {
  margin-bottom: 8px;
}
.info-block__key {
  font-size: 18px;
  font-weight: 500;
}
.info-block__value {
  font-size: 18px;
  color: #434343;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input-file {
  background-color: #f4f4f4;
  padding: 26px 16px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.input-file .file-label {
  color: #ee702a;
  text-decoration: underline;
}
.input-file .file-label:hover {
  opacity: 0.9;
}

.graphic-block {
  padding-bottom: 32px;
  margin-bottom: 32px;
}
.graphic-block__title {
  font-size: 22px;
  line-height: 120%;
  margin-bottom: 32px;
}
.graphic-block__subtitle {
  font-size: 18px;
  font-weight: 500;
  color: #adb4ba;
  margin-bottom: 16px;
}

.chart {
  max-width: 280px;
  max-height: 280px;
}

.volume-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.volume-buttons button {
  margin-right: 24px;
}
.volume-buttons button:last-child {
  margin-right: 0;
}
@media (max-width: 1399.98px) {
  .volume-buttons {
    flex-wrap: wrap;
    gap: 24px;
  }
  .volume-buttons button {
    margin: 0;
  }
}

.checkbox-wrapper {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  color: #434343;
  font-size: 16px;
  font-weight: 300;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 20px;
  min-height: 20px;
}

.checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid #c0c7cd;
}

.checkbox-wrapper:hover input ~ .checkmark {
  border-color: #ee702a;
}

.checkbox-wrapper input:checked ~ .checkmark {
  background-color: #ee702a;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-wrapper .checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Drop down */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  cursor: pointer;
  font-size: 16px;
  border: none;
  outline: none;
  color: #fff;
  padding: 14px 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 8px;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content a,
.dropdown-content button {
  width: 100%;
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 12px;
}
.dropdown-content a:hover,
.dropdown-content button:hover {
  border-radius: 4px;
  background-color: #13a05511;
}
.dropdown-content a svg,
.dropdown-content button svg {
  width: 20px;
  height: 20px;
}

.show {
  display: block;
}

/* Select */
.bv_mainselect {
  position: relative;
  color: #fff;
  border-radius: 4px;
  font-size: 16px;
}

.bv_atual {
  color: #434343;
  font-size: 16px;
  padding: 12px 16px;
  border: 1px solid #c0c7cd;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.bv_ul_inner {
  position: absolute;
  display: none;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  padding: 8px;
  z-index: 1;
}

.bv_ul_inner li {
  cursor: pointer;
  color: black;
  padding: 8px 16px;
  background-color: #fff;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.bv_ul_inner li:hover {
  background-color: #13a05511;
}

.bv_disabled {
  background-color: #e6e6e6 !important;
  cursor: default !important;
}

.checkbox-switch {
  display: inline-block;
  height: 29px;
  line-height: 29px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}
.checkbox-switch__switcher {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 29px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 25%/50%;
  background: #eee;
  transition: 0.2s;
}
.checkbox-switch__switcher--dark {
  background: #434343;
}
.checkbox-switch__switcher::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  transition: 0.2s;
}
.checkbox-switch__switcher-blocked {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 29px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 25%/50%;
  background: #464646;
  transition: 0.2s;
  cursor: not-allowed;
}
.checkbox-switch__switcher-blocked--dark {
  background: #434343;
}
.checkbox-switch__switcher-blocked::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  transition: 0.2s;
}
.checkbox-switch .switch-input {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox-switch .switch-input:checked + .checkbox-switch__switcher {
  background: #ee702a;
}
.checkbox-switch .switch-input:checked + .checkbox-switch__switcher-blocked {
  background: #464646;
}
.checkbox-switch .switch-input:checked + .checkbox-switch__switcher:before {
  transform: translateX(28px);
}

.header {
  background-color: #222222;
  padding: 8px 0;
}

.header-nav__item .dropbtn {
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  color: #ebeef1;
  padding: 14px 0;
}

.block {
  background-color: #fff;
  border-radius: 8px;
  padding: 32px;
}
@media (max-width: 1399.98px) {
  .block {
    padding: 16px;
  }
}

.solution-block {
  min-height: 356px;
  background-color: #434343;
  color: #fff;
  border-radius: 8px;
  padding: 24px 24px 68px;
  position: relative;
}
.solution-block:hover {
  background-color: #222;
}
.solution-block__header {
  min-height: 56px;
  display: flex;
  padding-right: 80px;
  margin-bottom: 36px;
}
.solution-block__title {
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  text-transform: uppercase;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.solution-block__icon {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 56px;
  height: 56px;
}
.solution-block__icon svg {
  width: 100%;
  height: 100%;
}
.solution-block__text {
  font-size: 18px;
  font-weight: 300;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.7);
}
.solution-block__link {
  position: absolute;
  left: 24px;
  bottom: 24px;
  color: #fff;
}
.solution-block__link svg {
  fill: #fff;
}
.solution-block__link:hover {
  color: #fff;
}

.solution-block__disabled {
  color: #434343;
  background-color: #f4f4f4;
  user-select: none;
}
.solution-block__disabled .solution-block__text {
  color: #434343;
}
.solution-block__disabled:hover {
  color: #434343;
  background-color: #f4f4f4;
}

.sub-block {
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
  flex-grow: 1;
}
@media (max-width: 1399.98px) {
  .sub-block {
    padding: 12px;
  }
}
.sub-block__title {
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.sub-block__text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 12px;
}
.sub-block__link {
  font-weight: 600;
  text-transform: uppercase;
}
.sub-block__link:hover {
  color: #ee702a;
}
.sub-block__link:hover svg {
  fill: #ee702a;
}

.table {
  border-radius: 10px;
  margin-bottom: 48px;
}

.table-footer {
  background-color: #858a8f;
  color: #fff;
  font-weight: 500;
  height: 42px;
  display: flex;
  align-items: center;
  padding: 0 24px;
}

.table-header__row {
  display: flex;
  align-items: center;
  background-color: #858a8f;
  color: #fff;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  padding: 0 24px;
  border-radius: 10px 10px 0 0;
}
.table-header__element {
  width: 20%;
  display: flex;
  align-items: center;
  gap: 4px;
  padding-right: 16px;
  line-height: 120%;
}

.table-main__row {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  position: relative;
}
.table-main__row:nth-child(even) {
  background-color: #f9fafb;
}
.table-main__element {
  width: 20%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
}

.table-footer {
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 120px;
}

.pagination {
  display: flex;
  align-items: center;
}
.pagination__block {
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: #fff;
  color: #434343;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}
.pagination__block:hover {
  background-color: #ebeef1;
}
.pagination__block-prev {
  border-radius: 4px 0 0 4px;
  border-right: 1px solid #858a8f;
}
.pagination__block-next {
  border-radius: 0 4px 4px 0;
  border-left: 1px solid #858a8f;
}
.pagination .range {
  user-select: none;
  pointer-events: none;
}
.pagination .active-block {
  color: #ee702a;
  user-select: none;
  pointer-events: none;
}

.row-stopped {
  background-color: #f9fafb;
}

.row-active {
  background-color: #fff;
}

.small-cell {
  width: 14.2857142857%;
}

.smallest-cell {
  width: 8%;
}

.quarter-cell {
  width: 25%;
}

.big-cell {
  width: 33.3333333333%;
}

.half-cell {
  width: 50%;
}

.bigSmall-cell {
  width: 12.5%;
}

.table-icon {
  width: 24px;
  height: 24px;
}

.table-icon-end {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  cursor: pointer;
}

.table-header-icon {
  cursor: pointer;
}

@media (max-width: 1399.98px) {
  .table--images-tab .last {
    width: 10%;
  }
}
.table--images-tab button {
  white-space: nowrap;
}

.create-volume-from-image {
  right: 74px;
}

.table--dasboard table {
  width: 100%;
}
.table--dasboard table thead tr td {
  background-color: #858a8f;
  color: #fff;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
}
.table--dasboard table thead tr td:first-child {
  padding-left: 24px;
  border-radius: 10px 0 0 0;
}
.table--dasboard table thead tr td:last-child {
  border-radius: 0 10px 0 0;
}
.table--dasboard table td {
  width: 100px;
  margin-right: 50px;
}
.table--dasboard table td:first-child {
  width: 140px;
  padding-left: 24px;
}
.table--dasboard table td:nth-child(3) {
  min-width: 150px;
}
.table--dasboard table td:nth-child(6) {
  min-width: 130px;
}
.table--dasboard table td:nth-child(7) {
  width: 60px;
}
.table--dasboard table td:nth-child(8) {
  width: 85px;
  margin-right: 10px;
}
.table--dasboard table td:nth-child(9) {
  width: 123px;
}
.table--dasboard table td:last-child {
  margin-right: 0;
  padding-right: 24px;
}
.table--dasboard table tbody td {
  font-size: 16px;
  line-height: 160%;
  color: #434343;
}
.table--dasboard table tbody td:last-child {
  min-width: 210px;
}
@media (max-width: 1680px) {
  .table--dasboard table tbody td:last-child {
    min-width: 90px;
  }
}
.table--dasboard__head {
  background-color: #858a8f;
  color: #fff;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  padding: 0 24px;
  border-radius: 10px 10px 0 0;
}
.table--dasboard__head td {
  font-size: 16px;
}
.table--dasboard .flavor {
  color: #ee702a;
}
.table--dasboard .status {
  color: #5ab810;
}
.table--dasboard .dropdown-content__wrap {
  display: flex;
}
.table--dasboard .dropdown-content__vertical-divader {
  height: 504px;
  border: 1px solid #eeeeee;
  margin: 0 25px;
}
@media (max-width: 1680px) {
  .table--dasboard .btn-start-instance {
    display: none;
  }
}
.table--dasboard #dashboardMain .btn-start-instance {
  display: none;
}
@media (max-width: 1680px) {
  .table--dasboard #dashboardMain .btn-start-instance {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .table--dasboard #dashboardMain .btn-start-instance button {
    display: flex;
    justify-content: center;
  }
}

.js-tabs__header {
  display: flex;
  gap: 36px;
  margin-bottom: 30px;
  padding-bottom: 6px;
}
.js-tabs__title {
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
  padding-bottom: 6px;
  color: #000;
}
.js-tabs__title-active {
  color: #ee702a;
  cursor: default;
  border-bottom: 1px solid #ee702a;
}
.js-tabs__title-active:hover {
  color: #ee702a;
}

.tabs-nav {
  display: flex;
  gap: 8px;
}
.tabs-nav__item {
  font-size: 16px;
  padding: 12px 16px;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
}
.tabs-nav__item.is-active {
  cursor: default;
  color: #ee702a;
  background: rgba(238, 112, 42, 0.0823529412);
}

.tabs__content {
  padding-top: 48px;
}

.tab {
  display: none;
}

.tab.is-active {
  display: block;
}

.tabs-nav-2 {
  display: flex;
  gap: 24px;
}
.tabs-nav-2 .tabs-nav__item {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  text-decoration: none;
  cursor: pointer;
  padding-bottom: 6px;
}
.tabs-nav-2 .tabs-nav__item.is-active {
  cursor: default;
  color: #ee702a;
  border-bottom: 1px solid #ee702a;
}

.attach-Interface .tabs-nav__item {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin-top: 36px;
  margin-right: 24px;
  padding-bottom: 8px;
}
.attach-Interface .tabs-nav__item.is-active {
  border-radius: 0;
  background: none;
  border-bottom: 1px solid #ee702a;
}
.attach-Interface .tabs {
  border-bottom: 1px solid #c0c7cd;
}

.modal {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 994;
  animation: fade 0.5s;
  padding: 40px 0;
}
.modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 995;
}
.modal__wrapper {
  padding: 12px 6px;
  border-radius: 12px;
  background-color: #fff;
  max-height: 100%;
  position: relative;
  margin: auto;
  z-index: 996;
}

.modal-content {
  background-color: #fff;
  padding: 24px 30px;
  max-height: calc(100% - 24px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #f2f2f8 #96a0bd;
}
.modal-content::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.modal-content::-webkit-scrollbar-track {
  background: #f2f2f8;
  border-radius: 3px;
}
.modal-content::-webkit-scrollbar-thumb {
  background-color: #96a0bd;
  border-radius: 3px;
}
.modal-content--w284 {
  width: 284px;
}
.modal-content--w475 {
  width: 475px;
}
.modal-content--w585 {
  width: 585px;
}
.modal-content--w659 {
  width: 659px;
}
.modal-content--w786 {
  width: 786px;
}
.modal-content--w892 {
  width: 892px;
}
.modal-content--w1056 {
  max-width: 1056px;
}
.modal-content--w1260 {
  width: 1260px;
}
.modal-content--w1400 {
  width: 1400px;
}
.modal-content--w786-w1200 {
  min-width: 786px;
  max-width: 1200px;
}
.modal-content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
  gap: 36px;
}
.modal-content__header svg {
  cursor: pointer;
}
.modal-content__title {
  font-size: 28px;
  font-weight: 600;
  line-height: 130%;
}
.modal-content__text {
  font-size: 18px;
  font-weight: 400;
}
.modal-content__btns {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}
.modal-content__btn {
  width: 211px;
}

.modal-name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.show-modal {
  display: flex;
  animation: fade 0.5s;
}

.Security-Groups .input-wrap {
  height: 239px;
  padding: 24px;
  background-color: #f9fafb;
}
.Security-Groups .modal-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  color: #434343;
}
.Security-Groups .badge-wrap {
  margin-top: 24px;
}
.Security-Groups .badge-wrap .badge {
  height: 38px;
  margin-bottom: 16px;
  justify-content: space-between;
}

.update-metadata__wrap {
  display: flex;
}
.update-metadata__content {
  padding: 16px 24px;
  border: 1px solid #c0c7cd;
  border-radius: 10px;
}
.update-metadata__content:first-child {
  margin-right: 24px;
}
.update-metadata__search {
  display: flex;
  align-items: center;
}
.update-metadata__title {
  font-weight: 600;
  font-size: 24px;
  margin-right: 24px;
  line-height: 120%;
  color: #434343;
}
.update-metadata .input-wrapper input {
  width: 190px;
}

#vmsDashboard-create-snapshot .progressbar__progress {
  background-color: #1cadff;
}
#vmsDashboard-create-snapshot .progressbar__potentail {
  background-color: #c983f3;
}

.windows10__main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.windows10__main .active {
  color: #ee702a;
  text-decoration: underline;
}
.windows10__column {
  margin-right: 50px;
}
.windows10__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.windows10__content-col {
  display: grid;
  grid-template-columns: 1fr;
}
.windows10__sideBlock-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.windows10__sideBlock-wrap div {
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 16px;
}
.windows10__block {
  margin-bottom: 16px;
}
.windows10__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  color: #434343;
  margin-bottom: 6px;
}
.windows10 .windows10-Interfaces .active {
  color: #13a054;
}
.windows10 .windows10-log .bv_atual {
  margin-right: 20px;
}
.windows10 .btn-go {
  padding: 15px 20px;
  min-width: 0;
}
.windows10__info-console {
  padding: 12px 36px;
  background: rgba(238, 112, 42, 0.15);
  margin-bottom: 36px;
}
.windows10__info-console p {
  margin: 0;
  font-size: 16px;
  line-height: 160%;
  color: #434343;
}
.windows10__info-console p span {
  color: #ee702a;
}

.import-public__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.import-public__content input,
.import-public__content .select-wrapper {
  max-width: 300px;
}
.import-public__content .import-public__text {
  max-width: 356px;
  height: 258px;
}
.import-public__content .import-public__text textarea {
  width: 100%;
  height: 100%;
}
.import-public__content .choose-file {
  max-width: 300px;
  padding: 12px 16px;
  text-align: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C0C7CDFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
.import-public__content .choose-file .file-label {
  color: #ee702a;
  text-decoration: underline;
}
.import-public__content .choose-file span {
  color: #858a8f;
}
.import-public__content .import-public__info-bytes {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.import-public__content .import-public__info-bytes span {
  font-size: 18px;
  line-height: 160%;
  color: #434343;
}
.import-public__content .import-public__info-bytes span:last-child {
  font-style: italic;
}

.dev-web__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.dev-web__data {
  display: flex;
  flex-direction: column;
}
.dev-web__data span {
  margin-bottom: 6px;
}
.dev-web__data .active {
  color: #ee702a;
}

.small-modal .modal-content {
  padding: 12px 16px 0;
}
.small-modal__title {
  width: 100%;
}
.small-modal__title p {
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #434343;
}
.small-modal__wrap {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.small-modal__wrap div {
  display: grid;
  grid-template-columns: 1fr;
}
.small-modal .small-modal__col span {
  margin-bottom: 2px;
  border-bottom: 1px solid #ebeef1;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  color: #434343;
}
.small-modal .small-modal__col:last-child span {
  font-weight: 300;
}
.small-modal .modal__wrapper {
  border-radius: 4px;
}

.create-pair .input-label,
.attach-Interface .input-label {
  gap: 2px;
}

.link-back {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  font-size: 36px;
  line-height: 150%;
  font-weight: 600;
  margin-bottom: 24px;
}
.link-back :hover {
  opacity: 0.8;
}

.steps {
  border: 1px solid #c0c7cd;
  background-color: #fff;
  display: flex;
  height: 48px;
  margin-bottom: 48px;
}

.step {
  width: 16.6666666667%;
  text-align: center;
  padding: 10px 22px 10px 32px;
  cursor: pointer;
  position: relative;
}
.step__text {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.step__arrow {
  position: absolute;
  z-index: 10;
  right: -17px;
  top: 6px;
  width: 33px;
  height: 34px;
  border-top: 1px solid #c0c7cd;
  border-right: 1px solid #c0c7cd;
  transform: rotate(45deg);
  background-color: #fff;
}

.step-active {
  background-color: #ee702a;
  color: #fff;
  height: 48px;
  transform: translateY(-1px);
}
.step-active .step__arrow {
  top: 7px;
  background-color: #ee702a;
  border-top: 2px solid #ee702a;
  border-right: 2px solid #ee702a;
}

.tab-buttons {
  display: flex;
  margin-bottom: 24px;
}

.tab-button {
  padding: 12px 16px;
}

.tab-button-active {
  color: #ee702a;
  background: #ee702a15;
}

.link-next a {
  width: 180px;
}
.link-next a:hover {
  color: #fff;
}

.filter-btn {
  padding: 12px 77px;
}

.tooltip {
  position: relative;
}
.tooltip svg {
  vertical-align: text-top;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #4a4a4a;
  color: #fff;
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 120%;
  width: max-content;
  max-width: 200px;
  position: absolute;
  left: 50%;
  bottom: 140%;
  transform: translateX(-50%);
  z-index: 1000;
}
.tooltip .tooltiptext--right {
  position: absolute;
  left: auto;
  left: 140%;
  bottom: 50%;
  transform: translateY(50%);
  z-index: 1000;
}

.progress-wrapper {
  background-color: #f9fafb;
  padding: 16px;
  border-radius: 4px;
}

.progressbars {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 16px;
}

.progressbar-block {
  border: 1px solid #c0c7cd;
  border-radius: 4px;
  padding: 16px;
  min-width: 270px;
}
.progressbar-block__text {
  margin-bottom: 8px;
}
.progressbar-block__value {
  margin: 0 0.3em;
}

.progressbar {
  height: 16px;
  width: 100%;
  background-color: #ebeef1;
  position: relative;
}
.progressbar__progress, .progressbar__potentail {
  display: inline-block;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
}
.progressbar__progress-ok {
  background-color: #5ab810;
  z-index: 20;
}
.progressbar__progress-warn {
  background-color: #f8cf40;
  z-index: 20;
}
.progressbar__progress-important {
  background-color: #de6464;
  z-index: 20;
}
.progressbar__potentail-ok {
  background-color: #ceeab8;
  z-index: 19;
}
.progressbar__potentail-warn {
  background-color: #f6ebc1;
  z-index: 19;
}
.progressbar__potentail-important {
  background-color: #f5dedd;
  z-index: 19;
}

.rule {
  margin-bottom: 24px;
}
.rule__title {
  font-weight: 500;
  margin-bottom: 4px;
}
.rule__text {
  font-size: 14px;
}

.to-pay-info {
  display: flex;
  justify-content: flex-end;
}
.to-pay-info__wrap {
  padding: 8px 16px;
  background: rgba(238, 112, 42, 0.15);
  border: 1px solid #ee702a;
  border-radius: 34px;
  box-sizing: border-box;
}
.to-pay-info__title {
  font-weight: 600;
  font-size: 16px;
  color: #222222;
  margin-right: 12px;
}
.to-pay-info__price {
  font-size: 16px;
  color: #434343;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.bordered {
  border: 1px solid #c0c7cd;
}

.overflow-visible {
  overflow: visible;
}

.w20h20 {
  width: 20px;
  height: 20px;
}

.gray-text {
  color: #858a8f;
}

.text-orange {
  color: #ee702a;
}

.text-light {
  font-weight: 300;
}

.status-active {
  font-weight: 400;
  color: #5ab810;
}

.status-stopped {
  font-weight: 400;
  color: #de6464;
}

.big-number {
  color: #5ab810;
  font-size: 32px;
  font-weight: 500;
}

.border-none {
  border: none;
}

.input-group {
  margin-bottom: 48px;
}

.input-label {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
}
.input-label svg {
  cursor: pointer;
}

.input {
  border: 1px solid #c0c7cd;
  border-radius: 4px;
  color: #434343;
  text-align: left;
  height: 41px;
  width: 100%;
  padding: 5px 50px 5px 5px;
  text-overflow: ellipsis;
  top: -1px;
  position: relative;
}
.input:focus {
  border: 1px solid #858a8f;
  border-color: #ee702a;
}

.disabled-input {
  pointer-events: none;
  background: #f4f4f4;
  border: none;
  color: #858a8f;
}

.disabled-select {
  position: relative;
  pointer-events: none;
}
.disabled-select::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: 4px;
  background: #e2e2e2;
  opacity: 0.5;
  border: none;
  z-index: 100;
}

.input-wrapper {
  position: relative;
}
.input-wrapper input[readonly] {
  color: #8d9ca7;
}
.input-wrapper .search-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.input-wrapper .right-bordered-icon {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
  border-left: 1px solid #ccc;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.input-wrapper .add-metadata-valid {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ee702a;
  cursor: pointer;
}
.input-wrapper .add-metadata-invalid {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0c7af;
  cursor: not-allowed;
}

.input-name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.textarea {
  width: 100%;
  resize: none;
  padding: 12px 16px;
  border: 1px solid #c0c7cd;
  border-radius: 4px;
  color: #434343;
}

.box-input {
  padding: 12px 16px;
  border: 1px solid #c0c7cd;
  border-radius: 4px;
  color: #434343;
  width: 110px;
}

.radios {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 48px;
}

.radio {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 16px;
  padding-right: 48px;
  border: 1px solid #c0c7cd;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-checkmark {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  border: 1px solid #c0c7cd;
  border-radius: 50%;
}

.radio:hover input ~ .radio-checkmark {
  border-color: #ee702a;
}

.radio input:checked .radio {
  border-color: #ee702a;
}

.radio input:checked ~ .radio-checkmark {
  background-color: #ee702a;
  border: none;
}

.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio input:checked ~ .radio-checkmark:after {
  display: block;
}

.radio .radio-checkmark:after {
  left: 7px;
  top: 4px;
  width: 6px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.arrow {
  border: solid #c0c7cd;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  float: right;
  pointer-events: none;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-top: 10px;
  transition: all 0.2s ease;
}

.down {
  transition: all 0.2s ease;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: 6px;
}

.date-input {
  padding: 12px;
  padding-left: 52px;
  text-align: right;
}

.date-wrapper {
  position: relative;
}

.date-iput-text {
  font-size: 16px;
  font-weight: 300;
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
}

.code-box {
  position: relative;
}
.code-box__icon {
  position: absolute;
  left: 24px;
  top: 24px;
  cursor: pointer;
}
.code-box__row {
  display: flex;
}
.code-box__number {
  padding-right: 10px;
  margin-right: 8px;
  border-right: 1px solid #c0c7cd;
  min-width: 50px;
  text-align: right;
}

.default-spinner-wrap {
  display: grid;
  place-items: center;
  margin: 0.5em 0;
}

.column-status-green {
  text-transform: capitalize;
  color: #0ebe74;
}

.column-status-orange {
  text-transform: capitalize;
  color: #ffd43c;
}

.column-status-red {
  text-transform: capitalize;
  color: #e2456b;
}

.column-status-black {
  text-transform: capitalize;
  color: #222222;
}

html {
  --scrollbarBG: rgb(34 34 34);
  --thumbBG: #ee6e2ac0;
}

body::-webkit-scrollbar {
  width: 11px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Exo 2;
  letter-spacing: 0em;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Exo 2;
  letter-spacing: 0.0075em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Exo 2;
  letter-spacing: 0.0094em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 500 15px / 24px Exo 2;
  letter-spacing: 0.0067em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Exo 2;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Exo 2;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Exo 2;
  letter-spacing: 0.0179em;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Exo 2;
  letter-spacing: 0.0179em;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Exo 2;
  letter-spacing: 0.0333em;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Exo 2;
  letter-spacing: -0.0134em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Exo 2;
  letter-spacing: -0.0089em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Exo 2;
  letter-spacing: 0em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Exo 2;
  letter-spacing: 0.0074em;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Exo 2;
  letter-spacing: 0.0179em;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Exo 2;
  font-size: 14px;
  font-weight: 500;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Exo 2;
  letter-spacing: 0.0075em;
}

.mat-expansion-panel-header {
  font-family: Exo 2;
  font-size: 15px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Exo 2;
  letter-spacing: 0.0179em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Exo 2;
  letter-spacing: 1.5px;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Exo 2;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Exo 2;
  font-size: 12px;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slider-thumb-label-text {
  font-size: 12px;
  font-weight: 500;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-label, .mat-tab-link {
  font-family: Exo 2;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Exo 2;
  letter-spacing: 0.0075em;
  margin: 0;
}

.mat-tooltip {
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Exo 2;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Exo 2;
  letter-spacing: 0.0179em;
}

.mat-simple-snackbar {
  font-family: Exo 2;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

body {
  --primary-color: #ee702a;
  --primary-lighter-color: #fad4bf;
  --primary-darker-color: #e75319;
  --text-primary-color: white;
  --text-primary-lighter-color: white;
  --text-primary-darker-color: #000000;
}

body {
  --accent-color: #ee702a;
  --accent-lighter-color: #fad4bf;
  --accent-darker-color: #e75319;
  --text-accent-color: white;
  --text-accent-lighter-color: white;
  --text-accent-darker-color: #000000;
}

body {
  --warn-color: #ff554c;
  --warn-lighter-color: #ffccc9;
  --warn-darker-color: #ff3b33;
  --text-warn-color: white;
  --text-warn-lighter-color: white;
  --text-warn-darker-color: #000000;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ee702a;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ee702a;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff554c;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #ee702a;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ee702a;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff554c;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #ee702a;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #ee702a;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff554c;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #ee702a;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #ee702a;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #ff554c;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #ee702a;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ee702a;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff554c;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #ee702a;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #ee702a;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #ff554c;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #ee702a;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ee702a;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff554c;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #ee702a;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ee702a;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff554c;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #ee702a;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff554c;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ee702a;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(238, 112, 42, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(238, 112, 42, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(238, 112, 42, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #ee702a;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(238, 112, 42, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(238, 112, 42, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(238, 112, 42, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(238, 112, 42, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(238, 112, 42, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(238, 112, 42, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ee702a;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(238, 112, 42, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(238, 112, 42, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(238, 112, 42, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 85, 76, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 85, 76, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 85, 76, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff554c;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 85, 76, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 85, 76, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 85, 76, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #ee702a;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #ee702a;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #ff554c;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #ee702a;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ee702a;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff554c;
}

.mat-focused .mat-form-field-required-marker {
  color: #ee702a;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #ee702a;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ee702a;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff554c;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #ee702a;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ee702a;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff554c;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff554c;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff554c;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff554c;
}

.mat-error {
  color: #ff554c;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #ee702a;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ee702a;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff554c;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff554c;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #ee702a;
}
.mat-icon.mat-accent {
  color: #ee702a;
}
.mat-icon.mat-warn {
  color: #ff554c;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #ee702a;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #ee702a;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #ff554c;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff554c;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #f7d8c6;
}

.mat-progress-bar-buffer {
  background-color: #f7d8c6;
}

.mat-progress-bar-fill::after {
  background-color: #ee702a;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #f7d8c6;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #f7d8c6;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ee702a;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fbd1cf;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fbd1cf;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff554c;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #ee702a;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ee702a;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #ff554c;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ee702a;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #ee702a;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ee702a;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ee702a;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff554c;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff554c;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #ee702a;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ee702a;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff554c;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff554c;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ee702a;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(238, 112, 42, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ee702a;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #ee702a;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(238, 112, 42, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #ee702a;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff554c;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 85, 76, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff554c;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #ee702a;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(238, 112, 42, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ee702a;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(238, 112, 42, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff554c;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 85, 76, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #ee702a;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ee702a;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff554c;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff554c;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff554c;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(250, 212, 191, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ee702a;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(250, 212, 191, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ee702a;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 201, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff554c;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(250, 212, 191, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #ee702a;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(250, 212, 191, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ee702a;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 201, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff554c;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #ee702a;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #ee702a;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #ff554c;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #ee702a;
}

.theme-alternate .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-option {
  color: white;
}
.theme-alternate .mat-option:hover:not(.mat-option-disabled), .theme-alternate .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.theme-alternate .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.theme-alternate .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.theme-alternate .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ee702a;
}
.theme-alternate .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ee702a;
}
.theme-alternate .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff554c;
}
.theme-alternate .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-pseudo-checkbox::after {
  color: #303030;
}
.theme-alternate .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-alternate .mat-primary .mat-pseudo-checkbox-checked,
.theme-alternate .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #ee702a;
}
.theme-alternate .mat-pseudo-checkbox-checked,
.theme-alternate .mat-pseudo-checkbox-indeterminate,
.theme-alternate .mat-accent .mat-pseudo-checkbox-checked,
.theme-alternate .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ee702a;
}
.theme-alternate .mat-warn .mat-pseudo-checkbox-checked,
.theme-alternate .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff554c;
}
.theme-alternate .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-alternate .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.theme-alternate .mat-app-background, .theme-alternate.mat-app-background {
  background-color: #303030;
  color: white;
}
.theme-alternate .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-alternate .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.theme-alternate .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.theme-alternate .mat-badge-content {
  color: white;
  background: #ee702a;
}
.cdk-high-contrast-active .theme-alternate .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-alternate .mat-badge-accent .mat-badge-content {
  background: #ee702a;
  color: white;
}
.theme-alternate .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff554c;
}
.theme-alternate .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.theme-alternate .mat-button, .theme-alternate .mat-icon-button, .theme-alternate .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.theme-alternate .mat-button.mat-primary, .theme-alternate .mat-icon-button.mat-primary, .theme-alternate .mat-stroked-button.mat-primary {
  color: #ee702a;
}
.theme-alternate .mat-button.mat-accent, .theme-alternate .mat-icon-button.mat-accent, .theme-alternate .mat-stroked-button.mat-accent {
  color: #ee702a;
}
.theme-alternate .mat-button.mat-warn, .theme-alternate .mat-icon-button.mat-warn, .theme-alternate .mat-stroked-button.mat-warn {
  color: #ff554c;
}
.theme-alternate .mat-button.mat-primary.mat-button-disabled, .theme-alternate .mat-button.mat-accent.mat-button-disabled, .theme-alternate .mat-button.mat-warn.mat-button-disabled, .theme-alternate .mat-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-icon-button.mat-primary.mat-button-disabled, .theme-alternate .mat-icon-button.mat-accent.mat-button-disabled, .theme-alternate .mat-icon-button.mat-warn.mat-button-disabled, .theme-alternate .mat-icon-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-stroked-button.mat-primary.mat-button-disabled, .theme-alternate .mat-stroked-button.mat-accent.mat-button-disabled, .theme-alternate .mat-stroked-button.mat-warn.mat-button-disabled, .theme-alternate .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #ee702a;
}
.theme-alternate .mat-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ee702a;
}
.theme-alternate .mat-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff554c;
}
.theme-alternate .mat-button.mat-button-disabled .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.theme-alternate .mat-button .mat-ripple-element, .theme-alternate .mat-icon-button .mat-ripple-element, .theme-alternate .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.theme-alternate .mat-button-focus-overlay {
  background: white;
}
.theme-alternate .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-flat-button, .theme-alternate .mat-raised-button, .theme-alternate .mat-fab, .theme-alternate .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  color: white;
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  color: white;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  color: white;
}
.theme-alternate .mat-flat-button.mat-primary.mat-button-disabled, .theme-alternate .mat-flat-button.mat-accent.mat-button-disabled, .theme-alternate .mat-flat-button.mat-warn.mat-button-disabled, .theme-alternate .mat-flat-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-raised-button.mat-primary.mat-button-disabled, .theme-alternate .mat-raised-button.mat-accent.mat-button-disabled, .theme-alternate .mat-raised-button.mat-warn.mat-button-disabled, .theme-alternate .mat-raised-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-fab.mat-primary.mat-button-disabled, .theme-alternate .mat-fab.mat-accent.mat-button-disabled, .theme-alternate .mat-fab.mat-warn.mat-button-disabled, .theme-alternate .mat-fab.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-primary.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-accent.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-warn.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  background-color: #ee702a;
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  background-color: #ee702a;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  background-color: #ff554c;
}
.theme-alternate .mat-flat-button.mat-primary.mat-button-disabled, .theme-alternate .mat-flat-button.mat-accent.mat-button-disabled, .theme-alternate .mat-flat-button.mat-warn.mat-button-disabled, .theme-alternate .mat-flat-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-raised-button.mat-primary.mat-button-disabled, .theme-alternate .mat-raised-button.mat-accent.mat-button-disabled, .theme-alternate .mat-raised-button.mat-warn.mat-button-disabled, .theme-alternate .mat-raised-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-fab.mat-primary.mat-button-disabled, .theme-alternate .mat-fab.mat-accent.mat-button-disabled, .theme-alternate .mat-fab.mat-warn.mat-button-disabled, .theme-alternate .mat-fab.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-primary.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-accent.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-warn.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-flat-button.mat-primary .mat-ripple-element, .theme-alternate .mat-raised-button.mat-primary .mat-ripple-element, .theme-alternate .mat-fab.mat-primary .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-accent .mat-ripple-element, .theme-alternate .mat-raised-button.mat-accent .mat-ripple-element, .theme-alternate .mat-fab.mat-accent .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-warn .mat-ripple-element, .theme-alternate .mat-raised-button.mat-warn .mat-ripple-element, .theme-alternate .mat-fab.mat-warn .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-stroked-button:not([class*=mat-elevation-z]), .theme-alternate .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-alternate .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-alternate .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-alternate .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.theme-alternate .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.theme-alternate .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.theme-alternate [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.theme-alternate .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.theme-alternate .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-alternate .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-alternate .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.theme-alternate .mat-card {
  background: #424242;
  color: white;
}
.theme-alternate .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox-checkmark {
  fill: #303030;
}
.theme-alternate .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.theme-alternate .mat-checkbox-mixedmark {
  background-color: #303030;
}
.theme-alternate .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #ee702a;
}
.theme-alternate .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ee702a;
}
.theme-alternate .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff554c;
}
.theme-alternate .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .theme-alternate .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.theme-alternate .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.theme-alternate .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #ee702a;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ee702a;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff554c;
}
.theme-alternate .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip::after {
  background: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #ee702a;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff554c;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ee702a;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-table {
  background: #424242;
}
.theme-alternate .mat-table thead, .theme-alternate .mat-table tbody, .theme-alternate .mat-table tfoot,
.theme-alternate mat-header-row, .theme-alternate mat-row, .theme-alternate mat-footer-row,
.theme-alternate [mat-header-row], .theme-alternate [mat-row], .theme-alternate [mat-footer-row],
.theme-alternate .mat-table-sticky {
  background: inherit;
}
.theme-alternate mat-row, .theme-alternate mat-header-row, .theme-alternate mat-footer-row,
.theme-alternate th.mat-header-cell, .theme-alternate td.mat-cell, .theme-alternate td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-cell, .theme-alternate .mat-footer-cell {
  color: white;
}
.theme-alternate .mat-calendar-arrow {
  fill: white;
}
.theme-alternate .mat-datepicker-toggle,
.theme-alternate .mat-datepicker-content .mat-calendar-next-button,
.theme-alternate .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.theme-alternate .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-calendar-table-header,
.theme-alternate .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-calendar-body-cell-content,
.theme-alternate .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.theme-alternate .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-calendar-body-in-range::before {
  background: rgba(238, 112, 42, 0.2);
}
.theme-alternate .mat-calendar-body-comparison-identical,
.theme-alternate .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-alternate .mat-calendar-body-comparison-bridge-start::before,
.theme-alternate [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(238, 112, 42, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-calendar-body-comparison-bridge-end::before,
.theme-alternate [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(238, 112, 42, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-alternate .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-alternate .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-alternate .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-alternate .mat-calendar-body-selected {
  background-color: #ee702a;
  color: white;
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(238, 112, 42, 0.4);
}
.theme-alternate .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-alternate .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(238, 112, 42, 0.3);
}
@media (hover: hover) {
  .theme-alternate .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(238, 112, 42, 0.3);
  }
}
.theme-alternate .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(238, 112, 42, 0.2);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-alternate .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(238, 112, 42, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-alternate .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(238, 112, 42, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ee702a;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(238, 112, 42, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-alternate .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(238, 112, 42, 0.3);
}
@media (hover: hover) {
  .theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(238, 112, 42, 0.3);
  }
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 85, 76, 0.2);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-alternate .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 85, 76, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-alternate .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 85, 76, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff554c;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 85, 76, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-alternate .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 85, 76, 0.3);
}
@media (hover: hover) {
  .theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 85, 76, 0.3);
  }
}
.theme-alternate .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-datepicker-toggle-active {
  color: #ee702a;
}
.theme-alternate .mat-datepicker-toggle-active.mat-accent {
  color: #ee702a;
}
.theme-alternate .mat-datepicker-toggle-active.mat-warn {
  color: #ff554c;
}
.theme-alternate .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.theme-alternate .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-expansion-panel {
  background: #424242;
  color: white;
}
.theme-alternate .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .theme-alternate .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.theme-alternate .mat-expansion-panel-header-title {
  color: white;
}
.theme-alternate .mat-expansion-panel-header-description,
.theme-alternate .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-alternate .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label {
  color: #ee702a;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ee702a;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff554c;
}
.theme-alternate .mat-focused .mat-form-field-required-marker {
  color: #ee702a;
}
.theme-alternate .mat-form-field-ripple {
  background-color: white;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #ee702a;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ee702a;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff554c;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #ee702a;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ee702a;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff554c;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff554c;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff554c;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff554c;
}
.theme-alternate .mat-error {
  color: #ff554c;
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #ee702a;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ee702a;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff554c;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff554c;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.theme-alternate .mat-icon.mat-primary {
  color: #ee702a;
}
.theme-alternate .mat-icon.mat-accent {
  color: #ee702a;
}
.theme-alternate .mat-icon.mat-warn {
  color: #ff554c;
}
.theme-alternate .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-input-element:disabled,
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element {
  caret-color: #ee702a;
}
.theme-alternate .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element:not(.mat-native-select-inline) option {
  color: white;
}
.theme-alternate .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(255, 255, 255, 0.38);
}
.theme-alternate .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ee702a;
}
.theme-alternate .mat-form-field.mat-warn .mat-input-element,
.theme-alternate .mat-form-field-invalid .mat-input-element {
  caret-color: #ff554c;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff554c;
}
.theme-alternate .mat-list-base .mat-list-item {
  color: white;
}
.theme-alternate .mat-list-base .mat-list-option {
  color: white;
}
.theme-alternate .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-list-option:hover, .theme-alternate .mat-list-option:focus,
.theme-alternate .mat-nav-list .mat-list-item:hover,
.theme-alternate .mat-nav-list .mat-list-item:focus,
.theme-alternate .mat-action-list .mat-list-item:hover,
.theme-alternate .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.theme-alternate .mat-list-single-selected-option, .theme-alternate .mat-list-single-selected-option:hover, .theme-alternate .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-menu-panel {
  background: #424242;
}
.theme-alternate .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-menu-item {
  background: transparent;
  color: white;
}
.theme-alternate .mat-menu-item[disabled],
.theme-alternate .mat-menu-item[disabled] .mat-menu-submenu-icon,
.theme-alternate .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-menu-item .mat-icon-no-color,
.theme-alternate .mat-menu-submenu-icon {
  color: white;
}
.theme-alternate .mat-menu-item:hover:not([disabled]),
.theme-alternate .mat-menu-item.cdk-program-focused:not([disabled]),
.theme-alternate .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-alternate .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.theme-alternate .mat-paginator {
  background: #424242;
}
.theme-alternate .mat-paginator,
.theme-alternate .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-paginator-decrement,
.theme-alternate .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.theme-alternate .mat-paginator-first,
.theme-alternate .mat-paginator-last {
  border-top: 2px solid white;
}
.theme-alternate .mat-icon-button[disabled] .mat-paginator-decrement,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-increment,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-first,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-progress-bar-background {
  fill: #60402f;
}
.theme-alternate .mat-progress-bar-buffer {
  background-color: #60402f;
}
.theme-alternate .mat-progress-bar-fill::after {
  background-color: #ee702a;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #60402f;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #60402f;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ee702a;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #643937;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #643937;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff554c;
}
.theme-alternate .mat-progress-spinner circle, .theme-alternate .mat-spinner circle {
  stroke: #ee702a;
}
.theme-alternate .mat-progress-spinner.mat-accent circle, .theme-alternate .mat-spinner.mat-accent circle {
  stroke: #ee702a;
}
.theme-alternate .mat-progress-spinner.mat-warn circle, .theme-alternate .mat-spinner.mat-warn circle {
  stroke: #ff554c;
}
.theme-alternate .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ee702a;
}
.theme-alternate .mat-radio-button.mat-primary .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #ee702a;
}
.theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ee702a;
}
.theme-alternate .mat-radio-button.mat-accent .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ee702a;
}
.theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff554c;
}
.theme-alternate .mat-radio-button.mat-warn .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff554c;
}
.theme-alternate .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.theme-alternate .mat-select-value {
  color: white;
}
.theme-alternate .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-select-panel {
  background: #424242;
}
.theme-alternate .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #ee702a;
}
.theme-alternate .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ee702a;
}
.theme-alternate .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff554c;
}
.theme-alternate .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff554c;
}
.theme-alternate .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.theme-alternate .mat-drawer {
  background-color: #424242;
  color: white;
}
.theme-alternate .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.theme-alternate .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ee702a;
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(238, 112, 42, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ee702a;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #ee702a;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(238, 112, 42, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #ee702a;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff554c;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 85, 76, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff554c;
}
.theme-alternate .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.theme-alternate .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.theme-alternate .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider.mat-primary .mat-slider-track-fill,
.theme-alternate .mat-slider.mat-primary .mat-slider-thumb,
.theme-alternate .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #ee702a;
}
.theme-alternate .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(238, 112, 42, 0.2);
}
.theme-alternate .mat-slider.mat-accent .mat-slider-track-fill,
.theme-alternate .mat-slider.mat-accent .mat-slider-thumb,
.theme-alternate .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ee702a;
}
.theme-alternate .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(238, 112, 42, 0.2);
}
.theme-alternate .mat-slider.mat-warn .mat-slider-track-fill,
.theme-alternate .mat-slider.mat-warn .mat-slider-thumb,
.theme-alternate .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff554c;
}
.theme-alternate .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 85, 76, 0.2);
}
.theme-alternate .mat-slider:hover .mat-slider-track-background,
.theme-alternate .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider.mat-slider-disabled .mat-slider-track-background,
.theme-alternate .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.theme-alternate .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.theme-alternate .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.theme-alternate .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.theme-alternate .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.theme-alternate .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .theme-alternate .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .theme-alternate .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-step-header.cdk-keyboard-focused, .theme-alternate .mat-step-header.cdk-program-focused, .theme-alternate .mat-step-header:hover:not([aria-disabled]), .theme-alternate .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-alternate .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-alternate .mat-step-header:hover {
    background: none;
  }
}
.theme-alternate .mat-step-header .mat-step-label,
.theme-alternate .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.theme-alternate .mat-step-header .mat-step-icon-selected,
.theme-alternate .mat-step-header .mat-step-icon-state-done,
.theme-alternate .mat-step-header .mat-step-icon-state-edit {
  background-color: #ee702a;
  color: white;
}
.theme-alternate .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-alternate .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-alternate .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-alternate .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ee702a;
  color: white;
}
.theme-alternate .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-alternate .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-alternate .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-alternate .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff554c;
  color: white;
}
.theme-alternate .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff554c;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff554c;
}
.theme-alternate .mat-stepper-horizontal, .theme-alternate .mat-stepper-vertical {
  background-color: #424242;
}
.theme-alternate .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-horizontal-stepper-header::before,
.theme-alternate .mat-horizontal-stepper-header::after,
.theme-alternate .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-sort-header-arrow {
  color: #c6c6c6;
}
.theme-alternate .mat-tab-nav-bar,
.theme-alternate .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group-inverted-header .mat-tab-nav-bar,
.theme-alternate .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.theme-alternate .mat-tab-label, .theme-alternate .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.theme-alternate .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(250, 212, 191, 0.3);
}
.theme-alternate .mat-tab-group.mat-primary .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ee702a;
}
.theme-alternate .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(250, 212, 191, 0.3);
}
.theme-alternate .mat-tab-group.mat-accent .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ee702a;
}
.theme-alternate .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 201, 0.3);
}
.theme-alternate .mat-tab-group.mat-warn .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff554c;
}
.theme-alternate .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(250, 212, 191, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container, .theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #ee702a;
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(250, 212, 191, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container, .theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ee702a;
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 201, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container, .theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff554c;
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.theme-alternate .mat-toolbar {
  background: #212121;
  color: white;
}
.theme-alternate .mat-toolbar.mat-primary {
  background: #ee702a;
  color: white;
}
.theme-alternate .mat-toolbar.mat-accent {
  background: #ee702a;
  color: white;
}
.theme-alternate .mat-toolbar.mat-warn {
  background: #ff554c;
  color: white;
}
.theme-alternate .mat-toolbar .mat-form-field-underline,
.theme-alternate .mat-toolbar .mat-form-field-ripple,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-alternate .mat-toolbar .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-select-value,
.theme-alternate .mat-toolbar .mat-select-arrow,
.theme-alternate .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-alternate .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-alternate .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.theme-alternate .mat-tree {
  background: #424242;
}
.theme-alternate .mat-tree-node,
.theme-alternate .mat-nested-tree-node {
  color: white;
}
.theme-alternate .mat-snack-bar-container {
  color: white;
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-simple-snackbar-action {
  color: inherit;
}

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 20px;
  font-family: "Material Icons", "Material Icons";
}
.material-icons .mat-badge-content {
  font-family: "Exo 2";
}

::ng-deep .mat-form-field-appearance-outline.mat-form-field-has-label .mat-form-field-infix {
  padding: 0.7em 0 0.7em 0;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.4em 0 0.8em 0;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.5em;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-label.mat-form-field-empty {
  top: 23px;
}

::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
  margin-top: 2px;
}

::ng-deep .mat-form-field.mat-form-field-type-mat-select.mat-form-field-has-label .mat-form-field-infix {
  padding: 0.6em 0 0.8em 0;
}
::ng-deep .mat-form-field.mat-form-field-type-mat-select.mat-form-field-has-label .mat-form-field-infix .mat-form-field-label-wrapper {
  top: -14px;
  padding: 0;
}

::ng-deep .mat-form-field.mat-form-field-type-mat-select.mat-form-field-has-label.mat-focused .mat-form-field-label {
  transform: translateY(-1.8em) scale(0.8);
}

::ng-deep .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.4em) scale(0.8);
}

::ng-deep .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-form-field.mat-focused .mat-form-field-outline-gap {
  border-top-color: currentColor;
}

::ng-deep .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-form-field .mat-form-field-outline-gap {
  border-top-color: currentColor;
}

::ng-deep .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-form-field.mat-focused.mat-form-field-type-mat-select .mat-form-field-outline-gap {
  border-top-color: transparent;
}

::ng-deep .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-form-field.mat-form-field-type-mat-select .mat-form-field-outline-gap {
  border-top-color: transparent;
}

::ng-deep .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-form-field.mat-focused.mat-form-field-has-label .mat-form-field-outline-gap {
  border-top-color: transparent;
}

::ng-deep .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-form-field.mat-form-field-has-label .mat-form-field-outline-gap {
  border-top-color: transparent;
}

::ng-deep .mat-form-field.mat-form-field-appearance-outline > .mat-form-field-wrapper > .mat-form-field-flex > .mat-form-field-outline > .mat-form-field-outline-start,
::ng-deep .mat-form-field.mat-form-field-appearance-outline > .mat-form-field-wrapper > .mat-form-field-flex > .mat-form-field-outline .mat-form-field-outline-end,
::ng-deep .mat-form-field.mat-form-field-appearance-outline > .mat-form-field-wrapper > .mat-form-field-flex > .mat-form-field-outline .mat-form-field-outline-gap {
  border-width: 1px;
}
::ng-deep .mat-form-field.mat-form-field-appearance-outline > .mat-form-field-wrapper > .mat-form-field-flex > .mat-form-field-outline:not(.mat-form-field-disabled) > .mat-form-field-outline-start,
::ng-deep .mat-form-field.mat-form-field-appearance-outline > .mat-form-field-wrapper > .mat-form-field-flex > .mat-form-field-outline:not(.mat-form-field-disabled) .mat-form-field-outline-end,
::ng-deep .mat-form-field.mat-form-field-appearance-outline > .mat-form-field-wrapper > .mat-form-field-flex > .mat-form-field-outline:not(.mat-form-field-disabled) .mat-form-field-outline-gap {
  border-width: 1px;
}
::ng-deep .mat-form-field.mat-form-field-appearance-outline > .mat-form-field-wrapper > .mat-form-field-flex:hover > .mat-form-field-outline:not(.mat-form-field-disabled) > .mat-form-field-outline-start,
::ng-deep .mat-form-field.mat-form-field-appearance-outline > .mat-form-field-wrapper > .mat-form-field-flex:hover > .mat-form-field-outline:not(.mat-form-field-disabled) .mat-form-field-outline-end,
::ng-deep .mat-form-field.mat-form-field-appearance-outline > .mat-form-field-wrapper > .mat-form-field-flex:hover > .mat-form-field-outline:not(.mat-form-field-disabled) .mat-form-field-outline-gap {
  border-width: 1px;
}

:host ::ng-deep .mat-select-arrow {
  border-left: 15px solid transparent !important;
  border-right: none !important;
  border-top: 15px solid transparent !important;
  border-image-source: "" !important;
  border-image-repeat: stretch !important;
}