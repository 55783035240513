@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.bordered {
  border: 1px solid $line-color;
}
.overflow-visible {
  overflow: visible;
}
.w20h20 {
  width: 20px;
  height: 20px;
}
.gray-text {
  color: $dark-gray;
}
.text-orange {
  color: $orange;
}
.text-light {
  font-weight: 300;
}
.status-active {
  font-weight: 400;
  color: $green;
}
.status-stopped {
  font-weight: 400;
  color: #de6464;
}
.big-number {
  color: $green;
  font-size: 32px;
  font-weight: 500;
}
.border-none {
  border: none;
}

.input-group {
  margin-bottom: 48px;
}

.input-label {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;

  svg {
    cursor: pointer;
  }
}
.input {
  border: 1px solid #c0c7cd;
  border-radius: 4px;
  color: #434343;
  text-align: left;
  height: 41px;
  width: 100%;
  padding: 5px 50px 5px 5px;
  text-overflow: ellipsis;
  top: -1px;
  position: relative;

  &:focus {
    border: 1px solid #858a8f;
    border-color: #ee702a;
  }
}
.disabled-input {
  pointer-events: none;
  background: #f4f4f4;
  border: none;
  color: #858a8f;
}
.disabled-select {
  position: relative;
  pointer-events: none;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: 4px;
    background: #e2e2e2;
    opacity: 0.5;
    border: none;
    z-index: 100;
  }
}
.input-wrapper {
  position: relative;

  input[readonly] {
    color: #8d9ca7;
  }

  .search-icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  .right-bordered-icon {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    border-left: 1px solid #ccc;
    border-radius: 0 4px 4px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  @mixin add-meta-common {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    border-radius: 0 4px 4px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add-metadata-valid {
    @include add-meta-common();
    background-color: $orange;
    cursor: pointer;
  }
  .add-metadata-invalid {
    @include add-meta-common();
    background-color: #f0c7af;
    cursor: not-allowed;
  }
}
.input-name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
.textarea {
  width: 100%;
  resize: none;
  padding: 12px 16px;
  border: 1px solid $line-color;
  border-radius: 4px;
  color: $text-color;
}
.box-input {
  padding: 12px 16px;
  border: 1px solid #c0c7cd;
  border-radius: 4px;
  color: #434343;
  width: 110px;
}

.radios {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 48px;
}
.radio {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 16px;
  padding-right: 48px;
  border: 1px solid $line-color;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radio-checkmark {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  border: 1px solid $line-color;
  border-radius: 50%;
}
.radio:hover input ~ .radio-checkmark {
  border-color: $orange;
}
.radio input:checked .radio {
  border-color: $orange;
}
.radio input:checked ~ .radio-checkmark {
  background-color: $orange;
  border: none;
}
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.radio input:checked ~ .radio-checkmark:after {
  display: block;
}
.radio .radio-checkmark:after {
  left: 7px;
  top: 4px;
  width: 6px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.arrow {
  border: solid $line-color;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  float: right;
  pointer-events: none;
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-top: 10px;
  transition: all 0.2s ease;
}
.down {
  transition: all 0.2s ease;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: 6px;
}

.date-input {
  padding: 12px;
  padding-left: 52px;
  text-align: right;
}
.date-wrapper {
  position: relative;
}
.date-iput-text {
  font-size: 16px;
  font-weight: 300;
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
}

.code-box {
  position: relative;

  &__icon {
    position: absolute;
    left: 24px;
    top: 24px;
    cursor: pointer;
  }

  &__row {
    display: flex;
  }
  &__number {
    padding-right: 10px;
    margin-right: 8px;
    border-right: 1px solid $line-color;
    min-width: 50px;
    text-align: right;
  }
}

.default-spinner-wrap {
  display: grid;
  place-items: center;
  margin: 0.5em 0;
}

.column-status-green {
  text-transform: capitalize;
  color: #0ebe74;
}

.column-status-orange {
  text-transform: capitalize;
  color: #ffd43c;
}

.column-status-red {
  text-transform: capitalize;
  color: #e2456b;
}

.column-status-black {
  text-transform: capitalize;
  color: #222222;
}
