/* exo-2-300 - latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/exo-2-v19-latin-300.woff2") format("woff2"),
    url("../fonts/exo-2-v19-latin-300.woff") format("woff");
}
/* exo-2-regular - latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/exo-2-v19-latin-regular.woff2") format("woff2"),
    url("../fonts/exo-2-v19-latin-regular.woff") format("woff");
}
/* exo-2-500 - latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/exo-2-v19-latin-500.woff2") format("woff2"),
    url("../fonts/exo-2-v19-latin-500.woff") format("woff");
}
/* exo-2-600 - latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/exo-2-v19-latin-600.woff2") format("woff2"),
    url("../fonts/exo-2-v19-latin-600.woff") format("woff");
}
/* exo-2-700 - latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/exo-2-v19-latin-700.woff2") format("woff2"),
    url("../fonts/exo-2-v19-latin-700.woff") format("woff");
}
/* exo-2-300italic - latin */
@font-face {
  font-family: "Exo 2";
  font-style: italic;
  font-weight: 300;
  src: local(""),
    url("../fonts/exo-2-v19-latin-300italic.woff2") format("woff2"),
    url("../fonts/exo-2-v19-latin-300italic.woff") format("woff");
}
/* exo-2-italic - latin */
@font-face {
  font-family: "Exo 2";
  font-style: italic;
  font-weight: 400;
  src: local(""), url("../fonts/exo-2-v19-latin-italic.woff2") format("woff2"),
    url("../fonts/exo-2-v19-latin-italic.woff") format("woff");
}
