.modal {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 994;
  animation: fade 0.5s;
  padding: 40px 0;
  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 995;
  }
  &__wrapper {
    padding: 12px 6px;
    border-radius: 12px;
    background-color: #fff;
    max-height: 100%;
    position: relative;
    margin: auto;
    z-index: 996;
  }
}

.modal-content {
  background-color: #fff;
  padding: 24px 30px;
  max-height: calc(100% - 24px);
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #f2f2f8 #96a0bd;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f8;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #96a0bd;
    border-radius: 3px;
  }

  &--w284 {
    width: 284px;
  }
  &--w475 {
    width: 475px;
  }

  &--w585 {
    width: 585px;
  }

  &--w659 {
    width: 659px;
  }

  &--w786 {
    width: 786px;
  }

  &--w892 {
    width: 892px;
  }

  &--w1056 {
    max-width: 1056px;
  }

  &--w1260 {
    width: 1260px;
  }
  &--w1400 {
    width: 1400px;
  }
  &--w786-w1200 {
    min-width: 786px;
    max-width: 1200px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;
    gap: 36px;

    svg {
      cursor: pointer;
    }
  }

  &__title {
    font-size: 28px;
    font-weight: 600;
    line-height: 130%;
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
  }

  &__btns {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  }

  &__btn {
    width: 211px;
  }
}

.modal-name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.show-modal {
  display: flex;
  animation: fade 0.5s;
}

.Security-Groups {
  .input-wrap {
    height: 239px;
    padding: 24px;
    background-color: #f9fafb;
  }

  .modal-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    color: #434343;
  }

  .badge-wrap {
    margin-top: 24px;

    .badge {
      height: 38px;
      margin-bottom: 16px;
      justify-content: space-between;
    }
  }
}

.update-metadata {
  &__wrap {
    display: flex;
  }

  &__content {
    padding: 16px 24px;
    // max-width: 480px;
    border: 1px solid #c0c7cd;
    border-radius: 10px;

    &:first-child {
      margin-right: 24px;
    }
  }

  &__search {
    display: flex;
    align-items: center;
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    margin-right: 24px;
    line-height: 120%;
    color: #434343;
  }
  .input-wrapper {
    input {
      width: 190px;
    }
  }
}

#vmsDashboard-create-snapshot {
  .progressbar__progress {
    background-color: #1cadff;
  }

  .progressbar__potentail {
    background-color: #c983f3;
  }
}

.windows10 {
  &__main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .active {
      color: #ee702a;
      text-decoration: underline;
    }
  }

  &__column {
    margin-right: 50px;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__content-col {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__sideBlock {
    &-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      div {
        display: grid;
        grid-template-columns: 1fr;
        padding-left: 16px;
      }
    }
  }

  &__block {
    margin-bottom: 16px;
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    color: #434343;
    margin-bottom: 6px;
  }

  .windows10-Interfaces {
    .active {
      color: #13a054;
    }
  }

  .windows10-log {
    .bv_atual {
      margin-right: 20px;
    }
  }

  .btn-go {
    padding: 15px 20px;
    min-width: 0;
  }

  &__info-console {
    padding: 12px 36px;
    background: rgba(238, 112, 42, 0.15);
    margin-bottom: 36px;

    p {
      margin: 0;
      font-size: 16px;
      line-height: 160%;
      color: #434343;
      span {
        color: #ee702a;
      }
    }
  }
}

//Key Pair
.import-public__content {
  display: grid;
  grid-template-columns: 1fr 1fr;

  input,
  .select-wrapper {
    max-width: 300px;
  }

  .import-public__text {
    max-width: 356px;
    height: 258px;

    textarea {
      width: 100%;
      height: 100%;
    }
  }

  .choose-file {
    max-width: 300px;
    padding: 12px 16px;
    text-align: center;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C0C7CDFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

    .file-label {
      color: #ee702a;
      text-decoration: underline;
    }

    span {
      color: #858a8f;
    }
  }

  // .modal-name {
  //   margin-bottom: 14px;
  // }

  .import-public__info-bytes {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    span {
      font-size: 18px;
      line-height: 160%;
      color: #434343;

      &:last-child {
        font-style: italic;
      }
    }
  }
}

.dev-web {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__data {
    display: flex;
    flex-direction: column;

    span {
      margin-bottom: 6px;
    }

    .active {
      color: #ee702a;
    }
  }
}

.small-modal {
  .modal-content {
    padding: 12px 16px 0;
  }

  &__title {
    width: 100%;
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 120%;
      color: #434343;
    }
  }

  &__wrap {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    div {
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  .small-modal__col {
    span {
      margin-bottom: 2px;
      border-bottom: 1px solid #ebeef1;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      color: #434343;
    }

    &:last-child {
      span {
        font-weight: 300;
      }
    }
  }

  .modal__wrapper {
    border-radius: 4px;
  }
}

.create-pair,
.attach-Interface {
  .input-label {
    gap: 2px;
  }
}
