.progress-wrapper {
  background-color: #f9fafb;
  padding: 16px;
  border-radius: 4px;
}

.progressbars {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 16px;
}

.progressbar-block {
  border: 1px solid $line-color;
  border-radius: 4px;
  padding: 16px;
  min-width: 270px;

  &__text {
    margin-bottom: 8px;
  }

  &__value {
    margin: 0 0.3em;
  }
}

.progressbar {
  height: 16px;
  width: 100%;
  background-color: #ebeef1;
  position: relative;

  &__progress,
  &__potentail {
    display: inline-block;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 4px;
  }

  &__progress-ok {
    background-color: $green;
    z-index: 20;
  }

  &__progress-warn {
    background-color: #f8cf40;
    z-index: 20;
  }

  &__progress-important {
    background-color: #de6464;
    z-index: 20;
  }

  &__potentail-ok {
    background-color: #ceeab8;
    z-index: 19;
  }

  &__potentail-warn {
    background-color: #f6ebc1;
    z-index: 19;
  }

  &__potentail-important {
    background-color: #f5dedd;
    z-index: 19;
  }
}
