.page-wrapper {
  padding: 40px;
}

.availability {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
  &__title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    text-transform: uppercase;
  }
  &__main {
    padding: 6px 0;
  }
}
