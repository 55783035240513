.solution-block {
  min-height: 356px;
  background-color: $text-color;
  color: #fff;
  border-radius: 8px;
  padding: 24px 24px 68px;
  position: relative;

  &:hover {
    background-color: #222;
  }

  &__header {
    min-height: 56px;
    display: flex;
    padding-right: 80px;
    margin-bottom: 36px;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__icon {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 56px;
    height: 56px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 300;
    line-height: 150%;
    color: rgba(256, 256, 256, 0.7);
  }

  &__link {
    position: absolute;
    left: 24px;
    bottom: 24px;
    color: #fff;

    svg {
      fill: #fff;
    }

    &:hover {
      color: #fff;
      // color: $orange;

      // svg {
      //   fill: $orange;
      // }
    }
  }
}

.solution-block__disabled {
  color: $text-color;
  background-color: #f4f4f4;
  user-select: none;

  .solution-block__text {
    color: $text-color;
  }

  &:hover {
    color: $text-color;
    background-color: #f4f4f4;
  }
}
