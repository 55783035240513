@import "~bootstrap/scss/_functions.scss";

// ------------ONLY FIRST VARIABLE FOR CORRECT WORKS---------

$spacer: 10px;
$spacers: (
  0: 0,
  4: (
    $spacer * 0.4,
  ),
  8: (
    $spacer * 0.8,
  ),
  10: $spacer,
  12: (
    $spacer * 1.2,
  ),
  15: (
    $spacer * 1.5,
  ),
  16: (
    $spacer * 1.6,
  ),
  20: (
    $spacer * 2,
  ),
  24: (
    $spacer * 2.4,
  ),
  30: (
    $spacer * 3,
  ),
  32: (
    $spacer * 3.2,
  ),
  36: (
    $spacer * 3.6,
  ),
  40: (
    $spacer * 4,
  ),
  48: (
    $spacer * 4.8,
  ),
  64: (
    $spacer * 6.4,
  ),
  68: (
    $spacer * 6.8,
  ),
  120: (
    $spacer * 12,
  ),
);

$negative-spacers: negativify-map($spacers);

$light-gray: #f9fafb;
$orange: #ee702a;
$text-color: #434343;
$line-color: #c0c7cd;
$green: #5ab810;
$dark-gray: #858a8f;

$border-color: #dde4ed;

$theme-colors: (
  //"primary":    $primary,
  //"secondary":  $secondary,
  //"success":    $success,
  //"info":       $info,
  //"warning":    $warning,
  //"danger":     $danger,
  //"light":      $light,
  //"dark":       $dark
);
//-------------MAIN MODULE ---------

@import "~bootstrap/scss/variables";

// ------------ENABLED---------

$enable-caret: false;
$enable-rounded: false;
//$enable-rfs: false;
$enable-negative-margins: false;
$enable-validation-icons: false;

// ------------GRID------------

$grid-columns: 12;
$grid-gutter-width: 30px;
$container-padding-x: 40px;
$gutters: (
  0: 0,
  4: 4px,
  8: 8px,
  12: 12px,
  20: 20px,
  24: 24px,
  32: 32px,
  40: 40px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px,
  xxl: 1600px,
);

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 100%,
);

// ------------VARIABLES-------

//colors

$colors: (
  //"blue":       $blue,
  //"indigo":     $indigo,
  //"purple":     $purple,
  //"pink":       $pink,
  //"red":        $red,
  //"orange":     $orange,
  //"yellow":     $yellow,
  //"green":      $green,
  //"teal":       $teal,
  //"cyan":       $cyan,
  //"white":      $white,
  //"gray":       $gray-600,
  //"gray-dark":  $gray-800
);

//global
$body-bg: $white;
$body-color: $dark;
$line-height-sm: 1.2;
$line-height-lg: 1.5;

//fonts
// $font-family-base: -apple-system,'Golos',sans-serif;
$font-family-base: "Exo 2", sans-serif;

//text
$text-muted: $gray-600;

//link
$link-color: $body-color;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;
$emphasized-link-hover-darken-percentage: 15%;

//transition
$transition-base: 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$utilities: map-merge(
  $utilities,
  (
    "shadow": null,
    "translate-middle": null,
    "line-height": null,
    "gradient": null,
    "rounded": null,
    "rounded-top": null,
    "rounded-end": null,
    "rounded-bottom": null,
    "rounded-start": null,
    "visibility": null,
  )
);
//modules
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/utilities/api";
