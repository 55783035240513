.link-back {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  font-size: 36px;
  line-height: 150%;
  font-weight: 600;
  margin-bottom: 24px;

  :hover {
    opacity: 0.8;
  }
}

.steps {
  border: 1px solid $line-color;
  background-color: #fff;
  display: flex;
  height: 48px;
  margin-bottom: 48px;
}
.step {
  width: calc(100% / 6);
  text-align: center;
  padding: 10px 22px 10px 32px;
  cursor: pointer;

  position: relative;

  &__text {
    display: inline-block;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__arrow {
    position: absolute;
    z-index: 10;
    right: -17px;
    top: 6px;
    width: 33px;
    height: 34px;
    border-top: 1px solid $line-color;
    border-right: 1px solid $line-color;
    transform: rotate(45deg);
    background-color: #fff;
  }
}
.step-active {
  background-color: #ee702a;
  color: #fff;
  height: 48px;
  transform: translateY(-1px);

  .step__arrow {
    top: 7px;
    background-color: $orange;
    border-top: 2px solid $orange;
    border-right: 2px solid $orange;
  }
}

.tab-buttons {
  display: flex;
  margin-bottom: 24px;
}
.tab-button {
  padding: 12px 16px;
}
.tab-button-active {
  color: $orange;
  background: #ee702a15;
}

.link-next {
  a {
    width: 180px;
    &:hover {
      color: #fff;
    }
  }
}

.filter-btn {
  padding: 12px 77px;
}
