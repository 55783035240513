:root {
  --snt-blue: #0000ff;
}

html {
  font-size: 16px;
}

body {
  display: flex;
  flex-direction: column;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  min-height: 100vh;
  min-width: 320px;
  font-family: $font-family-base;
  color: $text-color;
  background-color: #f4f4f4;

  .footer {
    margin-top: auto;
  }
}

.btn {
  background-color: $orange;
  padding: 12px 16px;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  white-space: nowrap;
  min-width: 100px;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    transition: $transition-base;
    opacity: 0.8;
  }
}

.btn-2 {
  @extend .btn;
  background-color: #ee702a26;
  color: $orange;
}
.btn-3 {
  @extend .btn;
  color: #000;
  background-color: #fff;
  border: 1px solid #d2dde5;
}
.btn-4 {
  @extend .btn;
  color: fff;
  background-color: #434343;
}

.btn-disabled {
  @extend .btn;
  color: #fff;
  background-color: #b8bdc3;
  user-select: none;

  &:hover {
    opacity: 1;
    cursor: default;
  }
}

.color-gray {
  color: #adb4ba;
  &:hover {
    color: #adb4ba;
  }
}
