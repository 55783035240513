.table {
  border-radius: 10px;
  margin-bottom: 48px;
}

.table-footer {
  background-color: $dark-gray;
  color: #fff;
  font-weight: 500;
  height: 42px;
  display: flex;
  align-items: center;
  padding: 0 24px;
}

.table-header {
  &__row {
    display: flex;
    align-items: center;
    background-color: $dark-gray;
    color: #fff;
    height: 50px;
    font-size: 18px;
    font-weight: 600;
    padding: 0 24px;
    border-radius: 10px 10px 0 0;
  }

  &__element {
    width: 20%;
    display: flex;
    align-items: center;
    gap: 4px;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    padding-right: 16px;
    line-height: 120%;
  }
}

.table-main {
  &__row {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    position: relative;

    &:nth-child(even) {
      background-color: #f9fafb;
    }
  }

  &__element {
    width: 20%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
  }
}
.table-footer {
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 120px;
}
.pagination {
  display: flex;
  align-items: center;

  &__block {
    width: 32px;
    height: 32px;
    padding: 4px;
    background-color: #fff;
    color: $text-color;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;

    &:hover {
      background-color: #ebeef1;
    }
  }
  &__block-prev {
    border-radius: 4px 0 0 4px;
    border-right: 1px solid #858a8f;
  }
  &__block-next {
    border-radius: 0 4px 4px 0;
    border-left: 1px solid #858a8f;
  }
  .range {
    user-select: none;
    pointer-events: none;
  }

  .active-block {
    color: $orange;
    user-select: none;
    pointer-events: none;
  }
}

.row-stopped {
  background-color: #f9fafb;
}

.row-active {
  background-color: #fff;
}
.small-cell {
  width: calc(100% / 7);
}
.smallest-cell {
  width: 8%;
}
.quarter-cell {
  width: calc(100% / 4);
}
.big-cell {
  width: calc(100% / 3);
}
.half-cell {
  width: calc(100% / 2);
}
.bigSmall-cell {
  width: calc(100% / 8);
}

.table-icon {
  width: 24px;
  height: 24px;
  // transform: translateY(-3px);
}

.table-icon-end {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  cursor: pointer;
}
.table-header-icon {
  cursor: pointer;
}

.table--images-tab {
  .last {
    @include media-breakpoint-down(xl) {
      width: 10%;
    }
  }
  button {
    white-space: nowrap;
  }
}
.create-volume-from-image {
  right: 74px;
}

.table--dasboard {
  table {
    width: 100%;
    thead {
      tr {
        td {
          background-color: $dark-gray;
          color: #fff;
          height: 50px;
          font-size: 18px;
          font-weight: 600;
          &:first-child {
            padding-left: 24px;
            border-radius: 10px 0 0 0;
          }
          &:last-child {
            border-radius: 0 10px 0 0;
          }
        }
      }
    }
    td {
      width: 100px;
      margin-right: 50px;
      &:first-child {
        width: 140px;
        padding-left: 24px;
      }
      &:nth-child(3) {
        min-width: 150px;
      }
      &:nth-child(6) {
        min-width: 130px;
      }
      &:nth-child(7) {
        width: 60px;
      }
      &:nth-child(8) {
        width: 85px;
        margin-right: 10px;
      }
      &:nth-child(9) {
        width: 123px;
      }
      &:last-child {
        margin-right: 0;
        padding-right: 24px;
      }
    }
    tbody {
      td {
        font-size: 16px;
        line-height: 160%;
        color: #434343;
        &:last-child {
          min-width: 210px;

          @media (max-width: 1680px) {
            min-width: 90px;
          }
        }
      }
    }
  }

  &__head {
    background-color: $dark-gray;
    color: #fff;
    height: 50px;
    font-size: 18px;
    font-weight: 600;
    padding: 0 24px;
    border-radius: 10px 10px 0 0;

    td {
      font-size: 16px;
    }
  }

  .flavor {
    color: $orange;
  }

  .status {
    color: $green;
  }

  .dropdown-content {
    &__wrap {
      display: flex;
    }

    &__vertical-divader {
      height: 504px;
      border: 1px solid #eeeeee;
      margin: 0 25px;
    }
  }

  @media (max-width: 1680px) {
    .btn-start-instance {
      display: none;
    }
  }

  #dashboardMain {
    .btn-start-instance {
      display: none;

      @media (max-width: 1680px) {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
        button {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
