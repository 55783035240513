/* Drop down */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  cursor: pointer;
  font-size: 16px;
  border: none;
  outline: none;
  color: #fff;
  padding: 14px 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 8px;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  a,
  button {
    width: 100%;
    color: black;
    padding: 8px 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 12px;

    &:hover {
      border-radius: 4px;
      background-color: #13a05511;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.show {
  display: block;
}
