.rule {
  margin-bottom: 24px;
  &__title {
    font-weight: 500;
    margin-bottom: 4px;
  }
  &__text {
    font-size: 14px;
  }
}
