/* Select */

.bv_mainselect {
  position: relative;
  color: #fff;
  border-radius: 4px;
  font-size: 16px;
}
.bv_atual {
  color: $text-color;
  font-size: 16px;
  padding: 12px 16px;
  border: 1px solid $line-color;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
}
.bv_ul_inner {
  position: absolute;
  display: none;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  padding: 8px;
  z-index: 1;
}

.bv_ul_inner li {
  cursor: pointer;
  color: black;
  padding: 8px 16px;
  background-color: #fff;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    background-color: #13a05511;
  }
}
.bv_disabled {
  background-color: #e6e6e6 !important;
  cursor: default !important;
}
