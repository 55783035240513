.header {
  background-color: #222222;
  padding: 8px 0;
}

.header-nav__item {
  .dropbtn {
    cursor: pointer;
    font-size: 16px;
    font-weight: 300;
    color: #ebeef1;
    padding: 14px 0;
  }
}
